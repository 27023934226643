import backend from '@/utils/backend'

export default {
  async get() {
    try {
      const { price } = await backend.get('spot-price/get')

      return price
    } catch (err) {
      console.error(err)
    }
  }
}
