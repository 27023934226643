<template>
  <div class="artrade-spinner"></div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { SpinnerProps } from '../types'

const props = withDefaults(defineProps<SpinnerProps>(), {
  color: 'var(--atr-color-black)',
  thickness: '2px',
  speed: '0.45s',
  size: 'sm'
})

const size = computed(() => {
  if (props.size === 'md') {
    return '24px'
  } else if (props.size === 'lg') {
    return '32px'
  }

  return '16px'
})
</script>

<style scoped lang="scss">
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.artrade-spinner {
  display: inline-block;
  border-color: v-bind('color');
  border-style: solid;
  border-radius: 99999px;
  border-width: v-bind('thickness');
  border-bottom-color: transparent;
  border-left-color: transparent;
  animation: spin v-bind('speed') linear infinite;
  width: v-bind('size');
  height: v-bind('size');
}
</style>
