import type { Ref } from 'vue'
export const FormControlProvider = Symbol()

export type FormControlContext = {
  isRequired: boolean
  isDisabled: boolean
  isReadonly: boolean
  isInvalid: boolean
}

export const InputGroupProvider = Symbol()

export type InputGroupContext = {
  hasSuffix: Ref<boolean>
  hasPrefix: Ref<boolean>
  hasSuffixDivider: Ref<boolean>
  hasPrefixDivider: Ref<boolean>
}
