<template>
  <section ref="wrapper">
    <Video ref="videoRef">
      <source
        src="/video/artrade-trailer@1920x1080.webm"
        type="video/webm"
        media="screen and (min-width:768px)"
      />
      <source src="/video/artrade-trailer@1080x1080.webm" type="video/webm" />
    </Video>
  </section>
</template>

<script setup lang="ts">
import { isMobile } from '@/utils'
import gsap from 'gsap'
import { onMounted, onUnmounted, ref } from 'vue'
import Video from '../Video.vue'

const isMobileDevice = ref(isMobile())
const maxWidth = ref<number>(800)
const maxHeight = ref<number>(400)
const gap = ref<number>(40)
const wrapper = ref<HTMLElement>(null)
const videoRef = ref<{
  container: HTMLElement
  figure: HTMLElement
}>()

function setVwVh() {
  const vw = document.documentElement.clientWidth / 100
  const vh = document.documentElement.clientHeight / 100

  document.documentElement.style.setProperty('--vw', `${vw}px`)
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

function getClipPath() {
  let insetX = (window.innerWidth - maxWidth.value - gap.value) / 2
  let insetY = (window.innerHeight - maxHeight.value - gap.value) / 2

  insetX = insetX > 0 ? insetX : gap.value
  insetY = insetY > 0 ? insetY : gap.value

  return `inset(${insetY}px ${insetX}px round 24px)`
}

onMounted(() => {
  if (isMobileDevice.value) return

  setVwVh()

  window.addEventListener('resize', setVwVh)

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: videoRef.value.container,
      start: 'top top',
      // markers: true,
      end: () => window.innerHeight * 2,
      scrub: true,
      pin: videoRef.value.container
    }
  })

  tl.fromTo(
    videoRef.value.figure,
    {
      clipPath: getClipPath
    },
    {
      clipPath: `inset(0px 0px round 24px)`,
      duration: 5
    }
  )
})

onUnmounted(() => {
  window.removeEventListener('resize', setVwVh)
})
</script>

<style scoped lang="scss">
:deep(.artrade--video) {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  height: 500px;

  @media screen and (min-width: 768px) {
    padding-left: var(--artrade-space-15);
    padding-right: var(--artrade-space-15);
    height: 100vh;
  }

  .artrade--video-container {
    overflow: hidden;
    background-color: var(--artrade-colors-grey-100);
    width: 100%;
    height: calc(100% - 80px * 2);
    display: flex;
    align-items: center;
    margin: auto;
    border-radius: 0;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
