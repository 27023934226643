/**
 * Utility function to join classnames with ease
 *
 * @example
 * const classnames = cx('artrade-element', {
 *    '-divider': true,
 *    '-dashed': false
 * })
 *
 * // Output: "artrade-element -divider"
 */
export const cx = (...classNames: any[]) =>
  classNames
    .map((classname) => {
      if (typeof classname === 'object') {
        let output = []

        for (const item in classname) {
          if (classname[item]) {
            output.push(item)
          }
        }

        return output.join(' ')
      } else {
        return classname
      }
    })
    .join(' ')
