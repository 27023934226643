<template>
  <Stack direction="column" class="artrade-popular-artist-wrapper">
    <Stack direction="column" :spacing="14" class="artrade-popular-artist">
      <Stack direction="column" :spacing="2">
        <Heading as="h3" fontWeight="semibold" size="xl">{{ t('Popular artists') }}</Heading>
        <Heading as="h3" fontWeight="semibold" size="xl" color="grey.500">{{
          t('Our latest artist recommendations')
        }}</Heading>
      </Stack>
      <template v-if="isLoading">
        <Stack :spacing="12">
          <Skeleton class="slide-skeleton" />
          <Skeleton class="slide-skeleton" />
          <Skeleton class="slide-skeleton" />
          <Skeleton class="slide-skeleton" />
        </Stack>
      </template>

      <div class="artrade-artist-slideshow" ref="container">
        <PopularArtistCard v-for="(artist, index) in artists" :key="index" :artist="artist" />

        <IconButton
          icon="arrowLeft"
          variant="outline"
          size="md"
          class="artrade-artist-slideshow-control -prev"
          v-if="currentIndex > 0"
          @click="slider?.prev"
        />
        <IconButton
          icon="arrowRight"
          variant="outline"
          size="md"
          class="artrade-artist-slideshow-control -next"
          @click="slider?.next"
          v-if="currentIndex < artists?.length - 4"
        />
      </div>
    </Stack>
  </Stack>
</template>

<script setup lang="ts">
import api from '@/api'
import { QUERIES } from '@/constants'
import { connectedUser } from '@/store/connectedUser'
import { Heading, IconButton, Skeleton, Stack } from '@/theme'
import { useQuery } from '@tanstack/vue-query'
import { useKeenSlider } from 'keen-slider/vue'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import PopularArtistCard from './PopularArtistCard.vue'

const { t } = useI18n()
const currentIndex = ref(0)
const cacheIndexes = ref<number[]>([])
const [container, slider] = useKeenSlider({
  loop: false,
  mode: 'snap',
  rtl: false,
  slides: { perView: 'auto', spacing: 28 },
  selector: '.artrade-artist-card',
  breakpoints: {
    '(max-width: 768px)': {
      disabled: true
    }
  },
  slideChanged(evt) {
    const { rel } = evt.track.details
    currentIndex.value = rel
  }
})

let {
  data: artists,
  isLoading,
  refetch
} = useQuery({
  queryKey: [QUERIES.POPULAR_ARTISTS, connectedUser.id],
  queryFn: async () => api.artist.getPopular(connectedUser.id),
  select(res) {
    // if order already cached, re-order elements from the first request order
    if (cacheIndexes.value.length) {
      return res.sort((a, b) => cacheIndexes.value.indexOf(a.id) - cacheIndexes.value.indexOf(b.id))
    }

    return res
  },
  onSuccess(res) {
    if (cacheIndexes.value.length) {
      return
    }

    // Save order for this session (to avoid reorder list on each request because its random order)
    cacheIndexes.value = res.map((item) => item.id)
  }
})

// Watch artists data and update when vue DOM is successfully updated
watch(
  artists,
  async () => {
    slider.value.update()
  },
  {
    flush: 'post'
  }
)

watch(
  () => connectedUser.id,
  () => refetch()
)
</script>

<style scoped lang="scss">
.artrade-popular-artist-wrapper {
  overflow: hidden;

  .artrade-popular-artist {
    padding: 0 var(--artrade-space-9);

    @media screen and (min-width: 768px) {
      padding-left: calc(100% / 12 * 2);
      padding-right: var(--artrade-space-15);
    }

    .slide-skeleton {
      height: 340px;
      border-radius: var(--artrade-radii-2xl);

      @media screen and (min-width: 768px) {
        width: 500px;
      }
    }

    .artrade-artist-slideshow {
      align-content: flex-start;
      display: flex;
      position: relative;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      user-select: none;
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
      width: 100%;

      @media screen and (max-width: 500px) {
        flex-direction: column;
        gap: var(--artrade-space-10);
      }

      .artrade-artist-slideshow-control {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;

        @media screen and (min-width: 768px) {
          display: flex;
        }

        &.-prev {
          left: 0;
        }

        &.-next {
          right: 0;
        }
      }
    }
  }
}
</style>
