import { useSolana } from '@/composables/useSolana'
import { getAtrTokenAccount } from '@/utils/solana/getAtrTokenAccount'
import { getUsdcTokenAccount } from '@/utils/solana/getUsdcTokenAccount'
import { PublicKey } from '@metaplex-foundation/js'
import { WalletAdapter, WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import {
  CoinbaseWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TrustWalletAdapter
} from '@solana/wallet-adapter-wallets'
import { computed, nextTick, ref } from 'vue'
import { useCookies } from 'vue3-cookies'
import { TokenSymbol } from './types'

const { cookies } = useCookies()

type WalletConnectionConfig = {
  isLedger?: boolean
}

let network: WalletAdapterNetwork

if (import.meta.env.PROD) {
  network = WalletAdapterNetwork.Mainnet
} else {
  network = WalletAdapterNetwork.Devnet
}

const walletAdapterOptions = {
  network
}

export const WALLET_ADAPTERS = [
  new PhantomWalletAdapter(walletAdapterOptions),
  new SolflareWalletAdapter(walletAdapterOptions),
  new CoinbaseWalletAdapter(walletAdapterOptions),
  new TrustWalletAdapter(walletAdapterOptions)
]

export const walletAdapter = ref<WalletAdapter>(null)
export const publicKey = ref<PublicKey>(null)

export function useWallet() {
  const solana = useSolana()
  const walletAddress = computed(() => publicKey.value?.toString())

  async function getBalance(token: TokenSymbol) {
    try {
      if (token === TokenSymbol.ATR) {
        const associatedTokenAccount = await getAtrTokenAccount(walletAddress.value)
        const balance = await solana.connection.getTokenAccountBalance(
          new PublicKey(associatedTokenAccount)
        )

        return balance.value.uiAmount
      } else if (token === TokenSymbol.SOL) {
        const balance = await solana.connection.getBalance(new PublicKey(walletAddress.value))

        return Math.floor((balance / 1000000000) * 1000) / 1000
      } else if (token === TokenSymbol.USDC) {
        const associatedTokenAccount = await getUsdcTokenAccount(walletAddress.value)
        const balance = await solana.connection.getTokenAccountBalance(
          new PublicKey(associatedTokenAccount)
        )

        return balance.value.uiAmount
      } else {
        throw `Token ${token} doesn't supported`
      }
    } catch (err) {
      console.error(err)
      return 0
    }
  }

  return {
    adapter: walletAdapter,
    publicKey,
    walletAddress,
    getBalance,
    disconnect,
    wallets: WALLET_ADAPTERS
  }
}

export async function connect(adapter: WalletAdapter, config: WalletConnectionConfig = {}) {
  return new Promise(async (resolve, reject) => {
    walletAdapter.value = adapter

    // @note: Add pre-disconnect before effective connection, to avoid connect bug
    await walletAdapter.value.disconnect()

    // @note: Clear public key before effective connection, to avoid connect bug
    publicKey.value = null

    walletAdapter.value.on('connect', (key: PublicKey) => {
      publicKey.value = key

      cookies.set('wallet-autoconnect', walletAdapter.value.name, 60 * 60 * 24 * 60)

      cookies.set(
        'artrade-ledger-' + publicKey.value.toString(),
        config.isLedger ? 'yes' : 'no',
        60 * 60 * 24 * 60
      )

      resolve(key)
    })

    try {
      // @note: Wait vue update public key modification before connection
      await nextTick()

      // @note: disconnect again to avoid bug
      await walletAdapter.value.disconnect()
      await walletAdapter.value.connect()
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export async function disconnect() {
  try {
    publicKey.value = null
    await walletAdapter.value.disconnect()
  } catch (_) {}
}

export function anchorWallet() {
  return {
    publicKey: publicKey.value,
    signTransaction: sendTransaction,
    signAllTransactions: sendTransaction
  }
}

export async function sendTransaction(transaction, connection, options, priorityFees) {
  if (priorityFees !== false) {
    // we add priority fees
    // transaction.add(ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 100000 }))
  }

  try {
    console.log('before walletAdapter.sendTransaction')
    console.log('transaction', transaction)
    console.log('connection', connection)
    console.log('options', options)

    const signature = await walletAdapter.value.sendTransaction(transaction, connection, options)
    console.log('after walletAdapter.sendTransaction')

    return signature
  } catch (err) {
    console.log('error !', err)
    return false
  }
}

export async function signTransaction(transaction) {
  try {
    const signedTransaction = walletAdapter.value.signTransaction(transaction)

    return signedTransaction
  } catch (err) {
    console.log('error !', err)
    return false
  }
}

export async function signMessage(message) {
  try {
    const signature = await walletAdapter.value.signMessage(message)

    return signature
  } catch (err) {
    console.log('error !', err)
    return false
  }
}

export async function autoConnect(wallets) {
  const walletName = cookies.get('wallet-autoconnect')

  if (!walletName) return

  for (const wallet of wallets) {
    if (wallet.name != walletName) continue

    const res = await connect(wallet)

    return res
  }

  return false
}
