import { Nft } from '@/models'
import { formatNumber } from '@/utils'
import backend from '@/utils/backend'
import { initWorkspace, useWorkspace } from '@/utils/solana/initProgram.js'
import { TokenSymbol } from '@/wallet-connect-vue'
import { useSolana, useSpot } from '.'

export function useNft() {
  initWorkspace()
  const { program } = useWorkspace()
  const solana = useSolana()
  const spot = useSpot()

  /**
   * Toggle bookmark an NFT
   */
  async function bookmark(id: number, userId: number): Promise<Nft> {
    return await backend.get(`nft/${id}/follow/${userId}`)
  }

  /**
   * Get auction end date with escrow account data from auction program
   */
  async function getAuctionEndDate(escrowAccount: string): Promise<number> {
    const escrowData = await program.value.account.escrowAccountAuctionV2.fetch(escrowAccount)

    return Number(escrowData.dateEnd.toString())
  }

  /**
   * Get all information about NFT auction
   */
  async function getAuctionState(escrowAccount: string) {
    const endDate = await getAuctionEndDate(escrowAccount)
    const now = await solana.getTime()

    return {
      endDate,
      isEnded: now > endDate
    }
  }

  function getFormattedHighestOffer(nft: Nft) {
    let price: string

    if (nft?.maxOffer?.currency == TokenSymbol.USDC) {
      price = formatNumber(nft?.maxOffer?.amountSavedUsd)
    } else if (nft?.maxOffer?.currency == TokenSymbol.SOL) {
      price = formatNumber(nft?.maxOffer?.amountSol)
    } else if (nft?.maxOffer?.currency == TokenSymbol.ATR) {
      price = formatNumber(nft?.maxOffer?.amountAtr)
    } else {
      price = formatNumber(0)
    }

    return {
      price,
      currency: nft?.maxOffer?.currency
    }
  }

  function getFormattedLastSold(nft: Nft) {
    let price: string

    if (nft?.lastSoldFor?.currency == TokenSymbol.USDC) {
      price = formatNumber(nft?.lastSoldFor?.priceUsd)
    } else if (nft?.lastSoldFor?.currency == TokenSymbol.SOL) {
      price = formatNumber(nft?.lastSoldFor?.priceSol)
    } else if (nft?.lastSoldFor?.currency == TokenSymbol.ATR) {
      price = formatNumber(nft?.lastSoldFor?.priceAtr)
    } else {
      price = formatNumber(0)
    }

    return {
      price,
      currency: nft?.lastSoldFor?.currency,
      usd: formatNumber(nft?.lastSoldFor?.priceUsd, {
        maximumSignificantDigits: 3,
        style: 'currency',
        currency: 'USD'
      })
    }
  }

  async function getFormattedMaxOffer(nft: Nft) {
    if (nft.maxOffer.currency === TokenSymbol.SOL) {
      return getFormattedUSD(nft.maxOffer.amountSol, nft.maxOffer.currency)
    } else if (nft.maxOffer.currency === TokenSymbol.ATR) {
      return getFormattedUSD(nft.maxOffer.amountAtr, nft.maxOffer.currency)
    } else if (nft.maxOffer.currency === TokenSymbol.USDC) {
      return getFormattedUSD(nft.maxOffer.amountSavedUsd, nft.maxOffer.currency)
    } else {
      return getFormattedUSD(0, nft.maxOffer.currency)
    }
  }

  async function getFormattedHighestBidUSD(nft: Nft) {
    const hasBids = nft?.sale?.bids?.length > 0

    if (nft?.sale?.currency === TokenSymbol.SOL) {
      return getFormattedUSD(
        hasBids ? nft?.sale?.highestBidSol : nft?.sale?.price,
        nft.sale?.currency
      )
    } else if (nft.sale?.currency === TokenSymbol.ATR) {
      return getFormattedUSD(
        hasBids ? nft.sale.highestBidAtr : nft?.sale?.price,
        nft.sale?.currency
      )
    } else if (nft.sale?.currency === TokenSymbol.USDC) {
      return getFormattedUSD(
        hasBids ? nft.sale.highestBidUsd : nft?.sale?.price,
        nft.sale?.currency
      )
    } else {
      return getFormattedUSD(0, nft.sale?.currency)
    }
  }

  function getFormattedHighestBid(nft: Nft) {
    let price: string

    if (nft?.sale?.currency == TokenSymbol.USDC) {
      price = formatNumber(nft?.sale?.highestBidUsd)
    } else if (nft?.sale?.currency == TokenSymbol.SOL) {
      price = formatNumber(nft?.sale?.highestBidSol)
    } else if (nft?.sale?.currency == TokenSymbol.ATR) {
      price = formatNumber(nft?.sale?.highestBidAtr)
    } else {
      price = formatNumber(0)
    }

    return {
      price,
      currency: nft?.sale?.currency
    }
  }

  function getFormattedPrice(nft: Nft) {
    return {
      price: formatNumber(nft?.sale?.price),
      currency: nft?.sale?.currency
    }
  }

  async function getFormattedUSD(amount: number, currency: TokenSymbol) {
    const formatOptions = {
      currency: 'USD',
      style: 'currency'
    } as Intl.NumberFormatOptions

    try {
      if (currency === TokenSymbol.SOL) {
        const spotPrice = await spot.get(currency)
        return formatNumber(amount * spotPrice, formatOptions)
      } else if (currency === TokenSymbol.ATR) {
        const spotPrice = await spot.get(currency)
        return formatNumber(amount * spotPrice, formatOptions)
      } else if (currency === TokenSymbol.USDC) {
        return formatNumber(amount, formatOptions)
      } else {
        return formatNumber(0, formatOptions)
      }
    } catch (err) {
      console.error(err)
      return formatNumber(0, formatOptions)
    }
  }

  function getCreationYear(nft: Nft) {
    const date = new Date(nft?.creation)
    return date.getFullYear()
  }

  return {
    bookmark,
    getAuctionEndDate,
    getAuctionState,
    getFormattedPrice,
    getFormattedHighestOffer,
    getFormattedLastSold,
    getFormattedMaxOffer,
    getFormattedHighestBid,
    getFormattedHighestBidUSD,
    getFormattedUSD,
    getCreationYear
  }
}
