import { PublicKey } from '@solana/web3.js'

export const QUERIES = {
  LAST_AUCTIONS: 'last-auctions',
  LAST_OFFERS: 'last-offers',
  LAST_FIXED_PRICES: 'last-fixed-prices',
  POPULAR_ARTISTS: 'popular-artists',
  SHOWCASE_ARTWORKS: 'showcase-artworks',
  USER_COLLECTIONS: 'user-collections',
  TAGS: 'tags',
  MEDIUM_LIST: 'medium-list',
  MATERIAL_LIST: 'material-list',
  ME: 'me',
  WALLET_BALANCE: 'wallet-balance',
  ARTWORKS: 'artworks',
  COLLECTIONS: 'collections',
  ATR_BALANCE: 'atr-balance',
  ARTWORK_COUNT: 'artwork-count',
  OFFERS: 'offers',
  BOOKMARKS: 'bookmarks',
  AUCTIONS: 'auctions'
}

export const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY

export const API_URL = import.meta.env.VITE_API_URL
export const API_JS_URL = import.meta.env.VITE_API_JS_URL

export const SOLANA_CLUSTER = import.meta.env.VITE_SOLANA_CLUSTER

export const METAPLEX_METADATA_PROGRAM_ID = new PublicKey(
  'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'
)
export const ARTRADE_PROGRAM_ID = new PublicKey('Yt3A8KLfo7JEz8RGHw2zbaQE6MVcf9duKmb24dgXbsD')
export const ARTADE_MARKETPLACE_WALLET = new PublicKey(
  '8gzmKhrYzo6bPm1sKJfdCHa817w5q8eRmM6TNYoE7p26'
)

export const ARTADE_MARKETPLACE_WALLET_USDC = new PublicKey(
  import.meta.env.VITE_ARTRADE_FEES_USDC_ATA
)

export const ARTADE_MARKETPLACE_WALLET_ATR = new PublicKey(
  import.meta.env.VITE_ARTRADE_FEES_ATR_ATA
)

export const ARTRADE_WALLET_CRON = new PublicKey('4x8KM2hCTXZp6bsgGCYRzHGGK4CZt1zbD8rYs2kP7qdt')
export const REAL_PROGRAM_ID = new PublicKey('reaLx5UbsggYsz3XyfVajQd4j5NWjimtob8TjSNypaM')

export const USDC_MINT_ADDRESS = new PublicKey(import.meta.env.VITE_USDC_MINT_ADDRESS)
export const ATR_MINT_ADDRESS = new PublicKey(import.meta.env.VITE_ATR_MINT_ADDRESS)
export const WATR_MINT_ADDRESS = new PublicKey(import.meta.env.VITE_WATR_MINT_ADDRESS)
export const BURN_ATA_ATR_ADDRESS = new PublicKey(import.meta.env.VITE_BURN_ATA_ATR_ADDRESS)
export const BURN_ATA_WATR_ADDRESS = new PublicKey(import.meta.env.VITE_BURN_ATA_WATR_ADDRESS)
export const ATR_DECIMAL_MULTIPLIER = import.meta.env.VITE_ATR_DECIMAL_MULTIPLIER

export const ATR_INITIAL_VALUE = 0.001246

// @todo gérer le devnet / mainnet
// export const CHAINLINK_FEED = new PublicKey('99B2bTijsU6f1GCT73HmdR7HCFFjGMBcPZY6jZ96ynrR'); // devnet
export const CHAINLINK_FEED = new PublicKey(import.meta.env.VITE_CHAINLINK_FEED) // mainnet
export const CHAINLINK_PROGRAM = new PublicKey('HEvSKofvBgfaexv23kMabbYqxasxU3mQ4ibBMEmJWHny')

export const FREE_MINTS_PER_USER = 6

export const VAULT_ACCOUNT_PREFIX = 'artradevault_221026'
export const VAULT_AUTHORITY_PREFIX = 'artradevault_auth_221026'
export const VAULT_ACCOUNT_OFFER_PREFIX = 'artradeOvault_221026'
export const VAULT_ACCOUNT_OFFER_CURRENCY_PREFIX = 'offer_currency'
export const VAULT_ACCOUNT_OFFER_CURRENCY_AUTHORITY_PREFIX = 'offer_currency_authority'
export const VAULT_AUTHORITY_OFFER_PREFIX = 'artradeOvault_auth_221026'
export const VAULT_ACCOUNT_PREFIX_CURRENCY = 'bid_currency'

export const FUNDS_ACCOUNT_AUTH_PREFIX = 'funds'
export const NFT_ACCOUNT_PREFIX = 'nft'
export const NFT_ACCOUNT_AUTH_PREFIX = 'nftauth'
export const ATR_INITIAL_SUPPLY = 1800000000
export const NFT_DESCRIPTION_MAXLENGTH = 160

export const FRAGMENT_RECEIVER_WALLET = new PublicKey(import.meta.env.VITE_FRAGMENT_RECEIVER_WALLET)

