export function isInvalidNumber(x: any, withZero = true) {
  if (withZero) {
    return x === undefined || x === null || x < 0 || x === '' || Number.isNaN(x)
  }

  return x === undefined || x === null || x <= 0 || x === '' || Number.isNaN(x)
}

export function isInvalidString(x: any) {
  return x === '' || x === null || x === undefined
}
