<template>
  <Stack as="section" direction="column" :class="['tabs', classname]" :spacing="0">
    <Stack :spacing="12">
      <slot></slot>
    </Stack>
    <Divider />
  </Stack>
</template>

<script setup lang="ts">
import Divider from '@/components/design/Divider.vue'
import Stack from '@/components/design/Stack.vue'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    isSticky?: boolean
  }>(),
  {
    isSticky: false
  }
)

const classname = computed(() => {
  if (props.isSticky) {
    return '-sticky'
  }

  return ''
})
</script>

<style scoped lang="scss">
.tabs {
  position: relative;
  width: 100%;
  background-color: var(--artrade-colors-white);
  z-index: 999;
  overflow: auto;

  &.-sticky {
    position: sticky;
    top: 74px;
  }
}
</style>
