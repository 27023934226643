import axios from 'axios'

import { API_URL, API_JS_URL } from '../constants'
import { useCookies } from 'vue3-cookies'
import { user } from '@/store/user'

export default {
  get: async function (url: string) {
    const response = await axios.get(API_URL + url, this.getHeaders())

    return this.handleApiresult(response)
  },

  getNode: async function (url: string) {
    const response = await axios.get(API_JS_URL + url)

    return this.handleApiresult(response)
  },

  post: async function (url: string, postData: object, options?: object) {
    let response = null

    response = await axios.post(API_URL + url, postData, this.getHeaders(options))

    return this.handleApiresult(response)
  },

  postNode: async function (url: string, postData: object, options?: object) {
    let response = null

    if (options) response = await axios.post(API_JS_URL + url, postData, options)
    else response = await axios.post(API_JS_URL + url, postData)

    return this.handleApiresult(response)
  },

  handleApiresult: function (response) {
    if (response.data.success === true) return response.data.response
    else {
      console.log(response.data.error)
      return {}
    }
  },

  getHeaders: function (options) {
    const { cookies } = useCookies()

    const bearer = cookies.get('artrade-bearer')

    let headers = options ? options : {}

    if (bearer) {
      headers.headers = {
        'Artrade-Bearer': bearer.bearer,
        'Artrade-UserId': user.data.id
      }
    }

    return headers
  }
}
