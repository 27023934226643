<template>
  <Stack direction="column" :spacing="4" class="artrade-form-control" role="group">
    <slot></slot>
  </Stack>
</template>

<script setup lang="ts">
import Stack from '@/components/design/Stack.vue'
import { provide } from 'vue'
import { FormControlProvider, type FormControlContext } from './types'

const props = withDefaults(
  defineProps<{
    isDisabled?: boolean
    isRequired?: boolean
    isReadonly?: boolean
    isInvalid?: boolean
    modelValue?: string | number
  }>(),
  {
    isDisabled: undefined,
    isRequired: undefined,
    isReadonly: undefined,
    isInvalid: undefined
  }
)

defineEmits(['onUpdate:modelValue'])

provide<FormControlContext>(FormControlProvider, {
  isRequired: props.isRequired,
  isReadonly: props.isReadonly,
  isInvalid: props.isInvalid,
  isDisabled: props.isDisabled
})
</script>

<style scoped lang="scss">
.artrade-form-control {
  width: 100%;
}
</style>
