<template>
  <BottomSheet v-slot="{ close }">
    <BottomSheetBody>
      <nav>
        <BottomSheetLink as="router-link" @click="close" :to="{ name: RouteName.Fragment }">{{
          t('Fragments')
        }}</BottomSheetLink>
        <BottomSheetLink as="router-link" @click="close" :to="{ name: RouteName.Marketplace }">{{
          t('Marketplace')
        }}</BottomSheetLink>
        <BottomSheetLink as="router-link" @click="close" :to="{ name: RouteName.FeedArtwork }">{{
          t('Artworks')
        }}</BottomSheetLink>
        <BottomSheetLink as="router-link" @click="close" :to="{ name: RouteName.FeedCollection }">{{
          t('Collections')
        }}</BottomSheetLink>
        <BottomSheetLink
          @click="close"
          href="https://artradeapp.notion.site/Artrade-FAQ-5bb50865a81743b1be9498cf89dea3ee"
          target="_blank"
          >{{ t('FAQ') }}</BottomSheetLink
        >
      </nav>
    </BottomSheetBody>
    <BottomSheetFooter>
      <TokenAds />
    </BottomSheetFooter>
  </BottomSheet>
</template>

<script setup lang="ts">
import { RouteName } from '@/router'
import { BottomSheet, BottomSheetBody, BottomSheetFooter, BottomSheetLink } from '@/theme'
import { useI18n } from 'vue-i18n'
import TokenAds from '../TokenAds.vue'

const { t } = useI18n()
</script>
