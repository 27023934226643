<script setup lang="ts">
import OptimizedAvatar from '@/components/OptimizedAvatar.vue'
import WalletBox from '@/components/WalletBox.vue'
import Heading from '@/components/design/Heading.vue'
import Stack from '@/components/design/Stack.vue'
import Text from '@/components/design/Text.vue'
import { useMenu } from '@/composables'
import { RouteName } from '@/router/types'
import { user } from '@/store/user'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'

const { t } = useI18n()
const { trigger, menu, isOpen, menuStyle } = useMenu({
  menuWidth: 400,
  placement: 'bottom-end'
})

function toggle() {
  isOpen.value = !isOpen.value
}

function logout() {
  toggle()
  user.disconnect()
}

function setTriggerRef(ref: { avatarRef: { el: HTMLDivElement } }) {
  trigger.value = ref?.avatarRef?.el
}

const avatar = computed(() => user.data.avatarUrlUid)
</script>

<template>
  <OptimizedAvatar
    class="user-avatar"
    size="md"
    :src="avatar"
    @click="toggle"
    :ref="setTriggerRef"
  />
  <div class="dropdown-overlay" v-if="isOpen" @click="toggle"></div>

  <Transition name="dropdown">
    <div class="dropdown-wrapper" v-if="isOpen" ref="menu" :style="menuStyle">
      <Stack class="dropdown" :spacing="5" direction="column">
        <RouterLink
          :to="{ name: RouteName.Profile, params: { id: user.data.urlName } }"
          @click="toggle"
        >
          <Stack class="user-card" :spacing="7">
            <OptimizedAvatar size="lg" :src="avatar" />
            <Stack direction="column" :spacing="2">
              <Heading fontWeight="semibold">{{ user.data.nickname }}</Heading>
              <Text color="grey.700">@{{ user.data.nickname }}</Text>
            </Stack>
          </Stack>
        </RouterLink>
        <WalletBox></WalletBox>
        <nav class="nav-list">
          <RouterLink :to="{ name: RouteName.Activity }" class="mobile">
            <div class="nav-list-item" @click="toggle">
              <Text fontWeight="semibold" size="md">{{ t('Activity') }}</Text>
            </div>
          </RouterLink>
          <RouterLink :to="{ name: RouteName.Message }" class="mobile">
            <div class="nav-list-item" @click="toggle">
              <Text fontWeight="semibold" size="md">{{ t('Messages') }}</Text>
            </div>
          </RouterLink>
          <RouterLink :to="{ name: RouteName.Settings }">
            <div class="nav-list-item" @click="toggle">
              <Text fontWeight="semibold" size="md">{{ t('Settings') }}</Text>
            </div>
          </RouterLink>
          <RouterLink :to="{ name: RouteName.Orders }">
            <div class="nav-list-item" @click="toggle">
              <Text fontWeight="semibold" size="md">{{ t('Orders') }}</Text>
            </div>
          </RouterLink>
          <RouterLink :to="{ name: RouteName.Staking }">
            <div class="nav-list-item" @click="toggle">
              <Text fontWeight="semibold" size="md">{{ t('ATR & Staking') }}</Text>
            </div>
          </RouterLink>
          <div class="nav-list-item" @click="logout">
            <Text fontWeight="semibold" size="md">{{ t('Disconnect') }}</Text>
          </div>
        </nav>
      </Stack>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.user-avatar {
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;

  &:hover {
    transform: translate3d(0, -1.6px, 0);
    box-shadow: 0px 2px 4px rgba(202, 207, 226, 0.16);
  }

  &:active {
    transform: translate3d(0, 2px, 0);
  }
}

.dropdown {
  .user-card {
    padding: var(--artrade-space-8);
    background-color: var(--artrade-colors-white);
    border-radius: var(--artrade-radii-md);
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      background-color: var(--artrade-colors-grey-50);
    }
  }

  .nav-list {
    .nav-list-item {
      height: 44px;
      display: flex;
      align-items: center;
      padding: 0 var(--artrade-space-8);
      background-color: var(--artrade-colors-white);
      border-radius: var(--artrade-radii-md);
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: var(--artrade-colors-grey-50);
      }
    }
  }
}
</style>
