import { ErrorModal, SignatureModal } from '@/components/modals'
import { ModalConfig, useModal } from '@/theme'
import { reactive } from 'vue'

export function useSignatureModal() {
  const modal = useModal()
  const modals = reactive<{
    confirm: ModalConfig
    error: ModalConfig
  }>({
    confirm: null,
    error: null
  })

  function confirm() {
    modals.confirm = modal.open(SignatureModal, null, {
      closeable: false
    })
  }

  function error(error: string) {
    modals.error = modal.open(
      ErrorModal,
      {
        description: error || 'An error occurred'
      },
      {
        closeable: false
      }
    )
  }

  return {
    confirm: {
      open: confirm,
      close() {
        if (!modals.confirm) return
        modals.confirm.close()
      }
    },
    error: {
      open: error,
      close() {
        if (!modals.error) return
        modals.error.close()
      }
    }
  }
}
