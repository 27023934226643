<template>
  <Stack class="artrade-featured">
    <Container
      class="pt-md-14 pb-0"
      :direction="{
        base: 'column',
        md: 'row'
      }"
      :spacing="{
        base: 9,
        md: 0
      }"
    >
      <Stack
        class="artrade-featured-image"
        :justifyContent="{
          base: 'start',
          md: 'end'
        }"
        flex
      >
        <template v-if="isLoading">
          <Skeleton class="artrade-featured-skeleton" />
        </template>
        <template v-else>
          <RouterLink
            as="router-link"
            :to="{ name: RouteName.Nft, params: { id: artwork?.urlName } }"
          >
            <OptimizedImage
              :src="artwork?.mainImageUid"
              :alt="artwork?.title"
              loading="lazy"
              decoding="async"
              maxWidth="100%"
              maxHeight="100%"
            />
          </RouterLink>
        </template>
      </Stack>
      <Stack
        class="artrade-featured-content"
        :alignItems="{
          base: 'start',
          md: 'center'
        }"
        flex
      >
        <Stack
          v-if="artwork"
          class="artrade-featured-content-inner"
          direction="column"
          :spacing="{
            base: 9,
            md: 12
          }"
        >
          <Stack direction="column" :spacing="12">
            <Stack direction="column" :spacing="12">
              <Stack direction="column" :spacing="6">
                <Stack alignItems="center" :spacing="4">
                  <Icon name="flare" :size="14" />
                  <Text fontWeight="medium">{{ t('FEATURED') }}</Text>
                </Stack>
                <Heading
                  as="router-link"
                  :to="{ name: RouteName.Nft, params: { id: artwork?.urlName } }"
                  fontWeight="semibold"
                  size="3xl"
                  >{{ artwork?.title }}</Heading
                >
                <Stack
                  :spacing="5"
                  alignItems="center"
                  as="router-link"
                  :to="{ name: RouteName.Profile, params: { id: artwork?.creator?.urlName } }"
                >
                  <OptimizedAvatar :src="artwork?.creator?.avatarUrlUid" size="sm" />
                  <Text size="md">{{ artwork?.creator?.nickname }}</Text>
                </Stack>
              </Stack>
              <Text lineHeight="md" v-html="description"></Text>
            </Stack>

            <template v-if="hasCurrentUserOwner">
              <Stack
                :direction="{
                  base: 'column',
                  md: 'row'
                }"
              >
                <Button
                  as="router-link"
                  :to="`/nft/${artwork?.urlName}`"
                  variant="primary"
                  size="lg"
                  >{{ t('See my artwork') }}</Button
                >
              </Stack>
            </template>

            <template v-else>
              <template v-if="isAuction">
                <Stack direction="column" :spacing="12">
                  <Stack direction="column">
                    <Divider />
                    <Stack :spacing="16">
                      <template v-if="hasBidder">
                        <Stack direction="column" :spacing="6">
                          <Text color="grey.600">{{ t('Highest bid') }}</Text>
                          <Heading fontWeight="medium" as="h3" size="lg"
                            >{{ highestBid?.price }} {{ highestBid?.currency }}</Heading
                          >
                        </Stack>
                        <Divider vertical />
                        <Stack direction="column" :spacing="6">
                          <Text color="grey.700">{{ t('Ends in') }}</Text>
                          <CountDownV2 v-if="endDate" :end="endDate" v-slot="{ display }">
                            <Heading as="h3" fontWeight="medium" size="lg">{{ display }}</Heading>
                          </CountDownV2>
                        </Stack>
                      </template>
                      <template v-else>
                        <Stack direction="column" :spacing="6">
                          <Text color="grey.600">{{ t('Starting bid') }}</Text>
                          <Heading fontWeight="medium" as="h3" size="lg"
                            >{{ price?.price }} {{ price?.currency }}</Heading
                          >
                        </Stack>
                      </template>
                    </Stack>
                    <Divider />
                  </Stack>
                  <Stack :spacing="5">
                    <Button
                      as="router-link"
                      :to="{ name: RouteName.Nft, params: { id: artwork?.urlName } }"
                      rightIcon="arrowRight"
                      >{{ t('See artwork') }}</Button
                    >
                  </Stack>
                </Stack>
              </template>

              <template v-if="isOpenToOffer">
                <Stack direction="column" :spacing="12">
                  <template v-if="artwork.maxOffer">
                    <Stack direction="column">
                      <Divider />
                      <Stack :spacing="16" alignItems="center">
                        <Stack direction="column" :spacing="6">
                          <Text color="grey.600">{{ t('Highest offer') }}</Text>
                          <Heading fontWeight="medium" as="h3" size="lg">
                            {{ highestOffer?.price }} {{ highestOffer?.currency }}
                          </Heading>
                        </Stack>
                        <Divider vertical />
                        <Stack
                          :spacing="5"
                          alignItems="center"
                          as="router-link"
                          :to="{
                            name: RouteName.Profile,
                            params: { id: artwork?.maxOffer?.user?.urlName }
                          }"
                        >
                          <OptimizedAvatar :src="artwork?.maxOffer?.user?.avatarUrlUid" size="sm" />
                          <Text size="md">@{{ artwork?.maxOffer?.user?.nickname }}</Text>
                        </Stack>
                      </Stack>
                      <Divider />
                    </Stack>
                  </template>
                  <Stack :spacing="5">
                    <Button
                      as="router-link"
                      :to="{ name: RouteName.Nft, params: { id: artwork?.urlName } }"
                      rightIcon="arrowRight"
                      >{{ t('See artwork') }}</Button
                    >
                  </Stack>
                </Stack>
              </template>

              <template v-if="isFixedPrice">
                <Stack direction="column" :spacing="12">
                  <Stack direction="column">
                    <Divider />
                    <Stack :spacing="16">
                      <Stack direction="column" :spacing="6">
                        <Text color="grey.600">{{ t('Fixed price') }}</Text>
                        <Heading fontWeight="medium" as="h3" size="lg"
                          >{{ price?.price }} {{ price?.currency }}</Heading
                        >
                      </Stack>
                      <template v-if="artwork?.lastSoldFor">
                        <Divider vertical />

                        <Stack direction="column" :spacing="6">
                          <Text color="grey.600">{{ t('Last sold') }}</Text>
                          <Heading fontWeight="medium" as="h3" size="lg">
                            {{ lastSold?.price }} {{ lastSold?.currency }}
                          </Heading>
                        </Stack>
                      </template>
                    </Stack>
                    <Divider />
                  </Stack>
                  <Stack :spacing="5">
                    <Button
                      as="router-link"
                      :to="{ name: RouteName.Nft, params: { id: artwork?.urlName } }"
                      rightIcon="arrowRight"
                      >{{ t('See artwork') }}</Button
                    >
                  </Stack>
                </Stack>
              </template>
            </template>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  </Stack>
</template>

<script setup lang="ts">
import api from '@/api'
import { useNft } from '@/composables'
import { NFT_DESCRIPTION_MAXLENGTH } from '@/constants'
import { RouteName } from '@/router/types'
import { connectedUser } from '@/store/connectedUser'
import { Button, Container, Divider, Heading, Icon, Skeleton, Stack, Text } from '@/theme'
import { nl2br } from '@/utils'
import { useQuery } from '@tanstack/vue-query'
import { computed, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import OptimizedAvatar from './OptimizedAvatar.vue'
import OptimizedImage from './OptimizedImage.vue'
import CountDownV2 from './misc/CountDownV2.vue'

const { t } = useI18n()
const { data: artwork, isLoading } = useQuery({
  queryKey: ['featured-artwork'],
  queryFn: api.nft.getFeatured,
  staleTime: 0,
  cacheTime: 0
})

const {
  getAuctionEndDate,
  getFormattedPrice,
  getFormattedHighestOffer,
  getFormattedHighestBid,
  getFormattedLastSold
} = useNft()
const endDate = ref<number>()
const hasCurrentUserOwner = computed(() => connectedUser.id === artwork?.owner?.id)
const isFixedPrice = computed(() => artwork.value?.sale?.saleType === 1)
const isAuction = computed(() => artwork.value?.sale?.saleType === 2)
const isOpenToOffer = computed(() => artwork.value?.sale === null)
const description = computed(() =>
  nl2br(artwork.value?.description?.slice(0, NFT_DESCRIPTION_MAXLENGTH))
)
const price = computed(() => getFormattedPrice(artwork.value))
const highestOffer = computed(() => getFormattedHighestOffer(artwork.value))
const highestBid = computed(() => getFormattedHighestBid(artwork.value))
const lastSold = computed(() => getFormattedLastSold(artwork.value))

const hasBidder = computed(() => {
  return artwork.value.sale?.bids?.length > 0
})

watchEffect(async () => {
  if (!artwork) return
  if (!isAuction.value) return

  endDate.value = await getAuctionEndDate(artwork.value.sale.escrowAccount)
})
</script>

<style scoped lang="scss">
.artrade-featured {
  background-color: var(--artrade-colors-grey-50);
  margin-top: -80px;
  padding: 80px 0 var(--artrade-space-9) 0;

  @media (min-width: 768px) {
    padding: calc(var(--artrade-space-16) + 80px) 0 var(--artrade-space-16) 0;
  }

  .artrade-featured-image {
    overflow: hidden;

    @media (min-width: 768px) {
      width: calc(100% / 12 * 4);
      margin-left: calc(100% / 12 * 1);
    }

    &:hover {
      :deep(.artrade-picture) {
        transform: scale(1.02);
      }
    }

    .artrade-featured-skeleton {
      height: 60dvh;
      width: 70%;
    }

    :deep(.artrade-picture) {
      transition: transform 0.6s ease;
      justify-content: flex-end;

      img {
        height: 60dvh;
      }
    }
  }

  .artrade-featured-content {
    @media (min-width: 768px) {
      width: calc(100% / 12 * 5);
      margin-left: calc(100% / 12 * 1);
    }

    .artrade-featured-content-inner {
      width: 100%;
    }
  }

  .artrade-featured-skeleton {
    &.-creator {
      width: 400px;
      height: 48px;
    }

    &.-title {
      width: 360px;
      height: 48px;
    }

    &.-tag {
      width: 90px;
      height: 38px;
      border-radius: var(--artrade-radii-full);
    }
  }
}
</style>
