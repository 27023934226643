<template>
  <component :is="as" :class="classnames" ref="el">
    <img v-if="src" :src="src" :alt="alt" loading="lazy" decoding="async" />
    <slot></slot>
  </component>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import type { AvatarProps } from '../types'

const props = withDefaults(defineProps<AvatarProps>(), {
  size: 'md',
  squared: false,
  as: 'span',
  outlined: false,
  alt: ''
})

const el = ref<HTMLElement>(null)

defineExpose({
  el
})

const classnames = computed(() => {
  const output = []

  output.push('artrade-avatar')
  output.push(`-${props.size}`)

  if (props.squared) {
    output.push(`-squared`)
  }

  if (props.outlined) {
    output.push('-outlined')
  }

  return output.join(' ')
})
</script>

<style scoped lang="scss">
.artrade-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  flex-shrink: 0;
  border-radius: var(--artrade-radii-full);
  background-color: var(--artrade-colors-grey-100);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--artrade-radii-full);
  }

  &.-xs {
    width: 20px;
    height: 20px;
  }

  &.-sm {
    width: 24px;
    height: 24px;
  }

  &.-md {
    width: 40px;
    height: 40px;
  }

  &.-lg {
    width: 48px;
    height: 48px;
  }

  &.-xl {
    width: 72px;
    height: 72px;
  }

  &.\-2xl {
    width: 90px;
    height: 90px;

    &.-outlined {
      box-shadow: 0 0 0 10px var(--artrade-colors-white);
    }
  }

  &.\-3xl {
    width: 100px;
    height: 100px;

    &.-outlined {
      box-shadow: 0 0 0 10px var(--artrade-colors-white);
    }
  }

  &.-squared {
    &.-xs {
      border-radius: var(--artrade-radii-xs);

      img {
        border-radius: var(--artrade-radii-xs);
      }
    }

    &.-sm {
      border-radius: var(--artrade-radii-xs);
      img {
        border-radius: var(--artrade-radii-xs);
      }
    }

    &.-md {
      border-radius: var(--artrade-radii-sm);
      img {
        border-radius: var(--artrade-radii-sm);
      }
    }

    &.-lg {
      border-radius: var(--artrade-radii-md);
      img {
        border-radius: var(--artrade-radii-md);
      }
    }

    &.-xl {
      border-radius: var(--artrade-radii-lg);
      img {
        border-radius: var(--artrade-radii-lg);
      }
    }

    &.\-2xl {
      border-radius: var(--artrade-radii-xl);
      img {
        border-radius: var(--artrade-radii-xl);
      }
    }
  }
}
</style>
