<template>
  <Modal novalidate>
    <ModalHeader alignItems="center">
      <Heading as="h4" size="md">{{ t('Buy ATR') }}</Heading>
    </ModalHeader>
    <ModalBody>
      <TokenInput
        :label="t('You pay')"
        max
        :tokens="[TokenSymbol.SOL, TokenSymbol.USDC]"
        @change="handleTokenChange"
      />
      <TokenInput
        :label="t('You receive')"
        :tokens="[TokenSymbol.ATR]"
        :amount="resultAmount"
        :disabled="true"
      />
    </ModalBody>
    <ModalFooter>
      <Button
        @click="buy.mutate"
        :isLoading="buy.isLoading.value"
        size="lg"
        fill
        :isDisabled="isDisabled"
        >{{ buttonLabel }}</Button
      >
    </ModalFooter>
  </Modal>
</template>

<script setup lang="ts">
import { TokenInput, TokenInputChangeEvent } from '@/components/token-input'
import { useAnalytic, useJupiter, useSignatureModal, useTransaction } from '@/composables'
import { QUERIES } from '@/constants'
import { user } from '@/store/user'
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModalConfig
} from '@/theme'
import { TokenSymbol } from '@/wallet-connect-vue'
import { WalletSendTransactionError } from '@solana/wallet-adapter-base'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

/**
 * @todo: Tout les cas ne sont pas pris en compte, TokenInput est à améliorer dans le temps, force et honneur =)
 */
const { t } = useI18n()
const amount = ref<number>(0)
const resultAmount = ref<number>(0)
const currency = ref<TokenSymbol>()
const isInsufficiant = ref<boolean>(false)
const config = useModalConfig()
const signatureModal = useSignatureModal()
const transaction = useTransaction()
const queryClient = useQueryClient()
const { getSpotRate, createSwapTransaction } = useJupiter()
const analytic = useAnalytic()

const buy = useMutation({
  async mutationFn() {
    const inputMint =
      currency.value == TokenSymbol.SOL
        ? 'So11111111111111111111111111111111111111112'
        : 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'

    const tx = await createSwapTransaction(
      inputMint,
      'ATRLuHph8dxnPny4WSNW7fxkhbeivBrtWbY6BfB4xpLj',
      amount.value
    )

    signatureModal.confirm.open()

    await transaction.sendAndConfirm(tx)
    signatureModal.confirm.close()
  },
  onSuccess() {
    // Send buy event to analytics
    analytic.send('BUY_ATR', {
      userId: user.data?.id?.toString(),
      amount: amount.value?.toString(),
      currency: currency.value,
      buyAmount: resultAmount.value?.toString()
    })

    config.close()

    // Refresh wallet balance
    queryClient.invalidateQueries([QUERIES.WALLET_BALANCE])
    queryClient.invalidateQueries([QUERIES.ATR_BALANCE])
  },
  onError(error) {
    if (error instanceof WalletSendTransactionError) {
      error = 'You have cancelled the transaction'
    }

    signatureModal.error.open(error as string)
  },
  onSettled() {
    signatureModal.confirm.close()
  }
})

// Get change event from TokenInput
async function handleTokenChange(evt: TokenInputChangeEvent) {
  currency.value = evt.token
  amount.value = evt.amount
  isInsufficiant.value = evt.isInsufficiant

  const inputMint =
    evt.token == TokenSymbol.SOL
      ? 'So11111111111111111111111111111111111111112'
      : 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'

  const outputAmount = await getSpotRate(
    inputMint,
    'ATRLuHph8dxnPny4WSNW7fxkhbeivBrtWbY6BfB4xpLj',
    amount.value
  )

  resultAmount.value = outputAmount
}

// Check if button is disabled
const isDisabled = computed(() => amount.value <= 0 || isInsufficiant.value)

// Computed button label depending to TokenInput result
const buttonLabel = computed(() => {
  if (amount.value <= 0) {
    return t('Enter amount to buy')
  }

  if (isInsufficiant.value) {
    return t('Insufficiant {currency} balance', {
      currency: currency.value
    })
  }

  return t('Buy for {amount} {currency}', {
    amount: amount.value,
    currency: currency.value
  })
})
</script>
