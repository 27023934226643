<template>
  <div ref="container" class="artrade--video" @click="isOpen = true">
    <figure ref="figure" class="artrade--video-container">
      <video preload="none" muted loop playsinline ref="video">
        <slot></slot>
      </video>
      <div class="artrade--video-overlay">
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g style="mix-blend-mode: difference">
            <path
              d="M21.5747 13.9705C19.1134 12.5119 16 14.2859 16 17.147V46.8537C16 49.7147 19.1134 51.4887 21.5747 50.0302L46.6397 35.1768C49.053 33.7467 49.053 30.254 46.6397 28.8239L21.5747 13.9705Z"
              fill="white"
            />
          </g>
        </svg>
      </div>
    </figure>
  </div>

  <Modal :isOpen="isOpen" @onClose="isOpen = false">
    <Stack class="video-modal-wrapper">
      <video playsinline ref="video" preload="auto">
        <slot></slot>
      </video>
    </Stack>
  </Modal>
</template>

<script setup lang="ts">
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import Stack from './design/Stack.vue'
import { Modal } from './modal'

const isOpen = ref(false)
const video = ref<HTMLVideoElement>(null)
const container = ref<HTMLElement>(null)
const figure = ref<HTMLElement>(null)
const observer = ref<IntersectionObserver>()

watch(isOpen, async (current) => {
  await nextTick()

  if (current) {
    video.value.volume = 0.5
    video.value.play()
  }
})

onMounted(() => {
  observer.value = new IntersectionObserver((entries) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        video.value.load()
        video.value.play()
      } else {
        video.value.pause()
      }
    }
  })

  observer.value.observe(container.value)
})

onBeforeUnmount(() => {
  observer.value.unobserve(container.value)
})

defineExpose({
  video,
  container,
  figure
})
</script>

<style scoped lang="scss">
.artrade--video {
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 400px;

  @media screen and (min-width: 768px) {
    height: 80vh;
  }

  .artrade--video-container {
    overflow: hidden;
    border-radius: var(--artrade-radii-3xl);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin: auto;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .artrade--video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

      &:hover {
        transform: scale(1.2);
      }

      &:active {
        transform: scale(0.9);
      }
    }
  }
}

.video-modal-wrapper {
  overflow: hidden;
  border-radius: 0px;
  margin: auto 0;
  max-width: 1920px;

  @media (min-width: 768px) {
    border-radius: var(--artrade-radii-2xl);
    margin: auto var(--artrade-space-12);
  }
}
</style>
