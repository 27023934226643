import {
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  createRouter,
  createWebHistory
} from 'vue-router'
import { routes } from './routes'
import { RouteName } from './types'

/**
 * Reset to top on certains pages
 */
function scrollBehavior(
  to: RouteLocationNormalized,
  from: RouteLocationNormalizedLoaded,
  savedPosition
) {
  // Disable scroll to top when changing only queryparams
  if (to.name === from.name) {
    return

    // Disable scroll to top when navigate between tabs (feed, category)
  } else if (to.name === RouteName.FeedArtwork && from.name === RouteName.FeedCollection) {
    return
  } else if (to.name === RouteName.FeedCollection && from.name === RouteName.FeedArtwork) {
    return
  } else if (to.name === RouteName.CategoryArtwork && from.name === RouteName.CategoryCollection) {
    return
  } else if (to.name === RouteName.CategoryCollection && from.name === RouteName.CategoryArtwork) {
    return
  } else if (
    to.name === RouteName.FragmentArtworkAbout &&
    from.name === RouteName.FragmentArtworkTokenomic
  ) {
    return
  } else if (
    to.name === RouteName.FragmentArtworkAbout &&
    from.name === RouteName.FragmentArtworkInvestmentThesis
  ) {
    return
  } else if (
    to.name === RouteName.FragmentArtworkTokenomic &&
    from.name === RouteName.FragmentArtworkAbout
  ) {
    return
  } else if (
    to.name === RouteName.FragmentArtworkTokenomic &&
    from.name === RouteName.FragmentArtworkInvestmentThesis
  ) {
    return
  } else if (
    to.name === RouteName.FragmentArtworkInvestmentThesis &&
    from.name === RouteName.FragmentArtworkAbout
  ) {
    return
  } else if (
    to.name === RouteName.FragmentArtworkInvestmentThesis &&
    from.name === RouteName.FragmentArtworkTokenomic
  ) {
    return
  } else if (to.name === RouteName.FeedArtwork && from.name === RouteName.Nft) {
    return savedPosition
  } else if (to.name === RouteName.FeedCollection && from.name === RouteName.Collection) {
    return savedPosition
  } else {
    // always scroll to top
    return { top: 0 }
  }
}

export * from './types'

export default createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior
})
