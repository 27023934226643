<template>
  <component :is="as" :class="classname">
    <Icon v-if="Boolean(icon)" :name="leftIcon || icon" :size="12" />
    <slot></slot>
    <Icon v-if="Boolean(rightIcon)" :name="rightIcon" :size="12" />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { TagProps } from '../types'
import { Icon } from './icons'

const props = withDefaults(defineProps<TagProps>(), {
  size: 'md',
  variant: 'outline',
  rounded: false,
  withAvatar: false,
  as: 'span',
  colorScheme: 'black'
})

const classname = computed(() => {
  const output = ['artrade-tag', `-${props.size}`, `-${props.variant}`, `-${props.colorScheme}`]

  if (props.rounded) {
    output.push('-rounded')
  }

  if (props.withAvatar) {
    output.push('-with-avatar')
  }

  if (props.alignSelf) {
    output.push(`-align-self-${props.alignSelf}`)
  }

  return output.join(' ')
})
</script>

<style scoped lang="scss">
.artrade-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  border-radius: var(--artrade-radii-xs);
  padding: 0 var(--artrade-space-4);
  gap: var(--artrade-space-3);
  font-size: var(--artrade-fontSizes-xs);

  &.-primary {
    background-color: var(--artrade-colors-black);
    color: white;

    :deep(.artrade-icon) {
      color: var(--artrade-colors-white);
    }

    &.-green {
      background-color: var(--artrade-colors-green-700);
      color: white;
    }

    &.-red {
      background-color: var(--artrade-colors-red-700);
      color: white;
    }

    &.-grey {
      background-color: var(--artrade-colors-grey-100);
      color: var(--artrade-colors-grey-800);
    }

    &.-black {
      background-color: var(--artrade-colors-black);
      color: var(--artrade-colors-white);
    }
  }

  &.-outline {
    background-color: var(--artrade-colors-white);
    border: 1px solid var(--artrade-colors-grey-200);

    &.-black {
      border-color: var(--artrade-colors-black);
    }

    &.-green {
      border-color: var(--artrade-colors-green-700);
      color: var(--artrade-colors-green-700);
    }

    &.-red {
      border-color: var(--artrade-colors-red-700);
      color: var(--artrade-colors-red-700);
    }
  }

  &.-ghost {
    background-color: var(--artrade-colors-grey-100);

    &.-black {
      background-color: var(--artrade-colors-grey-100);
      color: var(--artrade-colors-black);
    }

    &.-green {
      background-color: var(--artrade-colors-green-100);
      color: var(--artrade-colors-green-900);
    }

    &.-red {
      background-color: var(--artrade-colors-red-50);
      color: var(--artrade-colors-red-900);
    }

    &.-grey {
      background-color: var(--artrade-colors-grey-100);
      color: var(--artrade-colors-grey-700);
    }
  }

  &.-xs {
    height: 20px;
    padding: 0 var(--artrade-space-3);
    font-size: 10px;

    :deep(.artrade-icon) {
      width: 12px;
      height: 12px;
    }
  }

  &.-sm {
    height: 24px;
    padding: 0 var(--artrade-space-3);

    :deep(.artrade-icon) {
      width: 14px;
      height: 14px;
    }
  }

  &.-md {
    height: 30px;

    :deep(.artrade-icon) {
      width: 16px;
      height: 16px;
    }
  }

  &.-lg {
    height: 36px;
    padding: 0 var(--artrade-space-6);

    :deep(.artrade-icon) {
      width: 18px;
      height: 18px;
    }
  }

  &.-with-avatar {
    &.-sm {
      padding-left: var(--artrade-space-1);
    }

    &.-md {
      padding-left: var(--artrade-space-2);
    }

    &.-lg {
      padding-left: var(--artrade-space-4);
    }
  }

  &.-rounded {
    border-radius: var(--artrade-radii-full);
  }

  &.-align-self-start {
    align-self: flex-start;
  }

  &.-align-self-center {
    align-self: center;
  }

  &.-align-self-end {
    align-self: flex-end;
  }
}
</style>
