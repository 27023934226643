<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5H28C34.6274 0.5 40 5.87258 40 12.5V28.5C40 35.1274 34.6274 40.5 28 40.5H12C5.37258 40.5 0 35.1274 0 28.5V12.5Z"
      fill="#1A1A1A"
    />
    <g clip-path="url(#clip0_995_11794)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.3294 28.9676C26.1727 31.0895 23.2139 32.3986 19.9493 32.3986C16.692 32.3986 13.739 31.0952 11.5835 28.9815C16.4771 25.1275 23.4308 25.1228 28.3294 28.9676ZM28.4675 28.8295C30.5895 26.6728 31.8986 23.714 31.8986 20.4493C31.8986 17.1929 30.5959 14.2406 28.4834 12.0853C24.6275 16.992 24.6223 23.9326 28.4675 28.8295ZM28.3474 11.9489C23.4419 15.8126 16.4659 15.8079 11.5655 11.9348C13.7224 9.8107 16.6828 8.49998 19.9493 8.49998C23.223 8.49998 26.1892 9.81648 28.3474 11.9489ZM11.4348 12.0655C9.31072 14.2224 8 17.1828 8 20.4493C8 23.723 9.31649 26.6892 11.4489 28.8474C15.3127 23.9419 15.3079 16.9659 11.4348 12.0655Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_995_11794">
        <rect width="24" height="24" fill="white" transform="translate(8 8.49998)" />
      </clipPath>
    </defs>
  </svg>
</template>
