import { connection } from '@/utils/solana/initConnection'
import { reactive } from 'vue'

export const time = reactive({
  timestamp: null,
  timestampSolana: null,
  get: async function () {
    if (this.timestampSolana > 0) {
      let difference = Math.round(new Date().getTime() / 1000) - this.timestamp

      return this.timestampSolana + difference
    }

    if (!connection) {
      return this.get()
    }

    let slot = await connection.getSlot()

    var timestamp_solana

    try {
      timestamp_solana = await connection.getBlockTime(slot)

      this.timestampSolana = timestamp_solana
      this.timestamp = Math.round(new Date().getTime() / 1000)

      return timestamp_solana
    } catch (e) {
      return await this.get_block_time(true)
    }
  }
})

time.get();
