<template>
  <img src="/images/fragments/warh-token.webp" alt="WARH token image" />
</template>

<style scoped lang="scss">
img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  object-position: center;
  border-radius: var(--artrade-radii-full);
}
</style>
