import { Tag } from '@/models'
import backend from '@/utils/backend'

async function getAll(): Promise<Tag[]> {
  return await backend.get('tags/get')
}

export default {
  getAll
}
