<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
      fill="#1A1A1A"
    />
    <path
      d="M12.9146 15.5213C16.829 11.6907 23.1737 11.6907 27.0881 15.5213L27.5585 15.9835C27.7531 16.1755 27.7531 16.4863 27.5585 16.6783L25.9473 18.2543C25.85 18.3489 25.6905 18.3489 25.5932 18.2543L24.9444 17.619C22.214 14.9482 17.786 14.9482 15.0556 17.619L14.3609 18.2976C14.2636 18.3922 14.1041 18.3922 14.0068 18.2976L12.3956 16.7215C12.2009 16.5296 12.2009 16.2187 12.3956 16.0268L12.9119 15.5213H12.9146ZM30.4213 18.7814L31.854 20.1845C32.0487 20.3764 32.0487 20.6873 31.854 20.8792L25.3877 27.2077C25.1931 27.3996 24.8741 27.3996 24.6794 27.2077L20.0892 22.7175C20.0406 22.6688 19.9622 22.6688 19.9108 22.7175L15.3206 27.2077C15.1259 27.3996 14.8069 27.3996 14.6123 27.2077L8.14598 20.8792C7.95134 20.6873 7.95134 20.3764 8.14598 20.1845L9.57873 18.7814C9.77337 18.5895 10.0924 18.5895 10.287 18.7814L14.8772 23.2716C14.9259 23.3203 15.0043 23.3203 15.0556 23.2716L19.6459 18.7814C19.8405 18.5895 20.1595 18.5895 20.3541 18.7814L24.9444 23.2743C24.993 23.323 25.0714 23.323 25.1228 23.2743L29.713 18.7841C29.9076 18.5922 30.2266 18.5922 30.4213 18.7841V18.7814Z"
      fill="white"
    />
  </svg>
</template>
