<template>
  <Stack :class="['artrade-search', 'desktop', { '-active': isOpen }]">
    <div class="input-left-element">
      <Icon name="search" />
    </div>
    <input
      :placeholder="t('Search by artist, artwork, collection...')"
      @click="isOpen = true"
      v-model="query"
      ref="trigger"
    />
    <div class="input-right-element" v-if="query?.length > 0 && isOpen">
      <Button variant="ghost" @click="close">{{ t('Clear') }}</Button>
    </div>
  </Stack>

  <div class="dropdown-overlay" v-if="isOpen" @click="toggle"></div>

  <Transition name="dropdown">
    <div class="dropdown-wrapper" v-if="isOpen" ref="menu" :style="menuStyle">
      <div :class="['dropdown', { '-show': query?.length > 0 }]">
        <Stack class="dropdown-list" role="list" direction="column" :spacing="10" flex>
          <Stack direction="column" :spacing="6" flex>
            <template v-if="noResults">
              <Stack flex alignItems="center" justifyContent="center">
                <Stack direction="column" :spacing="3">
                  <Heading as="h5" size="sm" align="center">{{ t('No search results') }}</Heading>
                  <Text color="grey.600" align="center">{{
                    t('There are no results for "{query}"', { query: query })
                  }}</Text>
                </Stack>
              </Stack>
            </template>
            <template v-else>
              <template v-if="nfts?.length > 0">
                <Stack direction="column" :spacing="4">
                  <Text color="grey.700" fontWeight="semibold">{{ t('Artworks') }}</Text>
                  <Stack direction="column" :spacing="0">
                    <RouterLink
                      v-for="nft of nfts"
                      :key="nft?.id"
                      :to="`/nft/${nft?.urlName}`"
                      @click="close"
                      class="artrade-search-item"
                    >
                      <Stack :spacing="7">
                        <OptimizedAvatar size="md" squared :src="nft?.mainImageUid" />
                        <Stack direction="column" :spacing="3">
                          <Text fontWeight="semibold">{{ nft?.title }}</Text>
                          <Stack :spacing="3" alignItems="center">
                            <OptimizedAvatar size="xs" :src="nft?.creator?.avatarUrlUid" />
                            <Text size="xs" color="grey.700">@{{ nft?.creator?.nickname }}</Text>
                          </Stack>
                        </Stack>
                      </Stack>
                    </RouterLink>
                  </Stack>
                </Stack>
              </template>
            </template>

            <template v-if="collections?.length > 0">
              <Stack direction="column" :spacing="4">
                <Text color="grey.700" fontWeight="semibold">{{ t('Collections') }}</Text>
                <Stack direction="column" :spacing="0">
                  <RouterLink
                    v-for="collection of collections"
                    :key="collection?.id"
                    :to="`/collection/${collection?.slug}`"
                    @click="close"
                    class="artrade-search-item"
                  >
                    <Stack :spacing="7">
                      <OptimizedAvatar size="md" squared :src="collection?.mainImageUid" />
                      <Stack direction="column" :spacing="3">
                        <Text fontWeight="semibold">{{ collection?.title }}</Text>
                        <Stack :spacing="3" alignItems="center">
                          <OptimizedAvatar size="xs" :src="collection?.creator?.avatarUrlUid" />
                          <Text size="xs" color="grey.700"
                            >@{{ collection?.creator?.nickname }}</Text
                          >
                        </Stack>
                      </Stack>
                    </Stack>
                  </RouterLink>
                </Stack>
              </Stack>
            </template>

            <template v-if="profiles?.length > 0">
              <Stack direction="column" :spacing="4">
                <Text color="grey.700" fontWeight="semibold">{{ t('Profiles') }}</Text>
                <Stack direction="column" :spacing="0">
                  <RouterLink
                    v-for="profile of profiles"
                    :key="profile?.id"
                    :to="`/profile/${profile?.urlName}`"
                    @click="close"
                    class="artrade-search-item"
                  >
                    <Stack :spacing="7" alignItems="center">
                      <OptimizedAvatar size="md" :src="profile?.avatarUrlUid" />
                      <Text fontWeight="semibold">@{{ profile?.nickname }}</Text>
                    </Stack>
                  </RouterLink>
                </Stack>
              </Stack>
            </template>
          </Stack>
        </Stack>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import OptimizedAvatar from '@/components/OptimizedAvatar.vue'
import Heading from '@/components/design/Heading.vue'
import Icon from '@/components/design/Icon.vue'
import Stack from '@/components/design/Stack.vue'
import Text from '@/components/design/Text.vue'
import { useMenu } from '@/composables'
import { Collection, Nft, User } from '@/models'
import backend from '@/utils/backend'
import { computed, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from '../../Button.vue'

const { t } = useI18n()
const query = ref('')
const nfts = ref<Nft[]>([])
const profiles = ref<User[]>([])
const collections = ref<Collection[]>([])
const { trigger, menu, isOpen, menuStyle } = useMenu()

function toggle() {
  isOpen.value = !isOpen.value
}

function close() {
  query.value = ''
  isOpen.value = false
}

// When query changes, execute automatically (query is a dependency of watchEffect)
watchEffect(async () => {
  if (query.value === '') return

  const results = await backend.get(`search/${query.value}`)

  nfts.value = results.artworks
  profiles.value = results.profiles
  collections.value = results.collections

  isOpen.value = true
})

const noResults = computed(
  () =>
    nfts?.value?.length === 0 && collections?.value?.length === 0 && profiles?.value?.length === 0
)
</script>

<style scoped lang="scss">
.artrade-search {
  display: flex;
  position: relative;
  isolation: isolate;
  background-color: var(--artrade-colors-grey-100);
  border-radius: var(--artrade-radii-full);
  width: 100%;
  max-width: 424px;
  transition: all 0.2s ease;

  &.-active {
    z-index: 9999;
    background-color: var(--artrade-colors-white);

    .input-left-element,
    .input-right-element {
      :deep(svg) {
        color: var(--artrade-colors-black);
      }
    }
  }

  .input-left-element,
  .input-right-element {
    width: var(--artrade-sizes-14);
    height: var(--artrade-sizes-12);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    :deep(svg) {
      color: var(--artrade-colors-grey-500);
    }
  }

  .input-left-element {
    left: 0;
  }

  .input-right-element {
    right: var(--artrade-space-6);
  }

  input {
    width: 100%;
    border: none;
    appearance: none;
    color: var(--artrade-colors-black);
    padding: 0 var(--artrade-space-2) 0 var(--artrade-space-15);
    height: var(--artrade-sizes-12);
    width: 100%;
    outline: 0;
    background-color: transparent;
    font-weight: var(--artrade-fontWeights-medium);

    &::placeholder {
      color: var(--artrade-colors-grey-500);
    }
  }
}

.artrade-search-item {
  border-radius: var(--artrade-radii-md);
  background-color: var(--artrade-colors-white);
  padding: var(--artrade-space-5);
  margin: 0 calc(var(--artrade-space-5) * -1);
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: var(--artrade-colors-grey-100);
  }

  &:active {
    background-color: var(--artrade-colors-grey-50);
  }
}

.dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9998;
  background-color: var(--artrade-overlay);
  transition: all 0.2s ease;
}

.dropdown-wrapper {
  transition: opacity 0.2s ease;
  z-index: 9999;
  position: absolute;

  .dropdown {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: var(--artrade-radii-lg);
    border: 1px solid var(--artrade-colors-grey-100);
    background-color: var(--artrade-colors-white);
    box-shadow: var(--artrade-shadows-md);
    transform: scale(1);
    opacity: 1;
    transition: all 0.2s ease;
    overflow: auto;
    opacity: 0;
    pointer-events: none;
    padding: var(--artrade-space-9);
    overflow: auto;
    max-height: calc(100vh - 88px);

    &.-show {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.dropdown-enter-from {
  opacity: 0;
}

.dropdown-leave-to {
  opacity: 0;
}

.dropdown-enter-from .dropdown,
.dropdown-leave-to .dropdown {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
</style>
