export function useMouse() {
  function stop(evt: MouseEvent) {
    evt.preventDefault()
    evt.stopPropagation()
    evt.stopImmediatePropagation()
  }

  return {
    stop
  }
}
