<template>
  <SmoothScroll>
    <header class="artrade--header">
      <GalleryGlobe />
      <div class="artrade--header-inner">
        <div class="artrade--header-content">
          <div class="row">
            <div class="col-12">
              <AnimatedText class="artrade--display-1" trigger=".artrade--header" :delay="1">
                {{ t('THE FIRST') }}
              </AnimatedText>
            </div>
            <div class="offset-sm-2 col-md-8 d-flex justify-content-end justify-content-sm-start">
              <AnimatedText class="artrade--display-1" trigger=".artrade--header" :delay="1.2">
                {{ t('MARKETPLACE') }}
              </AnimatedText>
            </div>
            <div class="offset-3 offset-sm-1 col-md-11 align-items-center">
              <AnimatedText class="artrade--display-1" trigger=".artrade--header" :delay="1.4">
                {{ t('FOR ART') }}
              </AnimatedText>
            </div>
            <div class="offset-sm-5 col-sm-7 d-flex align-items-center gap-7 gap-md-9">
              <AnimatedText class="artrade--display-1" trigger=".artrade--header" :delay="1.4">
                {{ t('#RWA') }}
              </AnimatedText>
              <AnimatedText
                as="h2"
                class="artrade--display-5 font-serif"
                trigger=".artrade--header"
                :delay="2.2"
              >
                {{ t('(Real World Assets)') }}
              </AnimatedText>
            </div>
          </div>
        </div>

        <AnimatedLink
          trigger=".artrade--header"
          :delay="3"
          :href="whitepaperBaseUrl"
          target="_blank"
          >{{ t('Read our whitepaper') }}</AnimatedLink
        >
      </div>
    </header>
    <main>
      <section class="artrade--section -intro">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <AnimatedText
              as="h4"
              class="artrade--display-3 text-center leading-lg"
              :duration="1"
              :scrub="true"
            >
              {{
                t(
                  'Our goal is to bring a new and optimistic perspective for the stakeholders of the art market. We aim to provide artists, gallery owners, collectors and whoever taking up interest in art space for more independence, in total transparency.'
                )
              }}
            </AnimatedText>
          </div>
        </div>
      </section>
      <ExpandableVideo />
      <section class="artrade--section -feature">
        <div class="artrade--feature-item -feature-solana">
          <div class="row">
            <div class="col-md-8 offset-md-1">
              <AnimatedTag class="mb-2" trigger=".artrade--feature-item.-feature-solana"
                >#OnlyPossibleOnSolana</AnimatedTag
              >
              <AnimatedText
                as="h2"
                trigger=".artrade--feature-item.-feature-solana"
                class="artrade--display-1"
                :duration="1"
              >
                Only possible
              </AnimatedText>
              <Stack
                :direction="{
                  base: 'column',
                  md: 'row'
                }"
                :alignItems="{
                  base: 'start',
                  md: 'center'
                }"
                :spacing="{
                  base: 5,
                  md: 12
                }"
                class="mb-8"
              >
                <AnimatedText
                  as="h2"
                  trigger=".artrade--feature-item.-feature-solana"
                  class="artrade--display-1"
                  :duration="1"
                  :delay="0.1"
                >
                  on Solana
                </AnimatedText>
                <AnimatedSolana
                  class="artrade--solana-logo"
                  trigger=".artrade--feature-item.-feature-solana"
                />
              </Stack>
            </div>
            <div class="col-md-4 offset-md-5">
              <Stack direction="column" :spacing="0">
                <AnimatedText
                  as="p"
                  trigger=".artrade--feature-item.-feature-solana"
                  class="artrade--text-1"
                  :duration="1"
                  :delay="0.2"
                >
                  {{ t('Artrade is powered by the most efficient') }}
                </AnimatedText>
                <AnimatedText
                  as="p"
                  trigger=".artrade--feature-item.-feature-solana"
                  class="artrade--text-1"
                  :duration="1"
                  :delay="0.2"
                >
                  {{ t(' blockchain in the world.') }}
                </AnimatedText>
              </Stack>
              <AnimatedLink
                class="mt-10"
                trigger=".artrade--feature-item.-feature-solana"
                :delay="0.3"
                :href="`${whitepaperBaseUrl}/eco-friendly-and-scalable`"
                target="_blank"
                >{{ t('Learn more') }}</AnimatedLink
              >
            </div>
          </div>
          <div class="row artrade--feature-grid -feature-solana">
            <img
              src="/images/trail/artrade-trail-image-1.webp"
              class="trail--image -feature-solana col-md-2"
            />
            <img
              src="/images/trail/artrade-trail-image-2.webp"
              class="trail--image -feature-solana col-md-2"
            />
            <img
              src="/images/trail/artrade-trail-image-3.webp"
              class="trail--image -feature-solana col-md-2"
            />
            <img
              src="/images/trail/artrade-trail-image-4.webp"
              class="trail--image -feature-solana col-md-2"
            />
            <img
              src="/images/trail/artrade-trail-image-5.webp"
              class="trail--image -feature-solana col-md-2"
            />
            <img
              src="/images/trail/artrade-trail-image-6.webp"
              class="trail--image -feature-solana col-md-2"
            />
          </div>
        </div>
        <div class="artrade--feature-item -feature-real d-flex flex-column justify-content-end">
          <div class="row artrade--feature-grid -feature-real">
            <img
              src="/images/trail/artrade-trail-image-7.webp"
              class="trail--image -feature-real col-md-2"
            />
            <img
              src="/images/trail/artrade-trail-image-8.webp"
              class="trail--image -feature-real col-md-2"
            />
            <img
              src="/images/trail/artrade-trail-image-9.webp"
              class="trail--image -feature-real col-md-2"
            />
            <img
              src="/images/trail/artrade-trail-image-10.webp"
              class="trail--image -feature-real col-md-2"
            />
            <img
              src="/images/trail/artrade-trail-image-11.webp"
              class="trail--image -feature-real col-md-2"
            />
            <img
              src="/images/trail/artrade-trail-image-12.webp"
              class="trail--image -feature-real col-md-2"
            />
          </div>
          <div class="row">
            <div class="col-md-8 offset-md-5">
              <AnimatedTag
                class="mb-2"
                trigger=".artrade--feature-item.-feature-real"
                start="center 80%"
                end="center 20%"
                :containerAnimation="containerAnimation"
                >#REALprotocol</AnimatedTag
              >
              <AnimatedText
                as="h2"
                trigger=".artrade--feature-item.-feature-real"
                class="artrade--display-1"
                :duration="1"
                start="center 80%"
                end="center 20%"
                :containerAnimation="containerAnimation"
              >
                REAL
              </AnimatedText>
              <AnimatedText
                as="h2"
                trigger=".artrade--feature-item.-feature-real"
                class="artrade--display-1 mb-8"
                :duration="1"
                start="center 80%"
                end="center 20%"
                :containerAnimation="containerAnimation"
              >
                Protocol
              </AnimatedText>
            </div>
            <div class="col-md-4 offset-md-7">
              <Stack direction="column" :spacing="0">
                <AnimatedText
                  as="p"
                  trigger=".artrade--feature-item.-feature-real"
                  class="artrade--text-1 mb-10"
                  :duration="1"
                  :delay="0.2"
                  start="center 80%"
                  end="center 20%"
                  :containerAnimation="containerAnimation"
                >
                  {{
                    t(
                      "A first of its kind innovation, Artrade's REAL protocol revolutionizes the traditional art market with NFT-NFC synergy."
                    )
                  }}
                </AnimatedText>
              </Stack>
              <AnimatedLink
                trigger=".artrade--feature-item.-feature-real"
                :delay="0.3"
                start="center 80%"
                end="center 20%"
                :containerAnimation="containerAnimation"
                :href="`${whitepaperBaseUrl}/real-protocol`"
                target="_blank"
                >{{ t('Learn more') }}</AnimatedLink
              >
            </div>
          </div>
        </div>
        <div class="artrade--feature-item -last d-flex align-items-center justify-content-center">
          <div class="row">
            <div class="col-md-6 offset-md-3">
              <AnimatedText
                as="h2"
                class="artrade--display-2 text-grey-500"
                :duration="1"
                trigger=".artrade--feature-item.-last"
                start="center 80%"
                end="center 20%"
                :containerAnimation="containerAnimation"
                :scrub="true"
              >
                <span class="text-black">{{ t('Art goes digital. ') }}</span>
                {{ t('Online platforms drive ') }}
                <span class="text-black">11 {{ t('billion') }}</span>
                {{ t(' in sales, representing') }}
                <span class="text-black">16%</span>
                {{ t('of the total art market in ') }}
                <span class="text-black"> 2022</span>
                .
                <br />
                {{ t('Artrade’s') }}
                <span class="text-black"> 5% </span>

                {{ t('commission sparks an unparalleled competitive advantage. With') }}
                <span class="font-serif text-black">{{ t(' REAL protocol ') }}</span>
                {{ t('and decentralization, Artrade cuts commissions by up to') }}
                <span class="text-black"> 10x </span>
                {{ t('compared to rivals!') }}
              </AnimatedText>
            </div>
          </div>
        </div>
      </section>
      <div class="bg-trigger"></div>
      <section class="artrade--section -token">
        <div class="row artrade--token-title">
          <div class="col-md-12">
            <AnimatedText
              class="artrade--display-1 text-white"
              trigger=".artrade--token-title"
              start="top 60%"
              :scrub="true"
            >
              {{ t('LET’S BUILD') }}
            </AnimatedText>
          </div>
          <div class="offset-md-3 col-md-9">
            <AnimatedText
              class="artrade--display-1 text-white"
              trigger=".artrade--token-title"
              start="top 60%"
              :scrub="true"
            >
              {{ t('THE FUTURE') }}
            </AnimatedText>
          </div>
          <div class="col-md-12 d-flex justify-content-end">
            <AnimatedText
              class="artrade--display-1 text-white"
              trigger=".artrade--token-title"
              start="top 60%"
              :scrub="true"
            >
              {{ t('OF THE ART MARKET') }}
            </AnimatedText>
          </div>
        </div>
        <Stack
          class="artrade--token-title"
          :direction="{
            base: 'column',
            md: 'row'
          }"
          :spacing="0"
        >
          <div class="d-flex justify-content-end justify-content-md-start">
            <AnimatedText
              class="artrade--display-1 text-white"
              trigger=".artrade--token-title"
              start="top 60%"
              :scrub="true"
            >
              {{ t('WITH ATR') }}
            </AnimatedText>
          </div>

          <div v-if="!isMobileDevice" class="token-target-area" ref="coinTargetArea"></div>
          <div class="d-flex justify-content-md-end">
            <AnimatedText
              class="artrade--display-1 text-white"
              trigger=".artrade--token-title"
              start="top 60%"
              :scrub="true"
            >
              {{ t('TOKEN') }}
            </AnimatedText>
          </div>
        </Stack>
        <AnimatedCoin parent=".artrade--section.-token" target=".token-target-area" />
        <div class="row artrade--token-info mb-16 mb-md-20 gap-16 gap-md-0">
          <div class="offset-md-1 col-md-4">
            <AnimatedText
              as="p"
              trigger=".artrade--token-info"
              class="artrade--text-1 text-white mb-11"
            >
              {{
                t(
                  'A community token fostering NFT technology adoption across the entire art market and rewarding the holders.'
                )
              }}
            </AnimatedText>
            <Stack :spacing="10">
              <AnimatedLink
                mode="dark"
                trigger=".artrade--token-info"
                :href="`${whitepaperBaseUrl}/tokenomics`"
                target="_blank"
                >Tokenomics</AnimatedLink
              ><AnimatedLink
                mode="dark"
                trigger=".artrade--token-info"
                href="https://raydium.io/swap/?inputCurrency=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&inputSymbol=ATR&outputCurrency=ATRLuHph8dxnPny4WSNW7fxkhbeivBrtWbY6BfB4xpLj&fixed=in"
                target="_blank"
                >{{ t('Buy token') }}</AnimatedLink
              ></Stack
            >
          </div>
          <div
            class="offset-md-1 col-md-6"
            v-if="tokenStats.marketcap > 0 && tokenStats.supply > 0 && tokenStats.price > 0"
          >
            <div class="row mb-md-16">
              <div class="col-md-6">
                <AnimatedDivider
                  trigger=".artrade--token-info"
                  color="grey.1100"
                  class="mb-10 d-none d-md-flex"
                  :scrub="true"
                />
                <AnimatedText
                  as="p"
                  trigger=".artrade--token-info"
                  class="artrade--text-1 text-white mb-1"
                  :duration="1"
                  :delay="0.2"
                  :scrub="true"
                >
                  {{ t('Marketcap') }}
                </AnimatedText>
                <AnimatedText
                  as="h3"
                  trigger=".artrade--token-info"
                  class="artrade--display-3 text-white"
                  :duration="1"
                  :delay="0.2"
                  :scrub="true"
                >
                  ${{ formatCash(tokenStats.marketcap) }}
                </AnimatedText>
              </div>
              <div class="col-md-6">
                <AnimatedDivider
                  trigger=".artrade--token-info"
                  color="grey.1100"
                  class="mb-10 mt-10 mt-md-0"
                  :scrub="true"
                />
                <AnimatedText
                  as="p"
                  trigger=".artrade--token-info"
                  class="artrade--text-1 text-white mb-1"
                  :duration="1"
                  :delay="0.2"
                  :scrub="true"
                >
                  {{ t('Price') }}
                </AnimatedText>
                <AnimatedText
                  as="h3"
                  trigger=".artrade--token-info"
                  class="artrade--display-3 text-white"
                  :duration="1"
                  :delay="0.2"
                  :scrub="true"
                >
                  ${{ tokenStats.price }}
                </AnimatedText>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <AnimatedDivider
                  trigger=".artrade--token-info"
                  color="grey.1100"
                  class="mb-10 mt-10 mt-md-0"
                  :scrub="true"
                />
                <AnimatedText
                  as="p"
                  trigger=".artrade--token-info"
                  class="artrade--text-1 text-white mb-1"
                  :duration="1"
                  :delay="0.2"
                  :scrub="true"
                >
                  {{ t('Total Supply') }}
                </AnimatedText>
                <AnimatedText
                  as="h3"
                  trigger=".artrade--token-info"
                  class="artrade--display-3 text-white"
                  :duration="1"
                  :delay="0.2"
                  :scrub="true"
                >
                  {{ formatCash(tokenStats?.supply) }} ATR
                </AnimatedText>
              </div>
              <div class="col-md-6">
                <AnimatedDivider
                  trigger=".artrade--token-info"
                  color="grey.1100"
                  class="mb-10 mt-10 mt-md-0"
                  :scrub="true"
                />
                <AnimatedText
                  as="p"
                  trigger=".artrade--token-info"
                  class="artrade--text-1 text-white mb-1"
                  :duration="1"
                  :delay="0.2"
                  :scrub="true"
                >
                  {{ t('Total burnt') }}
                </AnimatedText>
                <AnimatedText
                  as="h3"
                  trigger=".artrade--token-info"
                  class="artrade--display-3 text-white"
                  :duration="1"
                  :delay="0.2"
                  :scrub="true"
                >
                  {{ formatCash(tokenStats.burnt) }} ATR
                </AnimatedText>
              </div>
            </div>
          </div>
        </div>
        <Video>
          <source
            src="/video/artrade-token@1920x1080.webm"
            type="video/webm"
            media="screen and (min-width:768px)"
          />
          <source src="/video/artrade-token@1080x1080.webm" type="video/webm" />
        </Video>
      </section>
      <section class="artrade--section -join">
        <div class="row">
          <div class="offset-md-1 col-md-6">
            <AnimatedText as="h2" class="artrade--display-2 text-medium leading-md" :duration="1">
              {{ t('Join the NFT art revolution & celebrate your uniqueness with') }}
            </AnimatedText>
          </div>
        </div>
      </section>
      <section class="artrade--section -ambassador">
        <a
          href="https://www.instagram.com/john_hamon"
          target="_blank"
          class="artrade--ambassador-item -hamon"
        >
          <figure>
            <Image
              sizes="(max-width: 400px) 400px, (max-width: 1440px) 1440px, 1920px"
              srcSets="/images/ambassador/artrade-ambassador-hamon@400w.webp 400w, /images/ambassador/artrade-ambassador-hamon@1440w.webp 1440w, /images/ambassador/artrade-ambassador-hamon.webp"
              alt="John Hamon"
            />
          </figure>
          <AnimatedText
            as="h5"
            class="artrade--text-2 text-medium"
            :duration="1"
            trigger=".artrade--ambassador-item.-hamon"
            :containerAnimation="ambassadorAnimationContainer"
          >
            John Hamon —
            <span class="font-serif"> Urban Art</span>
          </AnimatedText>
        </a>
        <a
          href="https://www.instagram.com/leocaillard"
          target="_blank"
          class="artrade--ambassador-item -caillard"
        >
          <figure>
            <Image
              sizes="(max-width: 400px) 400pxpx, (max-width: 1440px) 1440px, 1920px"
              srcSets="/images/ambassador/artrade-ambassador-caillard@400w.webp 400w, /images/ambassador/artrade-ambassador-caillard@1440w.webp 1440w, /images/ambassador/artrade-ambassador-caillard.webp"
              alt="Léo Caillard"
            />
          </figure>
          <AnimatedText
            as="h5"
            class="artrade--text-2 text-medium"
            :duration="1"
            trigger=".artrade--ambassador-item.-caillard"
            :containerAnimation="ambassadorAnimationContainer"
          >
            Léo Caillard —
            <span class="font-serif"> {{ t('Real Marble Sculptures') }}</span>
          </AnimatedText>
        </a>
        <a
          href="https://www.instagram.com/benjaminboutinspark"
          target="_blank"
          class="artrade--ambassador-item -spark"
        >
          <figure>
            <Image
              sizes="(max-width: 400px) 400px, (max-width: 1440px) 1440px, 1920px"
              srcSets="/images/ambassador/artrade-ambassador-spark@400w.webp 400w, /images/ambassador/artrade-ambassador-spark@1440w.webp 1440w, /images/ambassador/artrade-ambassador-spark.webp"
              alt="Benjamin Spark"
            />
          </figure>
          <AnimatedText
            as="h5"
            class="artrade--text-2 text-medium"
            :duration="1"
            trigger=".artrade--ambassador-item.-spark"
            :containerAnimation="ambassadorAnimationContainer"
          >
            Benjamin Spark —
            <span class="font-serif"> Street Pop Art</span>
          </AnimatedText>
        </a>
        <a
          href="https://www.instagram.com/lionel_deluy"
          target="_blank"
          class="artrade--ambassador-item -deluy"
        >
          <figure>
            <Image
              sizes="(max-width: 400px) 400px, (max-width: 1440px) 1440px, 1920px"
              srcSets="/images/ambassador/artrade-ambassador-deluy@400w.webp 400w, /images/ambassador/artrade-ambassador-deluy@1440w.webp 1440w, /images/ambassador/artrade-ambassador-deluy.webp"
              alt="Lionel Deluy"
              position="left"
            />
          </figure>
          <AnimatedText
            as="h5"
            class="artrade--text-2 text-medium"
            :duration="1"
            trigger=".artrade--ambassador-item.-deluy"
            :containerAnimation="ambassadorAnimationContainer"
          >
            Lionel Deluy —
            <span class="font-serif"> {{ t('Celibrity Photography') }}</span>
          </AnimatedText>
        </a>
        <a
          href="https://www.instagram.com/antoinepuisais"
          target="_blank"
          class="artrade--ambassador-item -puisais"
        >
          <figure>
            <Image
              sizes="(max-width: 400px) 400px, (max-width: 1440px) 1440px, 1920px"
              srcSets="/images/ambassador/artrade-ambassador-puisais@400w.webp 400w, /images/ambassador/artrade-ambassador-puisais@1440w.webp 1440w, /images/ambassador/artrade-ambassador-puisais.webp"
              alt="Antoine Puisais"
            />
          </figure>
          <AnimatedText
            as="h5"
            class="artrade--text-2 text-medium"
            :duration="1"
            trigger=".artrade--ambassador-item.-puisais"
            :containerAnimation="ambassadorAnimationContainer"
          >
            Antoine Puisais —
            <span class="font-serif"> {{ t('Abstract geometry') }}</span>
          </AnimatedText>
        </a>
        <a
          href="https://www.instagram.com/raphael_federici"
          target="_blank"
          class="artrade--ambassador-item -federici"
        >
          <figure>
            <Image
              sizes="(max-width: 400px) 400px, (max-width: 1440px) 1440px, 1920px"
              srcSets="/images/ambassador/artrade-ambassador-federici@400w.webp 400w, /images/ambassador/artrade-ambassador-federici@1440w.webp 1440w, /images/ambassador/artrade-ambassador-federici.webp"
              alt="Raphael Federici"
            />
          </figure>
          <AnimatedText
            as="h5"
            class="artrade--text-2 text-medium"
            :duration="1"
            trigger=".artrade--ambassador-item.-federici"
            :containerAnimation="ambassadorAnimationContainer"
          >
            Raphael Federici —
            <span class="font-serif"> {{ t('Neo pop expressionism') }}</span>
          </AnimatedText>
        </a>
      </section>
      <section class="artrade--section -roadmap">
        <div class="row mb-14 mb-md-17">
          <div class="col-md-4 offset-md-2">
            <AnimatedText
              as="h2"
              class="artrade--display-2 text-medium"
              :duration="1"
              trigger=".artrade--section.-roadmap"
              start="top 80%"
            >
              {{ t('Pioneering the future of art ownership') }}
            </AnimatedText>
          </div>
        </div>
        <div class="artrade--timeline row">
          <ul class="artrade--timeline-list col-md-8 offset-md-2">
            <li class="artrade--timeline-item pb-14 pb-md-17 -a">
              <AnimatedDivider
                trigger=".artrade--timeline-item.-a"
                class="mb-9 mb-md-13"
                color="black"
                start="top 80%"
              />
              <div class="row">
                <div class="col-4 col-md-6">
                  <AnimatedText
                    as="p"
                    trigger=".artrade--timeline-item.-a"
                    class="artrade--text-1 text-semibold"
                    start="top 80%"
                  >
                    Q4 2023
                  </AnimatedText>
                </div>
                <div class="col-8 col-md-6">
                  <AnimatedText
                    as="p"
                    trigger=".artrade--timeline-item.-a"
                    class="artrade--text-1"
                    start="top 80%"
                  >
                    {{ t('REAL protocol v1') }}
                  </AnimatedText>
                  <AnimatedText
                    as="p"
                    trigger=".artrade--timeline-item.-a"
                    class="artrade--text-1"
                    start="top 80%"
                  >
                    {{ t('Direct message feature') }}
                  </AnimatedText>
                </div>
              </div>
            </li>

            <li class="artrade--timeline-item pb-14 pb-md-17 -b">
              <AnimatedDivider
                trigger=".artrade--timeline-item.-b"
                class="mb-9 mb-md-13"
                color="black"
                start="top 80%"
              />
              <div class="row">
                <div class="col-4 col-md-6">
                  <AnimatedText
                    as="p"
                    trigger=".artrade--timeline-item.-b"
                    class="artrade--text-1 text-semibold"
                    start="top 80%"
                  >
                    Q1 2024
                  </AnimatedText>
                </div>
                <div class="col-8 col-md-6">
                  <AnimatedText
                    as="p"
                    trigger=".artrade--timeline-item.-b"
                    class="artrade--text-1"
                    start="top 80%"
                  >
                    {{ t('$ATR migration & integration') }}
                  </AnimatedText>
                </div>
              </div>
            </li>
            <li class="artrade--timeline-item pb-14 pb-md-17 -c">
              <AnimatedDivider
                trigger=".artrade--timeline-item.-c"
                class="mb-9 mb-md-13"
                color="black"
                start="top 80%"
              />
              <div class="row">
                <div class="col-4 col-md-6">
                  <AnimatedText
                    as="p"
                    trigger=".artrade--timeline-item.-c"
                    class="artrade--text-1 text-semibold"
                    start="top 80%"
                  >
                    Q2 2024
                  </AnimatedText>
                </div>
                <div class="col-8 col-md-6">
                  <AnimatedText
                    as="p"
                    trigger=".artrade--timeline-item.-c"
                    class="artrade--text-1"
                    start="top 80%"
                  >
                    {{
                      t(
                        'Introducing Fragments: tokenizing fine art masterpieces with fractionalization'
                      )
                    }}
                  </AnimatedText>
                </div>
              </div>
            </li>
            <li class="artrade--timeline-item pb-14 pb-md-17 -d">
              <AnimatedDivider
                trigger=".artrade--timeline-item.-d"
                class="mb-9 mb-md-13"
                color="black"
                start="top 80%"
              />
              <div class="row">
                <div class="col-4 col-md-6">
                  <AnimatedText
                    as="p"
                    trigger=".artrade--timeline-item.-d"
                    class="artrade--text-1 text-semibold"
                    start="top 80%"
                  >
                    Q3 2024
                  </AnimatedText>
                </div>
                <div class="col-8 col-md-6">
                  <AnimatedText
                    as="p"
                    trigger=".artrade--timeline-item.-d"
                    class="artrade--text-1"
                    start="top 80%"
                  >
                    {{
                      t(
                        'Masterpieces fractionalization, secondary trading features and DeFi integration'
                      )
                    }}
                  </AnimatedText>
                </div>
              </div>
            </li>
            <li class="artrade--timeline-item -e">
              <AnimatedDivider
                trigger=".artrade--timeline-item.-e"
                class="mb-9 mb-md-13"
                color="black"
                start="top 80%"
              />
              <div class="row">
                <div class="col-4 col-md-6">
                  <AnimatedText
                    as="p"
                    trigger=".artrade--timeline-item.-e"
                    class="artrade--text-1 text-semibold"
                    start="top 80%"
                  >
                    Q4 2024
                  </AnimatedText>
                </div>
                <div class="col-8 col-md-6">
                  <AnimatedText
                    as="p"
                    trigger=".artrade--timeline-item.-e"
                    class="artrade--text-1"
                    start="top 80%"
                  >
                    {{ t('Mainstream adoption') }}
                  </AnimatedText>
                  <AnimatedText
                    as="p"
                    trigger=".artrade--timeline-item.-e"
                    class="artrade--text-1"
                    start="top 80%"
                  >
                    {{ t('Custodial wallet integration') }}
                  </AnimatedText>
                  <AnimatedText
                    as="p"
                    trigger=".artrade--timeline-item.-e"
                    class="artrade--text-1"
                    start="top 80%"
                  >
                    {{ t('Credit card payment') }}
                  </AnimatedText>
                  <AnimatedText as="p" trigger=".artrade--timeline-item.-e" class="artrade--text-1">
                    {{ t('Mobile application launch') }}
                  </AnimatedText>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section v-if="false" class="artrade--section -team">
        <div class="row mb-14 mb-md-17">
          <div class="col-md-4">
            <AnimatedText
              as="h2"
              class="artrade--display-2 text-medium"
              :duration="1"
              trigger=".artrade--section.-team"
              start="top 80%"
            >
              {{ t('Who we are') }}
            </AnimatedText>
            <AnimatedText
              as="p"
              class="artrade--text-1"
              :duration="1"
              trigger=".artrade--section.-team"
              start="top 80%"
            >
              {{ t('Meet the visionaries driving Artrade’s evolution.') }}
            </AnimatedText>
          </div>
        </div>
        <div class="artrade--team-gallery">
          <div class="artrade--team-gallery-scrollable">
            <div class="d-flex flex-column gap-9 gap-md-10 artrade--team-item">
              <figure class="artrade--team-image">
                <Image
                  sizes="(max-width: 400px) 400pxpx, (max-width: 1440px) 1440px, 1920px"
                  srcSets="/images/team/lc@400w.webp 400w, /images/team/lc@1440w.webp 1440w, /images/team/lc.webp"
                  alt="Léo Caillard"
                />
              </figure>
              <Stack
                direction="column"
                :spacing="{
                  base: 0,
                  md: 3
                }"
              >
                <AnimatedText
                  as="p"
                  class="artrade--text-1 text-medium"
                  :duration="1"
                  trigger=".artrade--section.-team"
                  start="top 80%"
                >
                  Léo Caillard
                </AnimatedText>
                <AnimatedText
                  as="p"
                  class="artrade--text-1"
                  :duration="1"
                  trigger=".artrade--section.-team"
                  start="top 80%"
                >
                  {{ t('Artist Ambassador') }}
                </AnimatedText>
              </Stack>
            </div>
            <div class="d-flex flex-column gap-9 gap-md-10 artrade--team-item">
              <figure class="artrade--team-image">
                <Image
                  sizes="(max-width: 400px) 400pxpx, (max-width: 1440px) 1440px, 1920px"
                  srcSets="/images/team/pw@400w.webp 400w, /images/team/pw@1440w.webp 1440w, /images/team/pw.webp"
                  alt="Paul Weibel"
                />
              </figure>
              <Stack
                direction="column"
                :spacing="{
                  base: 0,
                  md: 3
                }"
              >
                <AnimatedText
                  as="p"
                  class="artrade--text-1 text-medium"
                  :duration="1"
                  trigger=".artrade--section.-team"
                  start="top 80%"
                >
                  Paul Weibel
                </AnimatedText>
                <AnimatedText
                  as="p"
                  class="artrade--text-1"
                  :duration="1"
                  trigger=".artrade--section.-team"
                  start="top 80%"
                >
                  {{ t('Founder & CEO') }}
                </AnimatedText>
              </Stack>
            </div>
            <div class="d-flex flex-column gap-9 gap-md-10 artrade--team-item">
              <figure class="artrade--team-image">
                <Image
                  sizes="(max-width: 400px) 400pxpx, (max-width: 1440px) 1440px, 1920px"
                  srcSets="/images/team/fb@400w.webp 400w, /images/team/fb@1440w.webp 1440w, /images/team/fb.webp"
                  alt="Frédéric Bry"
                />
              </figure>
              <Stack
                direction="column"
                :spacing="{
                  base: 0,
                  md: 3
                }"
              >
                <AnimatedText
                  as="p"
                  class="artrade--text-1 text-medium"
                  :duration="1"
                  trigger=".artrade--section.-team"
                  start="top 80%"
                >
                  Frédéric Bry
                </AnimatedText>
                <AnimatedText
                  as="p"
                  class="artrade--text-1"
                  :duration="1"
                  trigger=".artrade--section.-team"
                  start="top 80%"
                >
                  {{ t('Chief Technical Officer') }}
                </AnimatedText>
              </Stack>
            </div>
            <div class="d-flex flex-column gap-9 gap-md-10 artrade--team-item">
              <figure class="artrade--team-image">
                <Image
                  sizes="(max-width: 400px) 400pxpx, (max-width: 1440px) 1440px, 1920px"
                  srcSets="/images/team/dk@400w.webp 400w, /images/team/dk@1440w.webp 1440w, /images/team/dk.webp"
                  alt="David Kubler"
                />
              </figure>
              <Stack
                direction="column"
                :spacing="{
                  base: 0,
                  md: 3
                }"
              >
                <AnimatedText
                  as="p"
                  class="artrade--text-1 text-medium"
                  :duration="1"
                  trigger=".artrade--section.-team"
                  start="top 80%"
                >
                  David Kubler
                </AnimatedText>
                <AnimatedText
                  as="p"
                  class="artrade--text-1"
                  :duration="1"
                  trigger=".artrade--section.-team"
                  start="top 80%"
                >
                  {{ t('Head of Design') }}
                </AnimatedText>
              </Stack>
            </div>
            <div class="d-flex flex-column gap-9 gap-md-10 artrade--team-item">
              <figure class="artrade--team-image">
                <Image
                  sizes="(max-width: 400px) 400pxpx, (max-width: 1440px) 1440px, 1920px"
                  srcSets="/images/team/mg@400w.webp 400w, /images/team/mg@1440w.webp 1440w, /images/team/mg.webp"
                  alt="David Kubler"
                />
              </figure>
              <Stack
                direction="column"
                :spacing="{
                  base: 0,
                  md: 3
                }"
              >
                <AnimatedText
                  as="p"
                  class="artrade--text-1 text-medium"
                  :duration="1"
                  trigger=".artrade--section.-team"
                  start="top 80%"
                >
                  Martin Girard
                </AnimatedText>
                <AnimatedText
                  as="p"
                  class="artrade--text-1"
                  :duration="1"
                  trigger=".artrade--section.-team"
                  start="top 80%"
                >
                  {{ t('Community & Marketing Director') }}
                </AnimatedText>
              </Stack>
            </div>
          </div>
        </div>
      </section>
      <section class="artrade--section -press">
        <div class="row">
          <div class="col-md-5 mb-14 mb-md-0">
            <AnimatedText
              as="h2"
              class="artrade--display-2 text-medium"
              :duration="1"
              trigger=".artrade--section.-press"
              start="top 80%"
            >
              {{ t('They speak') }}
            </AnimatedText>
            <AnimatedText
              as="h2"
              class="artrade--display-2 text-medium"
              :duration="1"
              trigger=".artrade--section.-press"
              start="top 80%"
            >
              {{ t(' about us.') }}
            </AnimatedText>
          </div>
          <div class="offset-md-1 col-md-6">
            <Stack direction="column" :spacing="13">
              <div class="artrade--press-slideshow" ref="container">
                <div
                  class="artrade--press-slideshow-item"
                  v-for="post in posts"
                  :key="post.company.name"
                >
                  <Stack direction="column" :spacing="13">
                    <AnimatedText
                      as="p"
                      class="artrade--text-3"
                      :duration="1"
                      trigger=".artrade--section.-press"
                      start="top 80%"
                    >
                      {{ t(post.content) }}
                    </AnimatedText>
                    <Stack :spacing="7" alignItems="center">
                      <Avatar :src="post.company.logo" size="md" />
                      <AnimatedText
                        as="p"
                        class="artrade--text-1"
                        :duration="1"
                        trigger=".artrade--section.-press"
                        start="top 80%"
                      >
                        {{ post.company.name }}
                      </AnimatedText>
                    </Stack>
                  </Stack>
                </div>
              </div>
              <Stack justifyContent="space-between">
                <AnimatedLink
                  trigger=".artrade--section.-press"
                  :delay="0.3"
                  :href="posts[currentPostIndex].url"
                  target="_blank"
                  >{{ t('Read more') }}</AnimatedLink
                >
                <Stack :spacing="10">
                  <IconButton icon="arrowLeft" variant="outline" @click="slider?.prev" />
                  <IconButton icon="arrowRight" variant="outline" @click="slider?.next" />
                </Stack>
              </Stack>
            </Stack>
          </div>
        </div>
      </section>
      <Newsletter />
    </main>
    <!-- <AnimatedCursor v-if="!isTouchDevice" target=".artrade--team-gallery" /> -->
  </SmoothScroll>
</template>

<script setup lang="ts">
import Newsletter from '@/components/Newsletter.vue'
import SmoothScroll from '@/components/SmoothScroll.vue'
import Video from '@/components/Video.vue'
import AnimatedDivider from '@/components/animated/AnimatedDivider.vue'
import AnimatedLink from '@/components/animated/AnimatedLink.vue'
import AnimatedSolana from '@/components/animated/AnimatedSolana.vue'
import AnimatedTag from '@/components/animated/AnimatedTag.vue'
import AnimatedText from '@/components/animated/AnimatedText.vue'
import ExpandableVideo from '@/components/animated/ExpandableVideo.vue'
import IconButton from '@/components/design/IconButton.vue'
import Image from '@/components/design/Image.vue'
import Stack from '@/components/design/Stack.vue'
import { useApp } from '@/composables/useApp'
import { Avatar } from '@/theme'
import { formatCash, getATRTotalBurnt, isMobile } from '@/utils'
import { getATRTotalSupply } from '@/utils/solana/getATRTotalSupply'
import spotPriceAtr from '@/utils/solana/spotPriceAtr'
import axios from 'axios'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'
import { useKeenSlider } from 'keen-slider/vue'
import {
  computed,
  defineAsyncComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watchEffect
} from 'vue'
import { useI18n } from 'vue-i18n'

const GalleryGlobe = defineAsyncComponent(() => import('@/components/GalleryGlobe.vue'))
const AnimatedCoin = defineAsyncComponent(() => import('@/components/animated/AnimatedCoin.vue'))

const { t } = useI18n()
const isMobileDevice = ref(isMobile())
const containerAnimation = ref<gsap.core.Tween>()
const ambassadorAnimationContainer = ref<gsap.core.Tween>()
const coinTargetArea = ref<HTMLElement>()
const isMounted = ref(false)
const currentPostIndex = ref(0)
const { locale } = useI18n()
const app = useApp()
const tokenStats = reactive({
  marketcap: 0,
  price: 0,
  supply: 0,
  burnt: 0
})

const posts = ref([
  {
    content:
      '“Artrade redéfinit l’avenir de l’art en fusionnant habilement le tangible et le numérique, promettant une ère où les œuvres d’art traversent sans effort les frontières entre tradition et technologie blockchain.”',
    url: 'https://www.cointribune.com/artrade-transformer-chaque-oeuvre-dart-en-real-world-asset-rwa-sur-la-blockchain/',
    company: {
      name: 'Cointribune',
      logo: '/images/press/cointribune.jpg'
    }
  },
  {
    content:
      '“More than just a marketplace, Artrade is positioned as a social network dedicated to art enthusiasts. The platform encourages exchanges between artists, collectors and enthusiasts, thanks to private messaging and public profile functionalities.”',
    url: 'https://cointelegraph.com/press-releases/artrade-transforming-every-work-of-art-into-a-real-world-asset-rwa-on-the-blockchain',
    company: {
      name: 'Cointelegraph',
      logo: '/images/press/cointelegraph.jpg'
    }
  },
  {
    content:
      '“Des artistes majeurs, à l’instar de Léo Caillard, John Hamon ou encore Lionel Deluy ont d’ores et déjà franchi le pas afin de rejoindre la plateforme. Séduits par le concept novateur d’Artrade, ces derniers exposent maintenant leurs œuvres sous forme de NFT.” ',
    url: 'https://cryptonaute.fr/artrade-transformer-vos-oeuvres-nft/',
    company: {
      name: 'Cryptonaute',
      logo: '/images/press/cryptonaute.jpg'
    }
  },
  {
    content:
      '“Grâce à sa blockchain garante d’authenticité, Artrade comble un vide sur le marché de l’art, souvent opaque et sujet à la fraude. La plateforme apporte un niveau de confiance inédit aux transactions artistiques, assurant à chaque créateur la pleine propriété et le contrôle de ses œuvres tokenisées.”',
    url: 'https://fr.beincrypto.com/affaires/communique-de-presse/301740/artrade-transformer-oeuvre-art-real-world-asset-rwa-blockchain/',
    company: {
      name: 'BeinCrypto France',
      logo: '/images/press/beincrypto.jpg'
    }
  },
  {
    content:
      '“Artrade‘s real innovation, however, lies in its REAL (Reliable Electronic Artwork Ledger) protocol. This guarantees transaction security by relying on smart contracts deployed on the Solana blockchain. ”',
    url: 'https://coinpri.com/news/nft/artrade-real-world-asset/',
    company: {
      name: 'Coinpri',
      logo: '/images/press/coinpri.jpg'
    }
  },
  {
    content:
      '“Générer un NFT lié à une œuvre d’art permettra tout d’abord à notre photographe de pouvoir garantir son authenticité et de certifier à un acheteur qu’il est bien le seul et unique propriétaire de sa création.”',
    url: 'https://journalducoin.com/nft/artrade-tokenisation-service-beaux-arts/',
    company: {
      name: 'Journal du coin',
      logo: '/images/press/journalducoin.jpg'
    }
  }
])

const [container, slider] = useKeenSlider({
  loop: true,
  mode: 'snap',
  rtl: false,
  slides: { perView: 'auto', spacing: 28 },
  selector: '.artrade--press-slideshow-item',
  slideChanged(evt) {
    const { rel } = evt.track.details
    currentPostIndex.value = rel
  }
})

watchEffect(async () => {
  if (isMounted.value && slider.value) {
    await nextTick()
    slider.value.update()
  }
})

onMounted(async () => {
  isMounted.value = true

  app.navbar.minimal()

  if (!isMobileDevice.value) {
    createHorizontalFeatureEffect()
  }

  createBodyBackgroundColorEffect()

  if (!isMobileDevice.value) {
    createHorizontalAmbassadorEffect()
  }

  // Make team gallery scrollable on drag
  /* new ScrollBooster({
    viewport: document.querySelector('.artrade--team-gallery'),
    content: document.querySelector('.artrade--team-gallery-scrollable'),
    scrollMode: 'transform',
    direction: 'horizontal'
  }) */

  const circulatingSupply = await axios.get('https://jsbackend.artrade.app/circulating-supply')

  tokenStats.price = await spotPriceAtr.get()
  tokenStats.supply = await getATRTotalSupply()
  tokenStats.burnt = await getATRTotalBurnt()
  tokenStats.marketcap = circulatingSupply.data * tokenStats.price

  // The ambassador horizontal effect didn't working when I remove this...
  setTimeout(() => {
    ScrollTrigger.refresh(true)
    ScrollTrigger.update()
  }, 1000)
})

onUnmounted(() => {
  app.navbar.full()

  gsap.killTweensOf('#app')
  gsap.killTweensOf('.artrade--ambassador-item')
  gsap.killTweensOf('.artrade--feature-item')

  ScrollTrigger.getById('home-bg').kill()

  gsap.to('#app', {
    backgroundColor: '#ffffff',
    ease: 'expo'
  })
})

/**
 * Change background color when scroller enter token section
 */
async function createBodyBackgroundColorEffect() {
  ScrollTrigger.create({
    trigger: '.artrade--section.-token',
    start: 'top 70%',
    end: 'bottom bottom',
    scrub: true,
    id: 'home-bg',
    onToggle: (self) => {
      gsap.to('#app', {
        backgroundColor: self.isActive ? '#000000' : '#ffffff',
        ease: 'expo'
      })

      if (self.isActive) {
        Array.from(document.querySelectorAll('.text-black')).forEach((item) => {
          item.classList.add('text-white')
          item.classList.remove('text-black')
        })
      } else {
        Array.from(document.querySelectorAll('.text-white')).forEach((item) => {
          item.classList.add('text-black')
          item.classList.remove('text-white')
        })
      }
    }
  })
}

/**
 * Change to horizontal scroll when scroller enter ambassador section
 */
function createHorizontalAmbassadorEffect() {
  const items = Array.from(document.querySelectorAll('.artrade--ambassador-item'))
  const trigger = document.querySelector('.artrade--section.-ambassador') as HTMLElement

  ambassadorAnimationContainer.value = gsap.to(items, {
    x: -100 * (items.length - 1) + '%',
    force3D: true,
    ease: 'none',
    scrollTrigger: {
      start: 'top top',
      trigger,
      pin: true,
      scrub: 1,
      end: () => '+=' + trigger.offsetWidth,
      invalidateOnRefresh: true
    }
  })
}

/**
 * Change to horizontal scroll when scroller enter feature section
 */
function createHorizontalFeatureEffect() {
  const items = Array.from(document.querySelectorAll('.artrade--feature-item'))
  const trigger = document.querySelector('.artrade--section.-feature') as HTMLElement

  // Horizontal scroll for feature section
  containerAnimation.value = gsap.to(items, {
    xPercent: -100 * (items.length - 1),
    ease: 'none',
    scrollTrigger: {
      trigger,
      pin: true,
      start: 'top top',
      scrub: 1,
      end: () => '+=' + trigger.offsetWidth
    }
  })

  // Animation for images inside feature section
  gsap.set('.trail--image', {
    scale: 0.5,
    opacity: 0
  })

  gsap.to('.artrade--feature-item.-feature-solana .trail--image', {
    scale: 1,
    opacity: 1,
    ease: 'none',
    stagger: 0.5,
    scrollTrigger: {
      trigger: '.artrade--feature-item.-feature-solana .artrade--feature-grid',
      scrub: true,
      start: 'left 10%',
      end: 'right 50%',
      containerAnimation: containerAnimation.value
    }
  })

  gsap.to('.artrade--feature-item.-feature-real .trail--image', {
    scale: 1,
    opacity: 1,
    ease: 'none',
    stagger: 0.5,
    scrollTrigger: {
      trigger: '.artrade--feature-item.-feature-real .artrade--feature-grid',
      scrub: true,
      start: 'left 50%',
      end: 'right 50%',
      containerAnimation: containerAnimation.value
    }
  })
}

const whitepaperBaseUrl = computed(() => {
  const url = 'https://whitepaper.artrade.app'

  if (locale.value === 'en') {
    return url
  }

  return `${url}/${locale.value}`
})
</script>

<style scoped lang="scss">
%container {
  position: relative;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding-left: var(--artrade-space-9);
  padding-right: var(--artrade-space-9);

  @media screen and (min-width: 768px) {
    padding-left: var(--artrade-space-15);
    padding-right: var(--artrade-space-15);
  }
}

* {
  font-family: var(--artrade-fonts-heading);
  font-weight: var(--artrade-fontWeights-regular);
  color: var(--artrade-colors-black);
}

.scroll-pin {
  transform: translate3d(0, 0, 0);
}

.artrade--container {
  @extend %container;
}

.artrade--header {
  position: relative;
  height: 100vh;
  height: 100dvh;

  .artrade--header-inner {
    @extend %container;
    padding-bottom: var(--artrade-space-13);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    @media screen and (min-width: 768px) {
      padding-bottom: var(--artrade-space-16);
    }

    .artrade--header-content {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
}

main {
  overflow-x: hidden;

  .artrade--section {
    @extend %container;

    padding-top: 100px;
    padding-bottom: 100px;

    @media screen and (min-width: 768px) {
      padding-top: 200px;
      padding-bottom: 200px;
    }

    &.-intro {
      @media screen and (min-width: 768px) {
        padding-bottom: 0;
      }
    }

    &.-feature {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      gap: var(--artrade-space-20);

      @media screen and (min-width: 768px) {
        padding: 0;
        flex-direction: row;
        overflow-x: hidden;
        gap: 0;
      }

      @extend %container;

      .artrade--feature-item {
        flex: 0 0 100%;
        height: 100vh;

        will-change: transform;
        display: flex;
        flex-direction: column;
        position: relative;

        @media (min-width: 768px) {
          padding-top: 200px;
        }

        .artrade--solana-logo {
          @media (max-width: 768px) {
            margin-top: -1%;
          }
        }

        .artrade--feature-grid {
          flex: 1;
          position: relative;
          display: none;

          @media screen and (min-width: 768px) {
            display: flex;
          }

          img {
            position: relative;
            height: auto;
            align-self: flex-end;
            transform-origin: left bottom;
          }
        }

        &:nth-child(1) {
          padding-left: var(--artrade-space-9);

          @media screen and (min-width: 768px) {
            padding-left: var(--artrade-space-15);
          }
        }

        &:nth-child(2) {
          padding-bottom: 100px;

          .artrade--feature-grid {
            img {
              align-self: flex-start;
            }
          }
        }
      }
    }

    &.-token {
      padding-top: 100px;
      padding-bottom: 100px;

      @media screen and (min-width: 768px) {
        padding-top: 300px;
        padding-bottom: 200px;
      }

      .artrade--token-title {
        position: relative;
        z-index: 2;
      }

      .token-target-area {
        flex: 1;
      }

      .artrade--token-info {
        @media screen and (min-width: 768px) {
          padding-top: 200px;
        }

        @media screen and (min-width: 1440px) {
          padding-top: 400px;
        }
      }
    }

    &.-join {
      padding-bottom: 0px;

      @media screen and (min-width: 768px) {
        padding-bottom: 0px;
      }
    }

    &.-ambassador {
      display: flex;
      gap: var(--artrade-space-12);
      flex-direction: column;

      @extend %container;

      padding-top: 50px;
      padding-bottom: 100px;

      @media screen and (min-width: 768px) {
        padding-top: 100px;
        padding-bottom: 0px;
        height: 100vh;
        flex-direction: row;
      }

      .artrade--ambassador-item {
        flex: 0 0 100%;
        will-change: transform;
        display: flex;
        flex-direction: column;
        position: relative;
        gap: var(--artrade-space-11);

        @media screen and (min-width: 768px) {
          flex: 0 0 50%;
          padding-bottom: var(--artrade-space-16);
        }

        figure {
          position: relative;
          overflow: hidden;
          border-radius: var(--artrade-radii-2xl);
          background-color: var(--artrade-colors-grey-50);
          height: 320px;

          @media screen and (min-width: 768px) {
            flex: 1;
            height: auto;
          }
        }
      }
    }
  }
}

.artrade--timeline {
  .artrade--timeline-list {
    list-style-type: none;
    position: relative;

    .artrade--timeline-item {
      position: relative;
    }
  }

  .artrade--timeline-line-container {
    width: 2px;
    height: 100%;
    background-color: var(--artrade-colors-grey-100);

    .artrade--timeline-line-progress {
      transform-origin: top;
      width: 100%;
      height: 100%;
      background-color: var(--artrade-colors-black);
    }
  }
}

.artrade--team-gallery {
  @extend %container;
  padding: 0;

  .artrade--team-gallery-scrollable {
    display: flex;
    gap: var(--artrade-space-12);

    .artrade--team-item {
      flex: 0 0 90%;

      @media screen and (min-width: 768px) {
        flex: 0 0 480px;
      }

      figure {
        position: relative;
        overflow: hidden;
        border-radius: var(--artrade-radii-xl);
        height: 480px;

        @media screen and (min-width: 768px) {
          height: 600px;
        }

        picture {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.artrade--press-slideshow {
  align-content: flex-start;
  display: flex;
  position: relative;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  overflow: hidden;

  .artrade--press-slideshow-item {
    flex: 0 0 100%;
  }
}

.artrade--display-1 {
  font-size: clamp(44px, 8vw, 154px);
  font-weight: var(--artrade-fontWeights-black);
  text-transform: uppercase;
  line-height: 90%;
}

.artrade--display-2 {
  font-size: clamp(34px, 3vw, 54px);
  font-weight: var(--artrade-fontWeights-regular);
  line-height: 110%;
}

.artrade--display-3 {
  font-size: clamp(32px, 8vw, 42px);
  font-weight: var(--artrade-fontWeights-medium);
  line-height: 130%;
}

.artrade--display-4 {
  font-size: clamp(20px, 2vw, 24px);
  line-height: 130%;
}

.artrade--display-5 {
  font-size: clamp(18px, 2vw, 24px);
  line-height: 110%;
}

.artrade--text-3 {
  font-size: clamp(24px, 2vw, 32px);
  line-height: 110%;
}

.artrade--text-2 {
  font-size: clamp(20px, 2vw, 26px);
  line-height: 110%;
}

.artrade--text-1 {
  font-size: clamp(20px, 1vw, 24px);
  line-height: 110%;
}

.text-medium {
  font-weight: var(--artrade-fontWeights-medium);
}

.text-semibold {
  font-weight: var(--artrade-fontWeights-semibold);
}

.font-serif {
  font-family: var(--artrade-fonts-serif);
}

.leading-lg {
  line-height: 120%;
}

.leading-md {
  line-height: 100%;
}

.text-grey-500 {
  color: var(--artrade-colors-grey-500);
}
</style>
