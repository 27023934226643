import { Material } from '@/models'
import backend from '@/utils/backend'

async function getAll(): Promise<Material[]> {
  return await backend.get('material')
}
async function getAllUsed(): Promise<Material[]> {
  return await backend.get('used-material')
}

export default {
  getAll,
  getAllUsed
}
