<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
      fill="#1A1A1A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.2593 12.963C29.2593 14.5994 27.9328 15.9259 26.2963 15.9259C24.6599 15.9259 23.3334 14.5994 23.3334 12.963C23.3334 11.3266 24.6599 10 26.2963 10C27.9328 10 29.2593 11.3266 29.2593 12.963ZM10.7407 11.8724C10.7407 10.8383 11.579 10 12.6131 10H17.7982H19.9794C21.0135 10 21.8518 10.8383 21.8518 11.8724V14.0536L21.8518 14.0694V28.1276C21.8518 29.1617 21.0135 30 19.9794 30H17.7982C16.7641 30 15.9258 29.1617 15.9258 28.1276V15.9259H12.6131C11.579 15.9259 10.7407 15.0877 10.7407 14.0536V11.8724Z"
      fill="white"
    />
  </svg>
</template>
