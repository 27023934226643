export enum RouteName {
  Home = 'home',
  Marketplace = 'marketplace',
  Feed = 'feed',
  FeedArtwork = 'feed.artworks',
  FeedCollection = 'feed.collection',
  Category = 'category',
  CategoryArtwork = 'category.artworks',
  CategoryCollection = 'category.collections',
  Profile = 'profile',
  Settings = 'settings',
  ProfileSettings = 'profile.settings',
  NotificationSettings = 'notification.settings',
  Mint = 'mint',
  Nft = 'nft',
  Collection = 'collection',
  Activity = 'activity',
  ActivityOffer = 'activity.offers',
  ActivityAuction = 'activity.auctions',
  ActivityReal = 'activity.reals',
  ActivityBookmark = 'activity.bookmarks',
  Message = 'messages',
  MessageDetail = 'message.detail',
  PageNotFound = '404',
  Create = 'create',
  CreateWelcome = 'create.welcome',
  CreateUpload = 'create.upload',
  CreateArtwork = 'create.artwork',
  CreateRoyalties = 'create.royalties',
  CreateSale = 'create.sale',
  CreateCollection = 'create.collection',
  CreateSummary = 'create.summary',
  Artist = 'artist',
  ArtistThankYou = 'artistThankYou',
  Orders = 'orders',
  Staking = 'staking',
  EditArtwork = 'edit-artwork',
  Fragment = 'fragment',
  FragmentInvest = 'fragment.invest',
  FragmentTrading = 'fragment.trading',
  FragmentPortfolio = 'fragment.portfolio',
  FragmentArtwork = 'fragment.artwork',
  FragmentArtworkAbout = 'fragment.artwork.about',
  FragmentArtworkTokenomic = 'fragment.artwork.tokenomic',
  FragmentArtworkInvestmentThesis = 'fragment.artwork.investmentThesis'
}
