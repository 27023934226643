<template>
  <component class="artrade-icon" :is="Iconoir[name]" :width="size" :height="size"></component>
</template>

<script setup lang="ts">
import { Iconoir } from '@/components/icons'
import type { IconNames } from '@/components/types'

import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    name: IconNames
    size?: number
  }>(),
  {
    size: 20
  }
)

const size = computed(() => `${props.size}px`)
</script>

<style scoped lang="scss">
.artrade-icon {
  width: v-bind('size');
  height: v-bind('size');
  flex: 0 0 v-bind('size');
}
</style>
