<template>
  <div ref="element" class="artrade--animated-solana">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="88" height="88" rx="44" fill="#0C192B" />
      <g clip-path="url(#clip0_4078_75299)">
        <path
          d="M28.727 53.3234C28.9997 53.0507 29.3746 52.8916 29.7723 52.8916L65.8355 52.8916C66.4945 52.8916 66.824 53.6869 66.3582 54.1528L59.2342 61.2768C58.9615 61.5495 58.5865 61.7086 58.1889 61.7086H22.1256C21.4666 61.7086 21.1371 60.9132 21.6029 60.4474L28.727 53.3234Z"
          fill="url(#paint0_linear_4078_75299)"
        />
        <path
          d="M28.7445 26.7238C29.0286 26.4511 29.4035 26.292 29.7899 26.292L65.8531 26.292C66.5121 26.292 66.8416 27.0873 66.3758 27.5532L59.2517 34.6772C58.9791 34.9499 58.6041 35.109 58.2064 35.109H22.1432C21.4842 35.109 21.1547 34.3136 21.6205 33.8478L28.7445 26.7238Z"
          fill="url(#paint1_linear_4078_75299)"
        />
        <path
          d="M59.2342 39.9366C58.9615 39.664 58.5865 39.5049 58.1889 39.5049H22.1256C21.4666 39.5049 21.1371 40.3002 21.6029 40.7661L28.727 47.8901C28.9997 48.1628 29.3746 48.3219 29.7723 48.3219H65.8355C66.4945 48.3219 66.824 47.5265 66.3582 47.0607L59.2342 39.9366Z"
          fill="url(#paint2_linear_4078_75299)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4078_75299"
          x1="62.3904"
          y1="22.0372"
          x2="37.4318"
          y2="69.8431"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00FFA3" />
          <stop offset="1" stop-color="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4078_75299"
          x1="51.4947"
          y1="16.3386"
          x2="26.5361"
          y2="64.1445"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00FFA3" />
          <stop offset="1" stop-color="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4078_75299"
          x1="56.8991"
          y1="19.1681"
          x2="31.9404"
          y2="66.974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00FFA3" />
          <stop offset="1" stop-color="#DC1FFF" />
        </linearGradient>
        <clipPath id="clip0_4078_75299">
          <rect
            width="45.187"
            height="35.4156"
            fill="white"
            transform="translate(21.4062 26.292)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

const props = defineProps<{
  trigger: gsap.DOMTarget
}>()

const element = ref<HTMLElement>(null)

onMounted(() => {
  gsap.set(element.value, {
    scale: 0.5,
    opacity: 0
  })

  gsap.to(element.value, {
    scale: 1,
    opacity: 1,
    duration: 0.6,
    delay: 0.2,
    ease: 'expo',
    scrollTrigger: {
      trigger: props.trigger,
      start: 'center 75%',
      toggleActions: 'play none none reverse'
    }
  })

  gsap.to(element.value, {
    rotation: 360 * 5,
    duration: 1,
    ease: 'none',
    scrollTrigger: {
      trigger: 'html',
      scrub: 0.2,
      start: 'top top',
      end: '+=10000'
    }
  })
})
</script>

<style scoped lang="scss">
.artrade--animated-solana {
  width: 80px;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  will-change: transform;

  @media (min-width: 768px) {
    width: 90px;
  }

  @media (min-width: 1920px) {
    width: 128px;
  }
}
</style>
