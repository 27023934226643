<template>
  <BoxButton>
    <Stack :spacing="7" alignItems="center">
      <template v-if="name === WalletName.Phantom">
        <Phantom />
      </template>
      <template v-if="name === WalletName.Solflare"><Solflare /></template>
      <template v-if="name === WalletName.Glow"><Glow /></template>
      <template v-if="name === WalletName.Slope"><Slope /></template>
      <template v-if="name === WalletName.Backpack"><Backpack /></template>
      <template v-if="name === WalletName.Coinbase"><Coinbase /></template>
      <template v-if="name === WalletName.Torus"><Torus /></template>
      <template v-if="name === WalletName.Trust"><Trust /></template>
      <template v-if="name === WalletName.WalletConnect"><WalletConnect /></template>

      <Stack :spacing="2" direction="column">
        <Text size="md">
          <template v-if="name === WalletName.Phantom">Phantom</template>
          <template v-if="name === WalletName.Solflare">Solflare</template>
          <template v-if="name === WalletName.Glow">Glow</template>
          <template v-if="name === WalletName.Slope">Slope</template>
          <template v-if="name === WalletName.Backpack">Backpack</template>
          <template v-if="name === WalletName.Coinbase">Coinbase</template>
          <template v-if="name === WalletName.Torus">Torus</template>
          <template v-if="name === WalletName.Trust">Trust</template>
          <template v-if="name === WalletName.WalletConnect">Wallet Connect</template>
        </Text>
        <Text size="xs" color="grey.600">
          <template v-if="isDetected">{{ t('Detected') }}</template>
          <template v-else>{{ t('Get the extension') }}</template>
        </Text>
      </Stack>
    </Stack>
    <Icon name="arrowRight" :size="16" color="black" />
  </BoxButton>
</template>

<script setup lang="ts">
import {
  Backpack,
  BoxButton,
  Coinbase,
  Glow,
  Icon,
  Phantom,
  Slope,
  Solflare,
  Stack,
  Text,
  Torus,
  Trust,
  WalletConnect
} from '@/theme'
import { useI18n } from 'vue-i18n'

enum WalletName {
  Phantom = 'Phantom',
  Solflare = 'Solflare',
  Glow = 'Glow',
  Slope = 'Slope',
  Backpack = 'Backpack',
  Coinbase = 'Coinbase Wallet',
  Torus = 'Torus',
  Trust = 'Trust',
  WalletConnect = 'WalletConnect'
}

defineProps<{
  name: string
  isDetected: boolean
}>()

const { t } = useI18n()
</script>
