import { ResponsiveValue } from '@/components/types'

export function getResponsiveValue<T>(prop: ResponsiveValue<T>, prefix = '') {
  if (typeof prop === 'object') {
    const output = []

    for (const breakpoint in prop) {
      if (breakpoint === 'base') {
        output.push(`${prefix}-${prop[breakpoint]}`)
      } else {
        output.push(`${prefix}-${breakpoint}-${prop[breakpoint]}`)
      }
    }

    return output.join(' ')
  }

  return `${prefix}-${prop}`
}
