<template>
  <slot :open="open" :locale="locale"></slot>
  <Modal size="md" :isOpen="isLanguageModalOpen" @onClose="isLanguageModalOpen = false">
    <ModalContent>
      <ModalHeader>
        <Heading as="h4" size="md">{{ t('Choose a language') }}</Heading>
      </ModalHeader>
      <ModalBody :withFooter="false">
        <Stack class="checkbox-list" direction="column" :spacing="0">
          <template v-for="language in languages" :key="`locale-${locale}`">
            <Stack
              :class="['checkbox-list-item', { '-selected': language.value === locale }]"
              justifyContent="space-between"
              @click="changeLocale(language.value)"
              alignItems="center"
            >
              <Stack :spacing="6" alignItems="center">
                <div :class="['artrade-checkbox', { '-selected': language.value === locale }]">
                  <Icon v-if="language.value === locale" name="check" />
                </div>
                <Text size="md" fontWeight="semibold">{{ t(language.label) }}</Text>
              </Stack>
              <Text size="md" color="grey.600">{{ language.translate }}</Text>
            </Stack>
          </template>
        </Stack>
      </ModalBody>
    </ModalContent>
  </Modal>
</template>

<script setup lang="ts">
import Stack from '@/components/design/Stack.vue'
import { I18N_KEY } from '@/i18n'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Heading from './design/Heading.vue'
import Icon from './design/Icon.vue'
import Text from './design/Text.vue'
import { Modal, ModalBody, ModalContent, ModalHeader } from './modal'

const { t, locale } = useI18n()
const isLanguageModalOpen = ref(false)
const languages = ref([
  {
    label: t('en'),
    value: 'en',
    translate: 'Hello'
  },
  {
    label: t('fr'),
    value: 'fr',
    translate: 'Bonjour'
  }
])

function open() {
  isLanguageModalOpen.value = true
}

function changeLocale(language: string) {
  locale.value = language
  localStorage.setItem(I18N_KEY, language)
  window.location.reload()
}
</script>
