import { connectedUser } from '@/store/connectedUser'
import backend from '@/utils/backend'
import base58 from 'bs58'
import { Ref, inject, provide, ref, watch } from 'vue'
import { useCookies } from 'vue3-cookies'
import {
  autoConnect,
  disconnect,
  publicKey,
  signMessage,
  signTransaction,
  useWallet
} from '../wallet-connect-vue/init.ts'

export enum NavbarMode {
  Simple,
  Full,
  Minimal
}

export const ApplicationContextKey = Symbol()

export type ApplicationContext = {
  navbar: {
    simple(): void
    full(): void
    minimal(): void
    getState(): Ref<NavbarMode>
  }
  footer: {
    show(): void
    hide(): void
    getState(): Ref<boolean>
  }
  modals: {
    signup: {
      open(): void
      close(): void
      getState(): Ref<boolean>
    }
  }
}

import { user } from '@/store/user'
import { createSignMessageTransaction } from '@/utils/solana/transactions/createSignMessageTransaction.ts'
import { useSolana } from './useSolana.ts'

export function createAppContext() {
  const { wallets } = useWallet()
  const { cookies } = useCookies()
  const solana = useSolana()

  const footerDisplayState = ref(true)
  const navbarModeState = ref(NavbarMode.Full)

  const isSignupModalOpened = ref(false)

  watch(publicKey, async (current) => {
    // If public key available
    if (current) {
      try {
        // Retrieve user data
        await user.fetch(false)

        const publicKeyCookie = cookies.get('artrade-public-key')

        cookies.set('artrade-public-key', publicKey.value.toString(), 60 * 60 * 24 * 60)

        if (publicKeyCookie != publicKey.value.toString()) {
          cookies.remove('artrade-bearer')
        }

        const bearer = cookies.get('artrade-bearer')

        // we check the bearer
        let check = false
        if (bearer) {
          check = await backend.get(
            'user/' + publicKey.value.toString() + '/check-bearer/' + bearer.bearer
          )
        }

        if (user.isSignedUp && (!bearer || check === false)) {
          const message = 'Please sign the message below to authenticate yourself'
          const ledger = cookies.get('artrade-ledger-' + publicKey.value.toString())

          let backendBearer: string

          if (ledger == 'yes') {
            const transaction = await createSignMessageTransaction(message)
            const { blockhash } = await solana.connection.getLatestBlockhash()

            transaction.recentBlockhash = blockhash
            transaction.feePayer = publicKey.value

            let signedTransaction
            try {
              signedTransaction = await signTransaction(transaction)
            } catch (err) {
              console.error(err)

              disconnect()
              user.disconnect()
              return
            }

            backendBearer = await backend.postNode('check-signature-ledger', {
              transaction: Array.from(signedTransaction.serialize()),
              publicKey: publicKey.value.toString()
            })
          } else {
            const encodedMessage = new TextEncoder().encode(message)
            const uint8arraySignature = await signMessage(encodedMessage)

            // the user didn't sign
            if (!uint8arraySignature) {
              disconnect()
              user.disconnect()
              return
            }

            backendBearer = await backend.postNode('check-signature', {
              signature: base58.encode(uint8arraySignature),
              publicKey: publicKey.value.toString()
            })
          }

          if (backendBearer) {
            cookies.set('artrade-bearer', backendBearer, 60 * 60 * 24 * 60)
            await user.fetch(true)
          } else {
            disconnect()
            user.disconnect()
          }
        } else {
          await user.fetch(true)
        }
      } catch (err) {
        console.error(err)
      }

      // If user doesn't already signed, open signup modal
      if (!user.isSignedUp) {
        isSignupModalOpened.value = true
        connectedUser.setId(null)
      }
    } else {
      user.disconnect()
      connectedUser.setPublicKey(null)
      connectedUser.setId(null)
      cookies.remove('artrade-bearer')
    }
  })

  autoConnect(wallets)

  const ctx = {
    navbar: {
      simple() {
        navbarModeState.value = NavbarMode.Simple
      },
      full() {
        navbarModeState.value = NavbarMode.Full
      },
      minimal() {
        navbarModeState.value = NavbarMode.Minimal
      },
      getState() {
        return navbarModeState
      }
    },
    footer: {
      show() {
        footerDisplayState.value = true
      },
      hide() {
        footerDisplayState.value = false
      },
      getState() {
        return footerDisplayState
      }
    },
    modals: {
      signup: {
        open() {
          isSignupModalOpened.value = true
        },
        close() {
          isSignupModalOpened.value = false
        },
        getState() {
          return isSignupModalOpened
        }
      }
    }
  }

  provide<ApplicationContext>(ApplicationContextKey, ctx)

  return ctx
}

export function useApp() {
  return inject<ApplicationContext>(ApplicationContextKey)
}
