<template>
  <Stack class="artrade-newsletter" direction="column" :spacing="0">
    <Divider />
    <Container
      :direction="{
        base: 'column',
        md: 'row'
      }"
      :spacing="{
        base: 12,
        md: 0
      }"
      justifyContent="space-between"
      :alignItems="{
        md: 'center'
      }"
    >
      <Stack direction="column" :spacing="6" flex class="offset-md-1">
        <Heading as="h2" size="2xl" fontWeight="semibold">{{ t('Stay in the loop') }}</Heading>
      </Stack>
      <Stack
        flex
        :alignItems="{
          md: 'center'
        }"
        :justifyContent="{
          md: 'end'
        }"
        :direction="{
          base: 'column',
          md: 'row'
        }"
        :spacing="5"
      >
        <FormControl>
          <InputGroup>
            <Input v-model="email" :placeholder="t('Your email address')" />
            <InputSuffix>
              <Button
                class="d-none d-md-flex"
                :isLoading="mutation.isLoading.value"
                :isDisabled="isDisabled"
                variant="ghost"
                @click="subscribe"
                >{{ t('Subscribe') }}</Button
              >
            </InputSuffix>
          </InputGroup>
          <FormHint color="red.500" v-if="error">
            {{ error }}
          </FormHint>
        </FormControl>
        <Button
          class="d-md-none d-flex"
          :isLoading="mutation.isLoading.value"
          :isDisabled="isDisabled"
          variant="outline"
          size="lg"
          @click="subscribe"
          >{{ t('Subscribe') }}</Button
        >
      </Stack>
    </Container>
    <Divider />
  </Stack>
</template>

<script setup lang="ts">
import api from '@/api'
import { useMutation } from '@tanstack/vue-query'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Container from './Container.vue'
import Button from './design/Button.vue'
import Divider from './design/Divider.vue'
import Heading from './design/Heading.vue'
import Stack from './design/Stack.vue'
import { FormControl, FormHint, Input, InputGroup, InputSuffix } from './design/form'

const { t } = useI18n()
const email = ref('')
const error = ref<string>(null)
const mutation = useMutation({
  mutationFn: api.newsletter.subscribe,
  onSettled() {
    email.value = ''
  },
  onError() {
    error.value = t('An error occured during the subscription, please retry')
  }
})

function subscribe() {
  mutation.mutate(email.value)
}

const isDisabled = computed(() => email.value?.length === 0 || mutation.isLoading.value)
</script>

<style scoped lang="scss">
.artrade-newsletter {
  margin-bottom: var(--artrade-space-17);

  .container {
    padding-top: var(--artrade-space-15);
    padding-bottom: var(--artrade-space-15);

    @media screen and (min-width: 768px) {
      padding-top: var(--artrade-space-16);
      padding-bottom: var(--artrade-space-16);
    }

    :deep(.artrade-form-control) {
      @media screen and (min-width: 768px) {
        width: 80%;
      }
    }

    :deep(.form-control-input) {
      border-radius: var(--artrade-radii-full);
      height: 52px;
      padding-left: var(--artrade-space-10);
    }

    :deep(.artrade-input-suffix) {
      padding-right: var(--artrade-space-2);
    }

    :deep(.artrade-button) {
      transform: translate3d(0, 0, 0) !important;
    }
  }
}
</style>
