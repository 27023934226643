<script setup lang="ts">
import { user } from '@/store/user'

import WalletButton from '@/components/WalletButton.vue'
import Checkbox from '@/components/design/form/Checkbox.vue'
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
  useModal,
  useModalConfig
} from '@/theme'
import { connect, publicKey, useWallet } from '@/wallet-connect-vue/init'
import { WalletAdapter, WalletReadyState } from '@solana/wallet-adapter-base'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import SignupModal from './SignupModal.vue'
import WalletTutorialModal from './WalletTutorialModal.vue'

const { t } = useI18n()
const modal = useModal()
const modalConfig = useModalConfig()
const isLedger = ref<boolean>(false)
const { wallets } = useWallet()

// Select wallet, connect and get the public key
async function signin(wallet: WalletAdapter) {
  console.log(wallet)
  if (wallet.readyState === WalletReadyState.NotDetected) {
    window.open(wallet.url, '_blank')
    return
  }

  try {
    await connect(wallet, {
      isLedger: isLedger.value
    })

    isSignup()

    modalConfig.close()
  } catch (_) {}
}

async function isSignup() {
  if (publicKey.value) {
    await user.fetch(false)

    // If user doesn't already signed, open signup modal
    if (!user.isSignedUp) {
      modal.open(SignupModal)
    }
  }
}

function openWalletTutorial() {
  modal.open(WalletTutorialModal)
}
</script>

<template>
  <Modal>
    <ModalHeader>
      <Heading as="h4" size="md">{{ t('Sign in') }}</Heading>
    </ModalHeader>
    <ModalBody>
      <Text size="md" color="grey.700" lineHeight="md">{{
        t('First things first, connect a Solana wallet to sign in or create an account')
      }}</Text>
      <Checkbox v-model="isLedger" data-testid="signin-ledger-checkbox"
        ><Text size="md" color="grey.700" lineHeight="md">{{
          t("I'm using a ledger")
        }}</Text></Checkbox
      >
      <Stack direction="column" :spacing="7">
        <template v-for="(wallet, index) in wallets" :key="wallet.name">
          <WalletButton
            :name="wallet.name"
            :isDetected="wallet.readyState === WalletReadyState.Installed"
            @click="signin(wallet)"
            :data-testid="`signin-wallet-button-${index}`"
          />
        </template>
      </Stack>
    </ModalBody>
    <ModalFooter>
      <Button
        size="lg"
        variant="ghost"
        @click="openWalletTutorial"
        data-testid="signin-tutorial-button"
        >{{ t('What is a wallet?') }}</Button
      >
    </ModalFooter>
  </Modal>
</template>
