<template>
  <Teleport to="body">
    <div class="bottom-sheet-portal">
      <TransitionGroup name="sheet">
        <template v-for="sheet in store.sheets.value" :key="`bottom-sheet-${sheet.index}`">
          <div v-if="sheet.isOpen" class="bottom-sheet-overlay" @click="close(sheet)"></div>
          <div v-if="sheet.isOpen" class="bottom-sheet-container" tabindex="-1">
            <BottomSheetContext :config="sheet">
              <Component :is="sheet.component" :id="`bottom-sheet-${sheet.index}`" />
            </BottomSheetContext>
          </div>
        </template>
      </TransitionGroup>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { useBottomSheetStore } from '../composables'
import type { BottomSheetConfig } from '../types'
import BottomSheetContext from './BottomSheetContext.vue'

const store = useBottomSheetStore()

function close(sheet: BottomSheetConfig) {
  if (!sheet.bottomSheetProps.closeable) return
  sheet.close()
}
</script>

<style lang="scss">
.bottom-sheet-portal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100dvh;
  pointer-events: none;
  display: flex;
  align-items: flex-end;
  z-index: 9998;

  .bottom-sheet-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--artrade-overlay);
    transition: opacity 0.2s ease;
    z-index: 1;
    backdrop-filter: blur(14px);
    -webkit-backdrop-filter: blur(14px);
    pointer-events: all;
  }

  .bottom-sheet-container {
    flex: 1;
    pointer-events: all;
  }
}

.sheet-enter-from,
.sheet-leave-to {
  opacity: 0;
}

.sheet-enter-from .bottom-sheet,
.sheet-leave-to .bottom-sheet {
  transform: translate3d(0, 100%, 0);
}
</style>
