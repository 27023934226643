<template>
  <div :class="['artrade-divider', { '-vertical': vertical }]"></div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { Colors } from '../types'

const props = withDefaults(
  defineProps<{
    vertical?: boolean
    color?: Colors
  }>(),
  {
    vertical: false,
    color: 'grey.100'
  }
)

const backgroundColor = computed(() => `var(--artrade-colors-${props.color.replace('.', '-')})`)
</script>

<style scoped lang="scss">
.artrade-divider {
  width: 100%;
  height: 1px;
  background-color: v-bind('backgroundColor');

  &.-vertical {
    width: 1px;
    height: auto;
    flex-shrink: 0;
    align-self: stretch;
  }
}
</style>
