<template>
  <div class="bottom-sheet-footer">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.bottom-sheet-footer {
  display: flex;
  flex-direction: column;
  gap: var(--artrade-space-8);
}
</style>
