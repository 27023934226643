<template>
  <Stack class="token-input">
    <Stack class="token-input-inner" justifyContent="space-between" :spacing="7" flex>
      <Stack class="token-input-left" direction="column" :spacing="4">
        <Text class="token-input-label" size="xs" color="grey.600" v-if="label">{{ label }}</Text>
        <button
          ref="trigger"
          @click="changeToken"
          class="token-input-selector"
          :disabled="disabled || hasOnlyOneToken"
        >
          <Stack :spacing="4" alignItems="center">
            <Token.SOL class="token" v-if="token === TokenSymbol.SOL" />
            <Token.ATR class="token" v-if="token === TokenSymbol.ATR" />
            <Token.USDC class="token" v-if="token === TokenSymbol.USDC" />
            <Token.WARH class="token" v-if="token === TokenSymbol.WARH" />
            <Text fontWeights="medium" size="md" lineHeight="md">{{ token }}</Text>
          </Stack>
          <template v-if="!hasOnlyOneToken">
            <Icon name="navArrowDown" color="black" v-if="!disabled" />
          </template>
        </button>

        <div class="dropdown-overlay" v-if="isOpen" @click="toggle"></div>

        <Transition name="dropdown">
          <div class="dropdown-wrapper" v-if="isOpen" ref="menu" :style="menuStyle">
            <div class="dropdown">
              <Stack class="dropdown-list" role="list" direction="column" :spacing="0">
                <Stack
                  :spacing="3"
                  class="autocomplete-option"
                  v-for="token in tokens"
                  alignItems="center"
                  @click="select(token)"
                  role="listbox"
                >
                  <Token.SOL class="token" v-if="token === TokenSymbol.SOL" />
                  <Token.ATR class="token" v-if="token === TokenSymbol.ATR" />
                  <Token.USDC class="token" v-if="token === TokenSymbol.USDC" />
                  <Token.WARH class="token" v-if="token === TokenSymbol.WARH" />
                  {{ token }}
                </Stack>
              </Stack>
            </div>
          </div>
        </Transition>

        <Text
          class="token-input-label"
          size="xs"
          color="grey.600"
          lineHeight="md"
          v-if="tokenLabel"
          >{{ tokenLabel }}</Text
        >
      </Stack>
      <Stack class="token-input-right" direction="column" :spacing="4" alignItems="end">
        <Stack :spacing="3" alignItems="center">
          <Text size="xs" color="grey.600" v-if="balance">Balance: {{ formattedBalance }}</Text>
          <button class="token-input-max-button" @click="setMax" v-if="max">
            <Text size="xs" fontWeight="semibold">MAX</Text>
          </button>
        </Stack>
        <input
          type="number"
          min="0"
          v-model.number="amount"
          @input="handleChange"
          :disabled="disabled"
          placeholder="0"
        />
        <Text size="xs" color="grey.600">≈ {{ formattedSpotAmount }}</Text>
      </Stack>
    </Stack>
  </Stack>
</template>

<script setup lang="ts">
import { useMenu } from '@/composables'
import { Icon, Stack, Text, Token } from '@/theme'
import { formatNumber } from '@/utils'
import { TokenSymbol } from '@/wallet-connect-vue'
import { useWallet } from '@/wallet-connect-vue/init'
import { computed, reactive, ref, watch, watchPostEffect } from 'vue'
import { TokenInputChangeEvent, TokenInputProps } from './types'
import { useTokenSpot } from './useTokenSpot'

const { trigger, menu, isOpen, menuStyle, toggle } = useMenu({
  menuWidth: 160,
  placement: 'bottom-start'
})

const props = withDefaults(defineProps<TokenInputProps>(), {
  amount: null,
  max: false,
  disabled: false,
  balance: true
})

const emits = defineEmits(['change'])

const spot = useTokenSpot()
const wallet = useWallet()
const token = ref<TokenSymbol>(props.tokens[0])
const amount = ref<number>(props.amount)
const balances = reactive({
  [TokenSymbol.ATR]: 0,
  [TokenSymbol.SOL]: 0,
  [TokenSymbol.USDC]: 0,
  [TokenSymbol.WARH]: 0
})

function changeToken() {
  if (hasOnlyOneToken.value) return
  if (props.disabled) return

  toggle()
}

function setMax() {
  amount.value = balances[token.value]
  handleChange()
}

function select(tokenId: TokenSymbol) {
  token.value = tokenId
  toggle()
  handleChange()
}

function handleChange() {
  emits('change', {
    token: token.value,
    amount: amount.value,
    spotAmount: spotAmount.value,
    isInsufficiant: balances[token.value] < amount.value
  } as TokenInputChangeEvent)
}

watch(
  () => props.amount,
  (current) => {
    amount.value = current
  }
)

const tokenLabel = computed(() => {
  switch (token.value) {
    case TokenSymbol.ATR:
      return 'Artrade token'
    case TokenSymbol.SOL:
      return 'SOL'
    case TokenSymbol.USDC:
      return 'USD coin'
    case TokenSymbol.USDT:
      return 'Tether USD'
    case TokenSymbol.JUP:
      return 'Jupiter'
    case TokenSymbol.WARH:
      return "Andy Warhol's token"
  }
})

const hasOnlyOneToken = computed(() => props.tokens?.length === 1)
const spotAmount = computed(() => spot.get(token.value))
const formattedBalance = computed(() => formatNumber(balances[token.value]))
const formattedSpotAmount = computed(() =>
  formatNumber(amount.value * spotAmount.value, {
    style: 'currency',
    currency: 'USD'
  })
)

watchPostEffect(async () => {
  if (props.balance) {
    // Get balance of current connected wallet of provided tokens
    for (const token of props.tokens) {
      balances[token] = await wallet.getBalance(token)
    }
  }

  emits('change', {
    token: token.value,
    amount: amount.value,
    spotAmount: spotAmount.value,
    isInsufficiant: balances[token.value] < amount.value
  } as TokenInputChangeEvent)
})
</script>

<style scoped lang="scss">
.token-input {
  padding: var(--artrade-space-9) 0;
  background-color: var(--artrade-colors-white);
  border: 1px solid var(--artrade-colors-grey-100);
  border-radius: var(--artrade-radii-lg);
  transition: border-color 0.2s ease;

  &:hover {
    border-color: var(--artrade-colors-grey-200);
  }

  .token {
    width: 24px;
    flex: 0 0 24px;
    height: 24px;
  }

  .token-input-inner {
    padding: 0 var(--artrade-space-9) 0 var(--artrade-space-5);

    .token-input-left {
      .token-input-label {
        padding-left: var(--artrade-space-5);
      }

      .token-input-selector {
        appearance: none;
        display: flex;
        direction: row;
        align-items: center;
        gap: var(--artrade-space-1);
        padding: 0 var(--artrade-space-4);
        border-radius: var(--artrade-radii-xs);
        transition: background-color 0.2s ease;
        border: none;
        background-color: var(--artrade-colors-white);
        height: 34px;

        &:hover {
          background-color: var(--artrade-colors-grey-100);
        }

        &:active {
          background-color: var(--artrade-colors-grey-50);
        }

        &:disabled {
          pointer-events: none;
        }
      }
    }

    .token-input-right {
      .token-input-max-button {
        appearance: none;
        display: flex;
        direction: row;
        align-items: center;
        padding: 0 var(--artrade-space-1);
        border-radius: 4px;
        transition: background-color 0.2s ease;
        border: none;
        background-color: var(--artrade-colors-grey-100);
        height: 18px;

        &:hover {
          background-color: var(--artrade-colors-grey-100);
        }

        &:active {
          background-color: var(--artrade-colors-grey-50);
        }
      }

      input {
        appearance: none;
        width: 100%;
        text-align: end;
        outline: 0;
        font-size: var(--artrade-fontSizes-xl);
        font-family: var(--artrade-fonts-mono);
        font-weight: var(--artrade-fontWeights-semibold);
        color: var(--artrade-colors-black);

        &:disabled {
          background-color: var(--artrade-colors-white);
        }
      }
    }
  }
}
</style>
