import { User } from '@/models'
import backend from '@/utils/backend'

async function getPopular(userId?: number): Promise<User[]> {
  return backend.get(`home/popular-artists${userId ? `/${userId}` : ''}`)
}

export default {
  getPopular
}
