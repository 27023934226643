import backend from '@/utils/backend'
import { TokenSymbol } from '@/wallet-connect-vue'
import { useQuery } from '@tanstack/vue-query'
import axios from 'axios'
import { reactive } from 'vue'

export function useTokenSpot() {
  const spots = reactive({
    [TokenSymbol.SOL]: 0,
    [TokenSymbol.USDC]: 1,
    [TokenSymbol.ATR]: 0,
    [TokenSymbol.WARH]: 0
  })

  useQuery({
    queryKey: [TokenSymbol.SOL],
    async queryFn() {
      const response = await axios.get(
        'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd'
      )

      return response.data.solana.usd
    },
    select(res) {
      spots[TokenSymbol.SOL] = res
    }
  })

  useQuery({
    queryKey: [TokenSymbol.ATR],
    async queryFn() {
      const response = await backend.get('spot-price/get')

      return response.price
    },
    select(res) {
      spots[TokenSymbol.ATR] = res
    }
  })

  useQuery({
    queryKey: [TokenSymbol.WARH],
    async queryFn() {
      return 1
    },
    select(res) {
      spots[TokenSymbol.WARH] = res
    }
  })

  function get(token: TokenSymbol): number {
    if (!spots[token]) {
      return 0
    }

    return spots[token]
  }

  return {
    get
  }
}
