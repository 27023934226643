<template>
  <div :class="classnames"></div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { DividerProps } from '../types'
import { cx, getClassnameFromToken } from '../utils'

const props = withDefaults(defineProps<DividerProps>(), {
  vertical: false,
  color: 'grey.100'
})

const classnames = computed(() =>
  cx('artrade-divider', getClassnameFromToken(props.color, 'bg'), { '-vertical': props.vertical })
)
</script>

<style scoped lang="scss">
.artrade-divider {
  width: 100%;
  height: 1px;

  &.-vertical {
    width: 1px;
    height: auto;
    flex-shrink: 0;
    align-self: stretch;
  }
}
</style>
