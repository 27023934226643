import { Component } from 'vue'
import { ComponentProps } from '../../../types'
import { AlertModal, ConfirmModal, LoadingModal } from '../components/models'
import type { ModalAlertProps, ModalConfirmProps, ModalLoadingProps, ModalProps } from '../types'
import { useModalStore } from './useModalStore'

export function useModal() {
  const store = useModalStore()

  function open<C extends Component, P extends ComponentProps>(
    component: C,
    props?: P,
    options?: ModalProps
  ) {
    return store.open(component, props, options)
  }

  function alert(props: ModalAlertProps, options?: ModalProps) {
    return store.open(AlertModal, props, {
      ...options,
      closeable: false
    })
  }

  function confirm(props: ModalConfirmProps, options?: ModalProps) {
    return store.open(
      ConfirmModal,
      {
        confirmLabel: 'OK',
        cancelLabel: 'Cancel',
        ...props
      },
      options
    )
  }

  function loading(props: ModalLoadingProps, options?: ModalProps) {
    return store.open(LoadingModal, props, {
      ...options,
      closeable: false
    })
  }

  return {
    alert,
    confirm,
    loading,
    open,
    closeAll: store.closeAll
  }
}
