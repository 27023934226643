<template>
  <component :is="as" :class="classnames">
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { HeadingProps } from '../types'
import { cx, getClassnameFromToken, getResponsiveValue } from '../utils'

const props = withDefaults(defineProps<HeadingProps>(), {
  size: 'md',
  as: 'h2',
  color: 'black',
  fontWeight: 'semibold',
  align: 'left',
  uppercase: false,
  lineHeight: 'xs',
  fontFamily: 'heading',
  italic: false
})
const classnames = computed(() =>
  cx(
    'artrade-heading',
    getClassnameFromToken(props.color),
    `-line-height-${props.lineHeight}`,
    `-${props.size}`,
    `-font-${props.fontFamily}`,
    `-font-weight-${props.fontWeight}`,
    `-align-${props.align}`,
    {
      '-italic': props.italic,
      '-uppercase': props.uppercase,
      [getResponsiveValue(props.size, `heading`)]: props.size !== null
    }
  )
)
</script>

<style scoped lang="scss">
$breakpoints: (
  base: 0px,
  sm: 320px,
  md: 768px,
  lg: 960px,
  xl: 1200px,
  \2xl: 1536px
);

.artrade-heading {
  letter-spacing: -0.7px;

  &.-uppercase {
    text-transform: uppercase;
  }

  &.-italic {
    font-style: italic;
  }

  &.-line-height-xs {
    line-height: var(--artrade-lineHeights-xs);
  }

  &.-line-height-sm {
    line-height: var(--artrade-lineHeights-sm);
  }

  &.-line-height-md {
    line-height: var(--artrade-lineHeights-md);
  }

  &.-line-height-lg {
    line-height: var(--artrade-lineHeights-lg);
  }

  &.-line-height-xl {
    line-height: var(--artrade-lineHeights-xl);
  }

  &.-font-body {
    font-family: var(--artrade-fonts-body);
  }

  &.-font-mono {
    font-family: var(--artrade-fonts-mono);
  }

  &.-font-serif {
    font-family: var(--artrade-fonts-serif);
  }

  &.-align-left {
    text-align: left;
  }

  &.-align-center {
    text-align: center;
  }

  &.-align-right {
    text-align: right;
  }

  &.-font-weight-hairline {
    font-weight: var(--artrade-fontWeights-hairline);
  }

  &.-font-weight-thin {
    font-weight: var(--artrade-fontWeights-thin);
  }

  &.-font-weight-light {
    font-weight: var(--artrade-fontWeights-light);
  }

  &.-font-weight-regular {
    font-weight: var(--artrade-fontWeights-normal);
  }

  &.-font-weight-medium {
    font-weight: var(--artrade-fontWeights-medium);
  }

  &.-font-weight-semibold {
    font-weight: var(--artrade-fontWeights-semibold);
  }

  &.-font-weight-bold {
    font-weight: var(--artrade-fontWeights-bold);
  }

  &.-font-weight-extrabold {
    font-weight: var(--artrade-fontWeights-extrabold);
  }

  &.-font-weight-black {
    font-weight: var(--artrade-fontWeights-black);
  }

  &.-line-height-xs {
    line-height: var(--artrade-lineHeights-xs);
  }

  &.-line-height-sm {
    line-height: var(--artrade-lineHeights-sm);
  }

  &.-line-height-md {
    line-height: var(--artrade-lineHeights-md);
  }

  &.-line-height-lg {
    line-height: var(--artrade-lineHeights-lg);
  }

  &.-line-height-xl {
    line-height: var(--artrade-lineHeights-xl);
  }

  @each $breakpoint, $value in $breakpoints {
    @if $breakpoint == base {
      &.heading-3xl {
        font-size: var(--artrade-fontSizes-6xl);
      }

      &.heading-2xl {
        font-size: var(--artrade-fontSizes-5xl);
      }

      &.heading-xl {
        font-size: var(--artrade-fontSizes-4xl);
      }

      &.heading-lg {
        font-size: var(--artrade-fontSizes-3xl);
      }

      &.heading-md {
        font-size: var(--artrade-fontSizes-2xl);
      }

      &.heading-sm {
        font-size: var(--artrade-fontSizes-xl);
      }

      &.heading-xs {
        font-size: var(--artrade-fontSizes-lg);
      }
    } @else {
      @media screen and (min-width: $value) {
        &.heading#{$breakpoint}-3xl {
          font-size: var(--artrade-fontSizes-6xl);
        }

        &.heading-#{$breakpoint}-2xl {
          font-size: var(--artrade-fontSizes-5xl);
        }

        &.heading-#{$breakpoint}-xl {
          font-size: var(--artrade-fontSizes-4xl);
        }

        &.heading-#{$breakpoint}-lg {
          font-size: var(--artrade-fontSizes-3xl);
        }

        &.heading-#{$breakpoint}-md {
          font-size: var(--artrade-fontSizes-2xl);
        }

        &.heading-#{$breakpoint}-sm {
          font-size: var(--artrade-fontSizes-xl);
        }
      }
    }
  }
}
</style>
