import { reactive } from 'vue'

export const connectedUser = reactive({
  connected: false,
  key: null,
  id: null,
  slug: null,
  setPublicKey(publicKey) {
    this.key = publicKey
  },
  setId(id) {
    this.id = id
  }
})
