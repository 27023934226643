import { PublicKey } from '@solana/web3.js'
import { connection } from './initConnection'
import { getAtrTokenAccount } from './getAtrTokenAccount.ts'

export async function getAtrBalance(walletAddress: string) {
  const associatedTokenAccount = await getAtrTokenAccount(walletAddress.toString())

  try {
    const balance = await connection.getTokenAccountBalance(new PublicKey(associatedTokenAccount))
    return balance.value.uiAmount
  } catch (_) {
    return 0
  }
}
