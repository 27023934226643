import { User } from '@/models'
import backend from '@/utils/backend'

async function follow(id: number, userId: number) {
  return backend.get(`user/${id}/follow/${userId}`)
}

async function updateCollectAddress(id: number, payload: Partial<User>) {
  return backend.post(`user/${id}/update-collect-address`, payload)
}

async function getBySlug(slug: string, userId: number) {
  return backend.get(`user/slug/${slug}/${userId}`)
}

export default {
  follow,
  updateCollectAddress,
  getBySlug
}
