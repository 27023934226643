<template>
  <Teleport to="body">
    <div class="artrade-portal">
      <TransitionGroup name="modal" tag="div">
        <div v-if="isOpen" class="artrade-modal-overlay" @click="$emit('onClose')"></div>
        <div
          v-if="isOpen"
          :class="['artrade-modal-container', { '-full': props?.variant === 'full' }]"
          tabindex="-1"
        >
          <slot></slot>
        </div>
      </TransitionGroup>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { provide } from 'vue'
import { Sizes } from '../types'
import { ModalProvider, type ModalContext } from './types'

const props = withDefaults(
  defineProps<{
    isOpen: boolean
    size?: Sizes
    as?: keyof HTMLElementTagNameMap
    closeable?: boolean
    variant?: 'full' | 'default'
  }>(),
  {
    size: 'sm',
    as: 'section',
    closeable: true,
    variant: 'default'
  }
)

const emits = defineEmits(['onClose'])

function close() {
  emits('onClose')
}

provide<ModalContext>(ModalProvider, {
  close,
  size: props.size,
  closeable: props.closeable
})
</script>

<style lang="scss">
.artrade-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: var(--artrade-overlay);
  transition: opacity 0.2s ease;
  z-index: 1400;
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
}

.artrade-modal-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1400;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  overscroll-behavior-y: none;
  transition: all 0.2s ease;
  pointer-events: none;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &.-full {
    @media screen and (max-width: 768px) {
      padding: 0;
      align-items: start;
      justify-content: start;

      .artrade-modal-content {
        width: 100vw !important;
        height: 100vh !important;
        max-width: 100vw !important;
        max-height: 100vh !important;
        border-radius: 0;
        margin: 0;
      }
    }
  }
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .artrade-modal-content,
.modal-leave-to .artrade-modal-content {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
</style>
