import { time } from '@/store/time'
import { connection } from '@/utils/solana/initConnection.js'
import { Connection, PublicKey } from '@solana/web3.js'
import { ref } from 'vue'

export function useSolana() {
  const blockTime = ref<number>(0)

  /**
   * Validate solana address (on curve)
   */
  function validateAddress(address: string) {
    try {
      const publicKey = new PublicKey(address)
      return PublicKey.isOnCurve(publicKey.toBytes())
    } catch (err) {
      return false
    }
  }

  /**
   * Get solscan URL for a transaction by signature
   */
  function getSolscanTransactionUrl(signature: string) {
    return `https://solscan.io/tx/${signature}?cluster=custom&customUrl=${connection.rpcEndpoint})`
  }

  /**
   * Get solana blockchain time
   */
  function getTime(): Promise<number> {
    return time.get()
  }

  async function getBlockTime() {
    if (blockTime.value > 0) {
      blockTime.value++
      return blockTime.value
    }

    const slot = await (connection as Connection).getSlot()

    try {
      blockTime.value = await (connection as Connection).getBlockTime(slot)
      return blockTime.value
    } catch (e) {
      console.error('error while getting block time', e)

      return getBlockTime()
    }
  }

  return {
    connection: connection as Connection,
    validateAddress,
    getSolscanTransactionUrl,
    getTime,
    getBlockTime
  }
}
