<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
      fill="#1A1A1A"
    />
    <path
      d="M24.254 16.9654H18.9417L24.9856 23.0489L18.8633 29.1324L17.1953 30.754H22.71L29.7349 23.6936C30.0897 23.338 30.0881 22.7615 29.7315 22.4075L24.254 16.9654Z"
      fill="white"
    />
    <path
      d="M21.0996 23.0346H15.7468L10.2684 17.5925C10.1836 17.5083 10.1163 17.4083 10.0702 17.2981C10.0242 17.1878 10.0003 17.0696 10 16.9501C9.99969 16.8307 10.0229 16.7123 10.0684 16.6019C10.1139 16.4914 10.1807 16.391 10.2651 16.3064L17.29 9.246H22.8055L21.1384 10.8676L15.0152 16.9511L21.0608 23.0346"
      fill="white"
    />
  </svg>
</template>
