import { API_URL } from '@/constants'
import backend from '@/utils/backend'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { Axios } from 'axios'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useCustomModal } from './useCustomModal'

export type Currency = {
  id: number
  code: string
  name: string
  enable: boolean
  wallet_regex: string
  priority: number
  extra_id_exists: boolean
  extra_id_regex: string
  logo_url: string
  track: boolean
  cg_id: string
  is_maxlimit: boolean
  network: string
  smart_contract: string
  network_precision: boolean
}

export type SimpleCurrency = {
  symbol: string
  name: string
  id: string
  logo: string
}

export type PaymentPayload = {
  amount: number
  amountWithFees: number
  currency: string
  fragmentId: number
}

export type EstimatePayload = {
  currencyFrom: string
  currencyTo: string
  amount: number
}

const config = {
  url: import.meta.env.VITE_NOWPAYMENTS_URL,
  apiUrl: import.meta.env.VITE_NOWPAYMENTS_API_URL,
  apiKey: import.meta.env.VITE_NOWPAYMENTS_API,
  ipnKey: import.meta.env.VITE_NOWPAYMENTS_IPN
}

const api = new Axios({
  baseURL: config.apiUrl
})

/**
 * Adding some headers to request with NowPayments
 */
api.interceptors.request.use((requestConfig) => {
  requestConfig.headers.setContentType('application/json')
  requestConfig.headers.setAccept('application/json')
  requestConfig.headers.set('x-api-key', config.apiKey)

  return requestConfig
})

/**
 * Sometimes the response is a string not JSON format
 */
api.interceptors.response.use(
  (response) => {
    try {
      if (typeof response.data === 'string') {
        response.data = JSON.parse(response.data)
      }
    } catch {}

    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

async function getStatus() {
  const res = await api.get<{ message: string }>('/status')

  return res.data.message === 'OK'
}

async function getCurrencies() {
  const res = await api.get<{
    currencies: Array<{
      min_amount: number
      max_amount: number
      currency: string
    }>
  }>('/currencies?fixed_rate=true')

  return res.data.currencies
}

async function estimatePrice(currencyFrom: string, currencyTo: string, amount: number) {
  const res = await api.get(
    `/estimate?amount=${amount}&currency_from=${currencyFrom}&currency_to=${currencyTo}`
  )

  return res.data as {
    currency_from: string
    amount_from: number
    currency_to: string
    estimated_amount: number
  }
}

async function getMinimumPaymentAmount(currencyFrom: string, currencyTo: string) {
  const res = await api.get(
    `/min-amount?currency_from=${currencyFrom}&currency_to=${currencyTo}&fiat_equivalent=usd&is_fixed_rate=False&is_fee_paid_by_user=True`
  )

  return res.data as {
    currency_from: string
    currency_to: string
    min_amount: number
    fiat_equivalent: number
  }
}

async function createInvoice(currency: string, amount: number, orderId: string) {
  const res = await api.post(
    '/invoice',
    JSON.stringify({
      price_amount: amount,
      price_currency: 'usd',
      pay_currency: currency,
      ipn_callback_url: `${API_URL}nowpayments/callback`,
      order_id: orderId
    })
  )

  return res.data as {
    id: number
    order_id: string
    order_description: string
    price_amount: number
    price_currency: string
    pay_currency: string
    ipn_callback_url: string
    invoice_url: string
    success_url: string
    cancel_url: string
    created_at: string
    updated_at: string
  }
}

async function createOrder(payload: PaymentPayload) {
  const res = await backend.post('fragment/create-order', payload)

  return res?.orderId
}

function getTokenInfo(token: string) {
  switch (token) {
    case 'btc':
      return {
        name: 'Bitcoin',
        id: 'btc',
        logo: `${config.url}/images/coins/btc.svg`,
        symbol: 'BTC'
      }
    case 'eth':
      return {
        name: 'Ethereum',
        id: 'eth',
        logo: `${config.url}/images/coins/eth.svg`,
        symbol: 'ETH'
      }
    case 'sol':
      return {
        name: 'Solana',
        id: 'sol',
        logo: `${config.url}/images/coins/sol.svg`,
        symbol: 'SOL'
      }
    case 'bnbmainnet':
      return {
        name: 'Binance Coin',
        id: 'bnbmainnet',
        logo: `${config.url}/images/coins/bnb.svg`,
        symbol: 'BNB'
      }
    case 'usdterc20':
      return {
        name: 'Tether USD (Ethereum)',
        id: 'usdterc20',
        logo: `${config.url}/images/coins/usdterc20.svg`,
        symbol: 'USDT (ERC20)'
      }
    case 'usdtbsc':
      return {
        name: 'Tether USD (Binance Smart Chain)',
        id: 'usdtbsc',
        logo: `${config.url}/images/coins/usdtbsc.svg`,
        symbol: 'USDT (BSC)'
      }

    case 'usdcsol':
      return {
        name: 'USD Coin (Solana)',
        id: 'usdcsol',
        logo: `${config.url}/images/coins/usdcsol.svg`,
        symbol: 'USDC (SOL)'
      }
    case 'usdc':
      return {
        name: 'USD Coin (Ethereum)',
        id: 'usdc',
        logo: `${config.url}/images/coins/usdc.svg`,
        symbol: 'USDC (ERC20)'
      }
    case 'usdcbsc':
      return {
        name: 'USD Coin (Binance Smart Chain)',
        id: 'usdcbsc',
        logo: `${config.url}/images/coins/usdcbsc.svg`,
        symbol: 'USDC (BSC)'
      }
  }
}

export function useNowPayment() {
  const { t } = useI18n()
  const modal = useCustomModal()
  const availableCurrencies = ref<string[]>([
    'sol',
    'usdcsol',
    'eth',
    'btc',
    'bnbmainnet',
    'usdterc20',
    'usdtbsc',
    'usdcerc20',
    'usdcbsc'
  ])

  /**
   * Check if NowPayments API is working
   */
  const { data: status, isLoading: isStatusLoading } = useQuery({
    queryKey: ['now-payment-status'],
    queryFn: getStatus
  })

  /**
   * Get list of available currencies from NowPayments gateway
   */
  const { data: currencies, isLoading: isCurrenciesLoading } = useQuery({
    queryKey: ['now-payment-currencies'],
    queryFn: getCurrencies,
    select(res) {
      return res
        .filter(({ currency }) => availableCurrencies.value.includes(currency))
        .sort(
          (a, b) =>
            availableCurrencies.value.indexOf(a.currency) -
            availableCurrencies.value.indexOf(b.currency)
        )
        .map(({ currency }) => getTokenInfo(currency))
    }
  })

  /**
   * Create order and generate link to NowPayments Gateway
   */
  const pay = useMutation({
    async mutationFn(payload: PaymentPayload) {
      let currencyTo = 'usdc'
      let orderId: string

      try {
        const res = await createOrder(payload)
        orderId = res.orderId
      } catch (err) {
        throw null
      }

      try {
        await getMinimumPaymentAmount(payload.currency, currencyTo)
      } catch (err) {
        throw null
      }

      try {
        const invoice = await createInvoice(payload.currency, payload.amount, orderId)

        return invoice.invoice_url
      } catch (err) {
        throw null
      }
    },
    onSuccess(url) {
      window.open(url, '_blank')
    },
    onError() {
      modal.error(t('An error occurred while creating payment URL'))
    }
  })

  /**
   * Estimate price from NowPayments Gateway
   */
  const estimate = useMutation({
    mutationFn(payload: EstimatePayload) {
      return estimatePrice(payload.currencyFrom, payload.currencyTo, payload.amount)
    },
    onError() {
      modal.error(t('An error occurred while estimate payment price'))
    }
  })

  return {
    pay,
    estimate,
    status,
    isStatusLoading,
    currencies,
    isCurrenciesLoading
  }
}
