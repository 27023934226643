import { computePosition, flip, offset, shift } from '@floating-ui/dom'

let wrapper: HTMLElement
let style: HTMLStyleElement

const tooltips = new Map()

const tooltipStyle = `
  display: flex;
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--artrade-colors-black);
  color: var(--artrade-colors-white);
  height: 28px;
  border-radius: var(--artrade-radii-xs);
  font-size: var(--artrade-fontSizes-xs);
  line-height: var(--artrade-fontSizes-xs);
  padding-left: var(--artrade-space-3);
  padding-right: var(--artrade-space-3);
  align-items: center;
  justify-content: center;
  transform: translate3d(0, 0, 0) scale(0.85);
  opacity: 0;
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.4, 1.1), opacity 0.2s ease-out;
  pointerEvent: none;
  user-select: none;
  z-index: 9999;
`

/**
 * Update tooltip position
 */
function update(target: HTMLElement, tooltip: HTMLElement) {
  computePosition(target, tooltip, {
    placement: 'bottom',
    middleware: [offset(6), flip(), shift({ padding: 5 })]
  }).then(({ x, y }) => {
    Object.assign(tooltip.style, {
      left: `${x}px`,
      top: `${y}px`
    })
  })
}

/**
 * Create tooltip element
 */
function createTooltip(title: string) {
  const tooltip = document.createElement('div')
  tooltip.className = 'artrade-tooltip'
  tooltip.innerText = title

  if (!style) {
    style = document.createElement('style')
    style.id = 'artrade-tooltip'
    style.textContent = `
    .${tooltip.className} {
      ${tooltipStyle}
    }
  `

    document.head.appendChild(style)
  }

  return tooltip
}

function showTooltip(tooltip: HTMLElement) {
  Object.assign(tooltip.style, {
    transform: 'translate3d(0, 0, 0) scale(1)',
    opacity: 1
  })
}

function hideTooltip(tooltip: HTMLElement) {
  Object.assign(tooltip.style, {
    transform: 'translate3d(0, 0, 0) scale(0.85)',
    opacity: 0
  })
}

function createWrapper() {
  wrapper = document.createElement('div')
  wrapper.classList.add('artrade-tooltip-wrapper')

  Object.assign(wrapper.style, {
    pointerEvent: 'none'
  })

  document.body.appendChild(wrapper)

  return wrapper
}

export default {
  /**
   * On parent creation initialize tooltip element, event and set it to directive store
   */
  created(el: HTMLElement, binding: { value: string }) {
    const isMobile = window.matchMedia('(max-width: 768px)').matches

    if (isMobile) {
      return
    }

    if (!wrapper) {
      createWrapper()
    }

    const tooltip = createTooltip(binding.value)

    tooltips.set(el, tooltip)

    wrapper.appendChild(tooltip)

    el.addEventListener('mouseenter', () => {
      if (!binding.value) {
        return
      }

      showTooltip(tooltip)
      update(el, tooltip)
    })
    el.addEventListener('focus', () => () => {
      showTooltip(tooltip)
      update(el, tooltip)
    })

    el.addEventListener('mouseleave', () => hideTooltip(tooltip))
    el.addEventListener('blur', () => hideTooltip(tooltip))
  },

  updated(el, binding: { value: string }) {
    const tooltip = tooltips.get(el)

    if (!tooltip) return

    tooltip.textContent = binding.value
    update(el, tooltip)
  },

  /**
   * Clean tooltip element when parent will be removed
   */
  unmounted(el: HTMLElement) {
    const tooltip = tooltips.get(el)

    if (!tooltip) return
    tooltip.remove()
  }
}
