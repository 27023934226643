import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, getAssociatedTokenAddress } from '@solana/spl-token';

export async function getAssociatedTokenAccount(mint, owner) {
  
  const tokenAccount = await getAssociatedTokenAddress(
		mint,
		owner,  // to
		true,
		TOKEN_PROGRAM_ID,
		ASSOCIATED_TOKEN_PROGRAM_ID,
	);
  
  return tokenAccount;
}