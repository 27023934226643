<template>
  <BottomSheet v-slot="{ close }">
    <BottomSheetBody>
      <nav>
        <BottomSheetLink @click="openSignin">{{ t('Sign in') }}</BottomSheetLink>
        <BottomSheetLink as="router-link" @click="close" :to="{ name: RouteName.Fragment }">{{
          t('Fragments')
        }}</BottomSheetLink>
        <BottomSheetLink as="router-link" @click="close" :to="{ name: RouteName.Marketplace }">{{
          t('Marketplace')
        }}</BottomSheetLink>
        <BottomSheetLink as="router-link" @click="close" :to="{ name: RouteName.FeedArtwork }">{{
          t('Artworks')
        }}</BottomSheetLink>
        <BottomSheetLink as="router-link" @click="close" :to="{ name: RouteName.FeedCollection }">{{
          t('Collections')
        }}</BottomSheetLink>
        <BottomSheetLink
          @click="close"
          href="https://artradeapp.notion.site/Artrade-FAQ-5bb50865a81743b1be9498cf89dea3ee"
          target="_blank"
          >{{ t('FAQ') }}</BottomSheetLink
        >
      </nav>
    </BottomSheetBody>
    <BottomSheetFooter>
      <TokenAds />
      <Button variant="primary" as="router-link" @click="close" :to="{ name: RouteName.Artist }">{{
        t('Apply as an artist')
      }}</Button>
    </BottomSheetFooter>
  </BottomSheet>
</template>

<script setup lang="ts">
import { SigninModal } from '@/components/modals'
import TokenAds from '@/components/TokenAds.vue'
import { RouteName } from '@/router'
import {
  BottomSheet,
  BottomSheetBody,
  BottomSheetFooter,
  BottomSheetLink,
  Button,
  useBottomSheetConfig,
  useModal
} from '@/theme'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const modal = useModal()
const bottomSheetConfig = useBottomSheetConfig()

function openSignin() {
  bottomSheetConfig.close()
  modal.open(SigninModal)
}
</script>
