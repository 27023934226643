<template>
  <slot></slot>
</template>

<script setup lang="ts">
import { provide } from 'vue'
import { ModalContextKey } from '../../constants'
import type { ModalConfig } from '../../types/ModalConfig'
import type { ModalContextProps } from '../../types/ModalContextProps'

const props = defineProps<{
  config: ModalConfig
}>()

provide<ModalContextProps>(ModalContextKey, {
  config: props.config
})
</script>
