<template>
  <slot
    :display="counter?.isEnded?.value ? t('Ended') : counter?.display?.value"
    :counter="counter"
  ></slot>
</template>

<script setup lang="ts">
import { UseCountdownConfig, useCountdown } from '@/composables'
import { onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  end: number
  config?: UseCountdownConfig
}>()

const { t } = useI18n()
const counter = useCountdown(props?.config)

watch(
  () => props.end,
  (current) => {
    counter.run(current)
  }
)

onMounted(() => {
  counter.run(props?.end)
})

defineExpose({
  ...counter
})
</script>

<style scoped lang="scss"></style>
