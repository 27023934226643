<template>
  <Stack as="header" class="modal-header" justifyContent="space-between">
    <slot></slot>
    <IconButton
      v-if="config.modalProps.closeable"
      @click="config.close()"
      variant="outline"
      icon="cancel"
    />
  </Stack>
</template>

<script setup lang="ts">
import IconButton from '../../../IconButton.vue'
import Stack from '../../../Stack.vue'

import { useModalConfig } from '../../composables/useModalConfig'

const config = useModalConfig()
</script>
