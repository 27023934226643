<template>
  <Stack class="artrade-wallet-box" direction="column" :spacing="6">
    <template v-if="isLoading">
      <Stack alignItems="center" justifyContent="center">
        <Spinner />
      </Stack>
    </template>
    <template v-else>
      <Stack alignItems="center" :spacing="7">
        <template v-if="walletAdapter?.name === 'Phantom'">
          <Phantom />
        </template>
        <template v-if="walletAdapter?.name === 'Solflare'"><Solflare /></template>
        <template v-if="walletAdapter?.name === 'Glow'"><Glow /></template>
        <template v-if="walletAdapter?.name === 'Slope'"><Slope /></template>
        <template v-if="walletAdapter?.name === 'Backpack'"><Backpack /></template>
        <Stack direction="column" :spacing="3">
          <Text color="grey.600">{{ blockchain }}</Text>
          <Text fontFamily="mono">{{ formattedWalletAddress }}</Text>
        </Stack>
        <Spacer />
        <IconButton
          v-tooltip="t(addressCopied ? 'Copied!' : 'Copy address')"
          size="sm"
          variant="outline"
          :icon="addressCopied ? 'check' : 'copy'"
          @click="copyWalletAddress"
        />
      </Stack>
      <Divider />
      <Stack direction="column" :spacing="6">
        <Stack alignItems="center" :spacing="5">
          <SOLToken />
          <Text>{{ balance?.SOL?.current }} SOL</Text>
          <Spacer />
          <Text color="grey.600">≈ {{ balance?.SOL?.spot }}</Text>
        </Stack>
        <Stack alignItems="center" :spacing="5">
          <USDCToken />
          <Text>{{ balance?.USDC?.current }} USDC</Text>
          <Spacer />
          <Text color="grey.600">≈ {{ balance?.USDC?.spot }}</Text>
        </Stack>
        <Stack alignItems="center" :spacing="5">
          <ATRToken />
          <Text>{{ balance?.ATR?.current }} ATR</Text>
          <Spacer />
          <Text color="grey.600">≈ {{ balance?.ATR?.spot }}</Text>
        </Stack>
      </Stack>
    </template>
  </Stack>
</template>

<script setup lang="ts">
import { useSpot } from '@/composables'
import { QUERIES } from '@/constants'
import {
  ATRToken,
  Backpack,
  Divider,
  Glow,
  IconButton,
  Phantom,
  SOLToken,
  Slope,
  Solflare,
  Spacer,
  Spinner,
  Stack,
  Text,
  USDCToken
} from '@/theme'
import { formatWalletAddress, getAtrBalance, getUsdcBalance, getWalletBalance } from '@/utils'
import { formatNumber } from '@/utils/formatNumber'
import { TokenSymbol } from '@/wallet-connect-vue'
import { publicKey, walletAdapter } from '@/wallet-connect-vue/init'
import { useQuery } from '@tanstack/vue-query'
import { ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const blockchain = ref('Solana')
const walletAddress = ref()
const formattedWalletAddress = ref()
const addressCopied = ref(false)
const spot = useSpot()

const {
  data: balance,
  isLoading,
  refetch
} = useQuery(
  [QUERIES.WALLET_BALANCE],
  async () => {
    let SOL: number
    let ATR: number
    let USDC: number
    let spotSOL: number
    let spotATR: number

    try {
      SOL = await getWalletBalance(walletAddress.value)
    } catch (err) {
      SOL = 0
    }

    try {
      ATR = await getAtrBalance(walletAddress.value)
    } catch (err) {
      ATR = 0
    }

    try {
      USDC = await getUsdcBalance(walletAddress.value)
    } catch (err) {
      USDC = 0
    }

    try {
      spotSOL = await spot.get(TokenSymbol.SOL)
    } catch (err) {
      spotSOL = 0
    }

    try {
      spotATR = await spot.get(TokenSymbol.ATR)
    } catch (err) {
      spotATR = 0
    }

    const spotFormatOptions = {
      style: 'currency',
      currency: 'USD'
    } as Intl.NumberFormatOptions

    return {
      SOL: {
        current: formatNumber(SOL),
        spot: formatNumber(SOL * spotSOL, spotFormatOptions)
      },
      ATR: {
        current: formatNumber(ATR),
        spot: formatNumber(ATR * spotATR, spotFormatOptions)
      },
      USDC: {
        current: formatNumber(USDC),
        spot: formatNumber(USDC, spotFormatOptions)
      }
    }
  },
  {
    onError(err) {
      console.error(err)
    },
    cacheTime: 30000,
    staleTime: 30000,
    enabled: false
  }
)

function copyWalletAddress() {
  navigator.clipboard.writeText(walletAddress.value)

  addressCopied.value = true

  setTimeout(() => {
    addressCopied.value = false
  }, 3000)
}

watchEffect(async () => {
  if (!publicKey.value) return

  walletAddress.value = publicKey.value.toString()
  formattedWalletAddress.value = formatWalletAddress(walletAddress.value)

  refetch()
})
</script>

<style scoped lang="scss">
.artrade-wallet-box {
  padding: var(--artrade-space-8);
  border-radius: var(--artrade-radii-2xl);
  background-color: var(--artrade-colors-grey-50);
}
</style>
