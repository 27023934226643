import { useWallet } from '@/wallet-connect-vue/init'
import { WalletSendTransactionError, WalletSignTransactionError } from '@solana/wallet-adapter-base'
import { Transaction, VersionedTransaction } from '@solana/web3.js'
import { useI18n } from 'vue-i18n'
import { useSolana } from './useSolana'

export function useTransaction() {
  const { t } = useI18n()
  const wallet = useWallet()
  const solana = useSolana()

  async function sendAndConfirm(transaction: Transaction | VersionedTransaction): Promise<string> {
    return new Promise(async (resolve, reject) => {
      let signature: string

      try {
        signature = await wallet.adapter.value.sendTransaction(transaction, solana.connection, {
          skipPreflight: false
        })
      } catch (err) {
        let error = t('An error occurred while sending the transaction')

        if (err instanceof WalletSendTransactionError) {
          error = t(err.message)
        }

        if (err instanceof WalletSignTransactionError) {
          error = t(err.message)
        }

        reject(error)
      }

      // Subscribe to the signature event
      const signatureSubscription = solana.connection.onSignature(signature, async (res) => {
        // Unsubscribe to signature event
        solana.connection.removeSignatureListener(signatureSubscription)

        // Transaction in error (simply message for end user)
        if (res.err) {
          console.error(
            `Signature error: check the state at https://solscan.io/tx/${signature}?cluster=custom&customUrl=${solana.connection.rpcEndpoint}):`
          )

          reject(t('An error occurred while confirming the transaction'))

          return
        }

        resolve(signature)
      })
    })
  }

  return {
    sendAndConfirm
  }
}
