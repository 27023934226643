import { Medium } from '@/models'
import backend from '@/utils/backend'

async function getAll(): Promise<Medium[]> {
  return await backend.get('medium')
}
async function getAllUsed(): Promise<Medium[]> {
  return await backend.get('used-medium')
}

export default {
  getAll,
  getAllUsed
}
