<template>
  <BottomSheet v-slot="{ close }">
    <BottomSheetBody :spacing="8">
      <RouterLink
        :to="{ name: RouteName.Profile, params: { id: user.data.urlName } }"
        @click="close"
      >
        <Stack class="profile-link" :spacing="7">
          <OptimizedAvatar size="lg" :src="avatar" />
          <Stack direction="column" :spacing="4">
            <Heading as="h6" fontWeight="semibold" fontSize="xs">{{ user.data.nickname }}</Heading>
            <Text color="grey.700">{{ t('View Profile') }}</Text>
          </Stack>
        </Stack>
      </RouterLink>
      <nav>
        <BottomSheetLink as="router-link" @click="close" :to="{ name: RouteName.Staking }">{{
          t('ATR & staking')
        }}</BottomSheetLink>
        <BottomSheetLink as="router-link" @click="close" :to="{ name: RouteName.Activity }">{{
          t('Activity')
        }}</BottomSheetLink>
        <BottomSheetLink as="router-link" @click="close" :to="{ name: RouteName.Message }"
          >{{ t('Messages') }}
          <Spacer />
          <Stack class="notification-indicator" v-if="user.message.unread > 0"
            ><Text size="xs" color="white">{{ user.message.unread }}</Text></Stack
          ></BottomSheetLink
        >
        <BottomSheetLink as="router-link" @click="close" :to="{ name: RouteName.Orders }">{{
          t('Orders')
        }}</BottomSheetLink>
        <BottomSheetLink as="router-link" @click="close" :to="{ name: RouteName.Settings }">{{
          t('Settings')
        }}</BottomSheetLink>
        <BottomSheetLink as="button" @click="signOut">{{ t('Disconnect') }}</BottomSheetLink>
      </nav>
    </BottomSheetBody>
    <BottomSheetFooter>
      <WalletBox />
      <template v-if="!isWhitelisted">
        <Button as="router-link" :to="{ name: RouteName.Artist }" @click="close">{{
          t('Apply as an artist')
        }}</Button>
      </template>
      <template v-else-if="!isVerified">
        <Button @click="openVerifyAccountModal">{{ t('Get started') }}</Button>
      </template>
      <template v-else>
        <Button as="router-link" :to="{ name: RouteName.Create }" @click="close">{{
          t('Create now')
        }}</Button>
      </template>
    </BottomSheetFooter>
  </BottomSheet>
</template>

<script setup lang="ts">
import WalletBox from '@/components/WalletBox.vue'
import { VerifyAccountModal } from '@/components/modals'
import { RouteName } from '@/router'
import { user } from '@/store/user'
import {
  BottomSheet,
  BottomSheetBody,
  BottomSheetFooter,
  BottomSheetLink,
  Button,
  Heading,
  Spacer,
  Stack,
  Text,
  useBottomSheetConfig,
  useModal
} from '@/theme'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import OptimizedAvatar from '../OptimizedAvatar.vue'

const { t } = useI18n()
const bottomSheetConfig = useBottomSheetConfig()
const modal = useModal()

function signOut() {
  bottomSheetConfig.close()
  user.disconnect()
}

function openVerifyAccountModal() {
  modal.open(VerifyAccountModal)
  bottomSheetConfig.close()
}

const avatar = computed(() => user.data.avatarUrlUid)
const isWhitelisted = computed(() => user.isWhitelisted())
const isVerified = computed(() => user.isVerified())
</script>

<style scoped lang="scss">
.profile-link {
  padding: var(--artrade-space-8);
  background-color: var(--artrade-colors-white);
  border-radius: var(--artrade-radii-md);
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: var(--artrade-colors-grey-50);
  }
}
</style>
