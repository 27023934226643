<template>
  <RouterLink :to="`/nft/${nft?.urlName}`">
    <Card>
      <CardImage :src="nft.mainImageUid" alt="">
        <CardImageOverlay>
          <Stack
            justifyContent="space-between"
            class="justify-content-end justify-content-md-between"
          >
            <Spacer />
            <IconButton
              v-tooltip="t(user.data.bookmarked[nft?.id] ? 'Unbookmark' : 'Bookmark')"
              :variant="user.data.bookmarked[nft?.id] ? 'primary' : 'outline'"
              icon="bookmarkEmpty"
              v-if="user.data.id"
              :isLoading="toggleBookmark.isLoading.value"
              @click="toggleBookmark.mutate"
            />
          </Stack>
        </CardImageOverlay>
      </CardImage>

      <CardFooter :spacing="7">
        <Stack direction="column" :spacing="3">
          <Text>{{ nft?.creator.name }}</Text>
          <Text color="grey.500" italic>{{ nft?.title }}, {{ yearOfCreation }}</Text>
        </Stack>
        <Divider />
        <Stack direction="column" :spacing="4">
          <Text color="grey.600" size="xs">{{ t('Buy now') }}</Text>
          <Text fontWeight="semibold">{{ price?.price }} {{ price?.currency }}</Text>
        </Stack>
      </CardFooter>
    </Card>
  </RouterLink>
</template>

<script setup lang="ts">
import { Card, CardFooter, CardImage, CardImageOverlay } from '@/components/design/card'
import { useCustomModal } from '@/composables'
import { useNft } from '@/composables/useNft'
import type { Nft } from '@/models'
import { user } from '@/store/user'
import { Divider, IconButton, Spacer, Stack, Text } from '@/theme'
import { useMouse } from '@/utils/useMouse'
import { useMutation } from '@tanstack/vue-query'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'

const props = defineProps<{
  nft: Nft
}>()

const { t } = useI18n()
const mouse = useMouse()
const { bookmark, getFormattedPrice, getCreationYear } = useNft()
const modal = useCustomModal()

const toggleBookmark = useMutation({
  mutationFn(evt: MouseEvent) {
    mouse.stop(evt)

    return bookmark(props?.nft?.id, user.data.id)
  },
  onSuccess(nft: Nft) {
    props.nft.followed = nft?.followed
    user.data.bookmarked[props?.nft?.id] = user.data.bookmarked[props?.nft?.id]
      ? null
      : props?.nft?.id
  },
  onError() {
    modal.error()
  }
})

const yearOfCreation = computed(() => getCreationYear(props?.nft))
const price = computed(() => getFormattedPrice(props?.nft))
</script>
