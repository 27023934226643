<template>
  <span ref="element" class="artrade--animated-tag">
    <slot></slot>
  </span>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { nextTick, onMounted, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    trigger: gsap.DOMTarget
    delay?: gsap.TweenValue
    duration?: gsap.TweenValue
    start?: ScrollTrigger.Vars['start']
    end?: ScrollTrigger.Vars['end']
    containerAnimation?: gsap.core.Tween
    scrub?: boolean
  }>(),
  {
    delay: 0,
    duration: 0.6,
    start: 'center 75%',
    end: null
  }
)

const element = ref<HTMLElement>(null)
const tween = ref<gsap.core.Tween>(null)

async function animate() {
  await nextTick()

  tween.value = gsap.to(element.value, {
    opacity: 1,
    yPercent: -10,
    duration: props.duration,
    delay: props.delay,
    scrollTrigger: {
      trigger: props.trigger,
      start: props.start,
      end: props.end,
      scrub: props.scrub,
      toggleActions: 'play none none reverse',
      containerAnimation: props.containerAnimation
    }
  })
}

onMounted(() => {
  animate()
})

watch(
  () => props.containerAnimation,
  () => {
    tween.value?.kill()
    animate()
  }
)
</script>

<style scoped lang="scss">
.artrade--animated-tag {
  position: relative;
  height: 32px;
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  padding: 0 var(--artrade-space-5);
  border: 1px solid var(--artrade-colors-black);
  border-radius: var(--artrade-radii-full);
  width: auto;
  font-size: clamp(16px, 2vw, 20px);
  opacity: 0;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  will-change: transform;
}
</style>
