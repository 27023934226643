import {
  Placement,
  computePosition,
  flip,
  offset as offsetMiddleware,
  shift
} from '@floating-ui/dom'
import { nextTick, onMounted, reactive, ref, watch, watchEffect } from 'vue'

export type UseMenuConfig = {
  menuWidth?: number
  placement?: Placement
  offset?: number
}

export function useMenu(config: UseMenuConfig = {}) {
  const { menuWidth, placement = 'top-start', offset = 8 } = config
  const isOpen = ref<boolean>(false)
  const trigger = ref<HTMLDivElement>(null)
  const menu = ref<HTMLDivElement>(null)

  const menuStyle = reactive({
    left: '0px',
    top: '0px',
    width: '0px'
  })

  onMounted(() => {
    menuStyle.width = menuWidth ? `${menuWidth}px` : `${trigger.value.offsetWidth}px`
  })

  watchEffect(() => {
    if (!trigger.value) return

    menuStyle.width = menuWidth ? `${menuWidth}px` : `${trigger.value.offsetWidth}px`
  })

  watch(isOpen, async (currentValue) => {
    if (!currentValue) return

    await nextTick()

    const { x, y } = await computePosition(trigger.value, menu.value, {
      placement,
      middleware: [offsetMiddleware(offset), shift(), flip()]
    })

    menuStyle.left = `${x}px`
    menuStyle.top = `${y}px`
  })

  function toggle() {
    isOpen.value = !isOpen.value
  }

  return {
    menuStyle,
    isOpen,
    menu,
    trigger,
    toggle
  }
}
