export enum TokenSymbol {
  ATR = 'ATR',
  SOL = 'SOL',
  USDC = 'USDC',
  USDT = 'USDT',
  JUP = 'JUP',
  ETH = 'ETH',
  BTC = 'BTC',
  USDT_BSC = 'USDTBSC',
  USDT_ERC20 = 'USDTERC20',
  USDC_SOL = 'USDCSOL',
  USDC_ERC20 = 'USDCERC20',
  USDC_BSC = 'USDCBSC',
  WARH = 'WARH'
}
