<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#E6E6E6" />
    <g clip-path="url(#clip0_4690_66072)">
      <path
        d="M7.83912 14.5427C7.91349 14.4683 8.01575 14.4249 8.1242 14.4249H17.9596C18.1394 14.4249 18.2292 14.6418 18.1022 14.7689L16.1593 16.7118C16.0849 16.7862 15.9826 16.8296 15.8742 16.8296H6.03874C5.85902 16.8296 5.76915 16.6126 5.8962 16.4856L7.83912 14.5427Z"
        fill="#1A1A1A"
      />
      <path
        d="M7.83906 7.28853C7.91652 7.21416 8.01878 7.17078 8.12414 7.17078H17.9596C18.1393 7.17078 18.2292 7.38769 18.1021 7.51474L16.1592 9.45765C16.0848 9.53202 15.9826 9.57541 15.8741 9.57541H6.03868C5.85896 9.57541 5.76909 9.35849 5.89614 9.23145L7.83906 7.28853Z"
        fill="#1A1A1A"
      />
      <path
        d="M16.1593 10.8924C16.0849 10.818 15.9826 10.7747 15.8742 10.7747H6.03874C5.85902 10.7747 5.76915 10.9916 5.8962 11.1186L7.83912 13.0615C7.91349 13.1359 8.01575 13.1793 8.1242 13.1793H17.9596C18.1394 13.1793 18.2292 12.9624 18.1022 12.8353L16.1593 10.8924Z"
        fill="#1A1A1A"
      />
    </g>
    <defs>
      <clipPath id="clip0_4690_66072">
        <rect width="12.3237" height="9.6588" fill="white" transform="translate(5.83813 7.17078)" />
      </clipPath>
    </defs>
  </svg>
</template>
