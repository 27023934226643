<script setup lang="ts">
import { Button, Heading, Modal, ModalBody, ModalFooter, ModalHeader, Stack, Text } from '@/theme'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>

<template>
  <Modal v-slot="{ close }">
    <ModalHeader>
      <Heading as="h4" size="md">{{ t('What is a wallet?') }}</Heading>
    </ModalHeader>
    <ModalBody>
      <Stack direction="column" :spacing="12">
        <Text size="md" color="grey.700" lineHeight="md">{{
          t(
            'A wallet is like a digital wallet for your cryptocurrencies. It stores your private keys and allows you to access your cryptocurrencies and NFTs on the blockchain.'
          )
        }}</Text>
        <Text size="md" color="grey.700" lineHeight="md">{{
          t('You need a wallet to manage and authorize crypto transactions.')
        }}</Text>
        <Stack direction="column" :spacing="8">
          <Stack :spacing="7">
            <Step>1</Step>
            <Text size="md" lineHeight="md">{{
              t("Don't have a wallet yet? Go to a Solana wallet provider such as Phantom or Glow.")
            }}</Text>
          </Stack>
          <Divider />
          <Stack :spacing="7">
            <Step>2</Step>
            <Text size="md" lineHeight="md">{{
              t(
                "Follow the steps to create your wallet. Don't forget to securely store your seed phrase! It's crucial in case you get locked out."
              )
            }}</Text>
          </Stack>
          <Divider />
          <Stack :spacing="7">
            <Step>3</Step>
            <Text size="md" lineHeight="md">{{
              t(
                "Install the browser extension, it'll allow you to safely and easily manage your wallet interactions on Artrade. ✨"
              )
            }}</Text>
          </Stack>
        </Stack>
      </Stack>
    </ModalBody>
    <ModalFooter>
      <Button size="lg" @click="close">{{ t('I understand') }}</Button>
    </ModalFooter>
  </Modal>
</template>
