import * as icons from '@iconoir/vue'
import ATR from './ATR.vue'
import ATRToken from './ATRToken.vue'
import ArtworkDigital from './ArtworkDigital.vue'
import ArtworkPhysical from './ArtworkPhysical.vue'
import Backpack from './Backpack.vue'
import Facebook from './Facebook.vue'
import Glow from './Glow.vue'
import Highlight from './Highlight.vue'
import Instagram from './Instagram.vue'
import Medium from './Medium.vue'
import Phantom from './Phantom.vue'
import SOLToken from './SOLToken.vue'
import Slope from './Slope.vue'
import Solana from './Solana.vue'
import Solflare from './Solflare.vue'
import Solscan from './Solscan.vue'
import Twitter from './Twitter.vue'
import USDCToken from './USDCToken.vue'

const Iconoir = {}

for (const icon in icons) {
  Iconoir[icon.charAt(0).toLocaleLowerCase() + icon.slice(1)] = icons[icon]
}

export {
  ATR,
  ATRToken,
  ArtworkDigital,
  ArtworkPhysical,
  Backpack,
  Facebook,
  Glow,
  Highlight,
  Iconoir,
  Instagram,
  Medium,
  Phantom,
  SOLToken,
  Slope,
  Solana,
  Solflare,
  Solscan,
  Twitter,
  USDCToken
}
