
import { time } from '@/store/time.js'

export default {

    get: async function() {

        let timeTmp = await time.get()
        return timeTmp;
    }
}