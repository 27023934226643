<template>
  <component :is="as" :type="type" :disabled="isDisabled" :class="classnames">
    <template v-if="isLoading">
      <Spinner></Spinner>
    </template>
    <template v-else>
      <template v-if="leftIcon">
        <Icon :name="leftIcon" :size="20" />
      </template>
      <slot></slot>
      <template v-if="rightIcon">
        <Icon :name="rightIcon" :size="20" />
      </template>
    </template>
  </component>
</template>

<script setup lang="ts">
import Icon from '@/components/design/Icon.vue'
import Spinner from '@/components/misc/Spinner.vue'
import type { ButtonProps } from '@/components/types'
import { getResponsiveValue } from '@/utils'
import { computed } from 'vue'

const props = withDefaults(defineProps<ButtonProps>(), {
  variant: 'primary',
  isDisabled: undefined,
  isLoading: false,
  size: 'md',
  type: 'button',
  as: 'button',
  fill: false,
  alignSelf: null,
  underline: false,
  theme: 'light'
})

const classnames = computed(() => {
  const output = []

  output.push('artrade-button')
  output.push(`-${props.variant}`)
  output.push(`-${props.size}`)

  if (props.theme === 'dark') {
    output.push('-dark')
  }

  if (props.isLoading) {
    output.push(`-loading`)
  }

  if (props.leftIcon) {
    output.push(`-left-icon`)
  }

  if (props.rightIcon) {
    output.push(`-right-icon`)
  }

  if (props.fill) {
    output.push(`-fill`)
  }

  if (props.alignSelf) {
    output.push(getResponsiveValue(props.alignSelf, `-align-self`))
  }

  if (props.underline) {
    output.push('-underline')
  }

  return output.join(' ')
})
</script>

<style scoped lang="scss">
$breakpoints: (
  base: 0px,
  sm: 320px,
  md: 768px,
  lg: 960px,
  xl: 1200px,
  \2xl: 1536px
);

.artrade-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--artrade-space-8);
  border-radius: var(--artrade-radii-full);
  border: none;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
  appearance: none;
  font-weight: var(--artrade-fontWeights-semibold);
  gap: var(--artrade-space-3);
  user-select: none;
  white-space: nowrap;
  line-height: 0;
  position: relative;

  @each $breakpoint, $value in $breakpoints {
    @if $breakpoint == base {
      &.-align-self-start {
        align-self: flex-start;
      }

      &.-align-self-center {
        align-self: center;
      }

      &.-align-self-end {
        align-self: flex-end;
      }
    } @else {
      @media screen and (min-width: $value) {
        &.-align-self-#{$breakpoint}-start {
          align-self: flex-start;
        }

        &.-align-self-#{$breakpoint}-center {
          align-self: center;
        }

        &.-align-self-#{$breakpoint}-end {
          align-self: flex-end;
        }
      }
    }
  }

  &:hover {
    transform: translate3d(0, -1.6px, 0);
  }

  &:active {
    transform: translate3d(0, 2px, 0);
  }

  &.-fill {
    width: 100%;
  }

  &.-primary {
    background-color: var(--artrade-colors-black);
    color: var(--artrade-colors-white);
    box-shadow: 0px 2px 4px rgba(202, 207, 226, 0.16);

    svg {
      color: var(--artrade-colors-white);
    }

    &:hover {
      box-shadow: 0px 4px 8px rgba(202, 207, 226, 0.32);
    }

    &:active {
      background-color: var(--artrade-colors-grey-1100);
    }

    &.-dark {
      background-color: var(--artrade-colors-white);
      color: var(--artrade-colors-black);
      box-shadow: none;

      svg {
        color: var(--artrade-colors-black);
      }

      &:hover {
        box-shadow: none;
      }

      &:active {
        background-color: var(--artrade-colors-grey-50);
      }
    }
  }

  &.-outline {
    background-color: var(--artrade-colors-white);
    border: 1px;
    border-style: solid;
    border-color: var(--artrade-colors-grey-200);
    color: var(--artrade-colors-black);
    box-shadow: 0px 2px 4px rgba(202, 207, 226, 0.16);

    &.-dark {
      background-color: var(--artrade-colors-grey-1100);
      color: var(--artrade-colors-white);
      box-shadow: none;
      border: none;
    }
  }

  &.-ghost {
    background-color: transparent;
    color: var(--artrade-colors-black);

    &:hover {
      background-color: var(--artrade-colors-grey-100);
    }
  }

  &.-plain {
    background-color: transparent;
    color: var(--artrade-colors-black);
    transform: translate3d(0, 0, 0) !important;
    padding: 0;

    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }
  }

  &.-underline {
    text-decoration: underline !important;
  }

  &[disabled],
  &.-loading {
    cursor: not-allowed;
    color: var(--artrade-colors-grey-600) !important;
    background-color: var(--artrade-colors-grey-100) !important;
    border: none !important;
    box-shadow: none !important;
    transform: translate3d(0, 0, 0) !important;

    &:deep(svg) {
      color: var(--artrade-colors-grey-600) !important;
    }
  }

  &.-sm {
    height: 32px;

    &:deep(svg) {
      width: 16px;
      height: 16px;
    }
  }

  &.-md {
    height: 42px;

    &:deep(svg) {
      width: 18px;
      height: 18px;
    }
  }

  &.-lg {
    height: 52px;
    font-size: var(--artrade-fontSizes-md);
    font-weight: var(--artrade-fontWeights-semibold);
    padding: 0 var(--artrade-space-10);
    gap: var(--artrade-space-6);

    &:deep(svg) {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
