import { ErrorModal, ShareModal } from '@/components/modals'
import { useModal } from '@/theme'

/**
 * Extends useModal composable to implement other custom modal with business code
 */
export function useCustomModal() {
  const modal = useModal()

  /**
   * Open share modal to share artrade url to social network
   */
  function share(url: string) {
    return modal.open(ShareModal, {
      url
    })
  }

  /**
   * Open generic error modal
   */
  function error(description = 'An error occurred') {
    return modal.open(
      ErrorModal,
      {
        description
      },
      {
        closeable: false
      }
    )
  }

  return {
    ...modal,
    share,
    error
  }
}
