import ATR from './ATR.vue'
import SOL from './SOL.vue'
import USDC from './USDC.vue'
import WARH from './WARH.vue'

export default {
  ATR,
  SOL,
  USDC,
  WARH
}
