import { PublicKey } from '@solana/web3.js'
import { getUsdcTokenAccount } from './getUsdcTokenAccount.ts'
import { connection } from './initConnection'

export async function getUsdcBalance(walletAddress: string) {
  const associatedTokenAccount = await getUsdcTokenAccount(walletAddress.toString())

  try {
    const balance = await connection.getTokenAccountBalance(new PublicKey(associatedTokenAccount))
    return balance.value.uiAmount
  } catch (_) {
    return 0
  }
}
