<template>
  <component :is="as" :class="classnames">
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { TextProps } from '../types'
import { cx, getClassnameFromToken } from '../utils'

const props = withDefaults(defineProps<TextProps>(), {
  size: 'sm',
  as: 'p',
  color: 'black',
  align: 'left',
  fontWeight: 'regular',
  fontFamily: 'body',
  lineHeight: 'xs',
  underline: false,
  uppercase: false,
  truncate: false,
  italic: false
})

const classnames = computed(() =>
  cx(
    'artrade-text',
    getClassnameFromToken(props.color),
    `-${props.size}`,
    `-line-height-${props.lineHeight}`,
    `-align-${props.align}`,
    `-font-${props.fontFamily}`,
    `-font-weight-${props.fontWeight}`,
    {
      '-underline': props.underline,
      '-uppercase': props.uppercase,
      '-truncate': props.truncate,
      '-italic': props.italic
    }
  )
)
</script>

<style scoped lang="scss">
.artrade-text {
  &.-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.-italic {
    font-style: italic;
  }

  &.-underline {
    text-decoration: underline;
  }

  &.-uppercase {
    text-transform: uppercase;
  }

  &.-line-height-xs {
    line-height: var(--artrade-lineHeights-xs);
  }

  &.-line-height-sm {
    line-height: var(--artrade-lineHeights-sm);
  }

  &.-line-height-md {
    line-height: var(--artrade-lineHeights-md);
  }

  &.-line-height-lg {
    line-height: var(--artrade-lineHeights-lg);
  }

  &.-line-height-xl {
    line-height: var(--artrade-lineHeights-xl);
  }

  &.-align-left {
    text-align: left;
  }

  &.-align-center {
    text-align: center;
  }

  &.-align-right {
    text-align: right;
  }

  &.-font-body {
    font-family: var(--artrade-fonts-body);
  }

  &.-font-mono {
    font-family: var(--artrade-fonts-mono);
  }

  &.-font-serif {
    font-family: var(--artrade-fonts-serif);
  }

  &.-font-weight-hairline {
    font-weight: var(--artrade-fontWeights-hairline);
  }

  &.-font-weight-thin {
    font-weight: var(--artrade-fontWeights-thin);
  }

  &.-font-weight-light {
    font-weight: var(--artrade-fontWeights-light);
  }

  &.-font-weight-regular {
    font-weight: var(--artrade-fontWeights-normal);
  }

  &.-font-weight-medium {
    font-weight: var(--artrade-fontWeights-medium);
  }

  &.-font-weight-semibold {
    font-weight: var(--artrade-fontWeights-semibold);
  }

  &.-font-weight-bold {
    font-weight: var(--artrade-fontWeights-bold);
  }

  &.-font-weight-extrabold {
    font-weight: var(--artrade-fontWeights-extrabold);
  }

  &.-font-weight-black {
    font-weight: var(--artrade-fontWeights-black);
  }
}

.\-6xl {
  font-size: var(--artrade-fontSizes-6xl);
}

.\-5xl {
  font-size: var(--artrade-fontSizes-5xl);
}

.\-4xl {
  font-size: var(--artrade-fontSizes-4xl);
}

.\-3xl {
  font-size: var(--artrade-fontSizes-3xl);
}

.\-2xl {
  font-size: var(--artrade-fontSizes-2xl);
}

.-xl {
  font-size: var(--artrade-fontSizes-xl);
}

.-lg {
  font-size: var(--artrade-fontSizes-lg);
}

.-md {
  font-size: var(--artrade-fontSizes-md);
}

.-sm {
  font-size: var(--artrade-fontSizes-sm);
}

.-xs {
  font-size: var(--artrade-fontSizes-xs);
}
</style>
