<script setup lang="ts">
import backend from '@/utils/backend'
import { nextTick, ref, watch, watchEffect } from 'vue'

import Button from '@/components/design/Button.vue'
import Heading from '@/components/design/Heading.vue'
import IconButton from '@/components/design/IconButton.vue'
import Stack from '@/components/design/Stack.vue'
import Text from '@/components/design/Text.vue'
import { FormControl, Input, InputGroup, InputSuffix } from '@/components/design/form'
import { Tab, Tabs } from '@/components/design/tabs'
import { Modal, ModalBody, ModalContent } from '@/components/modal'
import { Collection, Nft, User } from '@/models'
import { Avatar } from '@/theme'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'
import OptimizedAvatar from './OptimizedAvatar.vue'

const { t } = useI18n()
const query = ref('')
const isOpen = ref(false)
const nfts = ref<Nft[]>([])
const profiles = ref<User[]>([])
const collections = ref<Collection[]>([])
const selectedTabIndex = ref(0)
const input = ref<{ inputRef: HTMLInputElement }>(null)

function close() {
  query.value = ''
  isOpen.value = false
}

// When query changes, execute automatically (query is a dependency of watchEffect)
watchEffect(async () => {
  if (query.value === '') return

  const results = await backend.get(`search/${query.value}`)

  nfts.value = results.artworks
  profiles.value = results.profiles
  collections.value = results.collections
})

// Autofocus search field
watch(
  () => isOpen.value,
  async (current) => {
    if (current) {
      await nextTick()
      input.value.inputRef.focus()
    }
  }
)
</script>

<template>
  <IconButton icon="search" variant="outline" class="d-flex d-md-none" @click="isOpen = true" />
  <Modal variant="full" :closeable="false" :isOpen="isOpen" @onClose="isOpen = false">
    <ModalContent :spacing="0">
      <Stack class="artrade-search" direction="column" justify :spacing="5">
        <Stack :spacing="5" justifyContent="space-between" flex>
          <FormControl isRequired>
            <InputGroup>
              <Input ref="input" rounded :placeholder="t('Search...')" v-model="query" />
              <InputSuffix>
                <Button v-if="query?.length > 0" size="sm" variant="ghost" @click="query = ''">{{
                  t('Clear')
                }}</Button>
              </InputSuffix>
            </InputGroup>
          </FormControl>
          <IconButton variant="outline" icon="cancel" @click="close" />
        </Stack>
        <Tabs v-if="query?.length > 0">
          <Tab :isActive="selectedTabIndex === 0" @click="selectedTabIndex = 0">{{
            t('Artworks')
          }}</Tab>
          <Tab :isActive="selectedTabIndex === 1" @click="selectedTabIndex = 1">{{
            t('Collections')
          }}</Tab>
          <Tab :isActive="selectedTabIndex === 2" @click="selectedTabIndex = 2">{{
            t('Profiles')
          }}</Tab>
        </Tabs>
      </Stack>
      <ModalBody>
        <template v-if="query?.length > 0">
          <template v-if="selectedTabIndex === 0">
            <template v-if="nfts?.length > 0">
              <RouterLink
                v-for="nft of nfts"
                :key="nft?.id"
                :to="`/nft/${nft?.urlName}`"
                @click="close"
              >
                <Stack :spacing="7">
                  <OptimizedAvatar size="lg" squared :src="nft?.mainImageUid" />
                  <Stack direction="column" :spacing="3">
                    <Text size="md" fontWeight="semibold">{{ nft?.title }}</Text>
                    <Stack :spacing="3" alignItems="center">
                      <OptimizedAvatar size="xs" :src="nft?.creator?.avatarUrlUid" />
                      <Text size="xs" color="grey.700">@{{ nft?.creator?.nickname }}</Text>
                    </Stack>
                  </Stack>
                </Stack>
              </RouterLink>
            </template>
            <template v-else>
              <Stack flex alignItems="center" justifyContent="center">
                <Stack direction="column" :spacing="3">
                  <Heading as="h5" size="sm">{{ t('No search results') }}</Heading>
                  <Text color="grey.600">{{
                    t('There are no results for "{query}"', {
                      query: query
                    })
                  }}</Text>
                </Stack>
              </Stack>
            </template>
          </template>
          <template v-if="selectedTabIndex === 1">
            <template v-if="collections?.length > 0">
              <RouterLink
                v-for="collection of collections"
                :key="collection?.id"
                :to="`/collection/${collection?.slug}`"
                @click="close"
              >
                <Stack :spacing="7">
                  <OptimizedAvatar size="lg" squared :src="collection?.mainImageUid" />
                  <Stack direction="column" :spacing="3">
                    <Text size="md" fontWeight="semibold">{{ collection?.title }}</Text>
                    <Stack :spacing="3" alignItems="center">
                      <OptimizedAvatar size="xs" :src="collection?.creator?.avatarUrlUid" />
                      <Text size="xs" color="grey.700">@{{ collection?.creator?.nickname }}</Text>
                    </Stack>
                  </Stack>
                </Stack>
              </RouterLink>
            </template>
            <template v-else>
              <Stack flex alignItems="center" justifyContent="center">
                <Stack direction="column" :spacing="3">
                  <Heading as="h5" size="sm">{{ t('No search results') }}</Heading>
                  <Text color="grey.600">{{
                    t('There are no results for "{query}"', {
                      query: query
                    })
                  }}</Text>
                </Stack>
              </Stack>
            </template>
          </template>
          <template v-if="selectedTabIndex === 2">
            <template v-if="profiles?.length > 0">
              <RouterLink
                v-for="profile of profiles"
                :key="profile?.id"
                :to="`/profile/${profile?.urlName}`"
                @click="close"
              >
                <Stack :spacing="3" alignItems="center">
                  <Avatar size="lg" squared :src="profile?.avatarUrlUid" />
                  <Text size="md">@{{ profile?.nickname }}</Text>
                </Stack>
              </RouterLink>
            </template>
            <template v-else>
              <Stack flex alignItems="center" justifyContent="center">
                <Stack direction="column" :spacing="3">
                  <Heading as="h5" size="sm">{{ t('No search results') }}</Heading>
                  <Text color="grey.600">{{
                    t('There are no results for "{query}"', {
                      query: query
                    })
                  }}</Text>
                </Stack>
              </Stack>
            </template>
          </template>
        </template>
      </ModalBody>
    </ModalContent>
  </Modal>
</template>

<style scoped lang="scss">
.artrade-search {
  padding: var(--artrade-space-9) var(--artrade-space-9) 0;
}
</style>
