<template>
  <Stack :spacing="0" class="artrade-input-suffix" alignItems="center">
    <slot></slot>
    <Divider color="grey.200" vertical v-if="divider" />
  </Stack>
</template>

<script setup lang="ts">
import Divider from '@/components/design/Divider.vue'
import Stack from '@/components/design/Stack.vue'
import { inject, onUnmounted } from 'vue'
import { InputGroupProvider, type InputGroupContext } from './types'

const props = withDefaults(
  defineProps<{
    divider?: boolean
  }>(),
  {
    divider: false
  }
)

const ctx = inject<InputGroupContext>(InputGroupProvider)

ctx.hasSuffix.value = true
ctx.hasSuffixDivider.value = Boolean(props.divider)

onUnmounted(() => {
  ctx.hasSuffix.value = false
  ctx.hasSuffixDivider.value = false
})
</script>

<style scoped lang="scss">
.artrade-input-suffix {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 var(--artrade-space-7);
  z-index: 2;

  &:deep(svg) {
    width: 16px;
    height: 16px;
  }

  &:deep(.artrade-divider) {
    position: absolute;
    left: 0;
    z-index: -1;
    height: 100%;
  }
}
</style>
