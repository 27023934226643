<template>
  <div class="form-control" role="group">
    <label
      >{{ label }} <span class="optional" v-if="!required">{{ t('(Optional)') }}</span></label
    >
    <textarea
      v-if="type === 'textarea'"
      :placeholder="placeholder"
      :readonly="readonly"
      :required="required"
      :aria-readonly="readonly ? true : undefined"
      :aria-required="required ? true : undefined"
      :aria-invalid="invalid ? true : undefined"
      :rows="rows"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    ></textarea>
    <input
      v-else
      :type="type"
      :placeholder="placeholder"
      :readonly="readonly"
      :required="required"
      :aria-readonly="readonly ? true : undefined"
      :aria-required="required ? true : undefined"
      :aria-invalid="invalid ? true : undefined"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div class="hint" v-if="hint">
      <p>{{ hint }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    label?: string
    placeholder?: string
    type?: HTMLInputElement['type']
    disabled?: boolean
    required?: boolean
    readonly?: boolean
    invalid?: boolean
    hint?: string
    rows?: number
    modelValue?: string | number
  }>(),
  {
    type: 'text',
    disabled: false,
    required: false,
    readonly: false,
    invalid: false,
    rows: 6
  }
)

defineEmits(['update:modelValue'])
const { t } = useI18n()
</script>

<style scoped lang="scss">
.form-control {
  display: flex;
  flex-direction: column;
  gap: var(--artrade-space-3);

  label {
    display: block;
    text-align: start;
    font-weight: var(--artrade-fontWeights-medium);

    .optional {
      color: var(--artrade-colors-grey-500);
    }
  }

  input,
  textarea {
    position: relative;
    width: 100%;
    padding-inline-start: var(--artrade-space-8);
    padding-inline-end: var(--artrade-space-8);
    appearance: none;
    outline: 0;
    transition: all 0.2s ease;
    border-width: 1px;
    border-style: solid;
    border-radius: 12px;
    border-color: var(--artrade-colors-grey-200);
    box-shadow: 0px 2px 4px rgba(202, 207, 226, 0.16);
    background-color: transparent;

    &:focus {
      border-color: var(--artrade-colors-black);
      box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.1);
    }

    &::placeholder {
      color: var(--artrade-colors-grey-500);
    }
  }

  textarea {
    padding: var(--artrade-space-8);
  }

  input {
    height: 42px;
  }

  .hint {
    font-size: var(--artrade-fontSizes-xs);
    color: var(--artrade-colors-grey-600);
  }
}
</style>
