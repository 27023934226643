<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
      fill="#1A1A1A"
    />
    <g clip-path="url(#clip0_6256_38413)">
      <path
        d="M19.9995 25C17.237 25 14.9995 22.7625 14.9995 20C14.9995 17.2375 17.237 15 19.9995 15C22.3245 15 24.262 16.6 24.8245 18.75H29.912C29.2995 13.8125 25.0995 10 19.9995 10C14.4745 10 9.99951 14.475 9.99951 20C9.99951 25.525 14.4745 30 19.9995 30C25.0995 30 29.2995 26.1875 29.912 21.25H24.8245C24.262 23.4 22.3245 25 19.9995 25Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_6256_38413">
        <rect width="20" height="20" fill="white" transform="translate(10 10)" />
      </clipPath>
    </defs>
  </svg>
</template>
