<template>
  <Container class="artrade-footer" direction="column" as="footer" :spacing="14">
    <Stack
      flex
      :spacing="{
        base: 13,
        md: 9
      }"
      :direction="{
        base: 'column',
        md: 'row'
      }"
    >
      <Stack class="artrade-footer-brand" direction="column" :spacing="7">
        <Brand as="router-link" :to="{ name: RouteName.Home }" />
        <Text color="grey.700">Be unique.</Text>
      </Stack>
      <Stack as="nav" flex class="artrade-footer-nav" wrap="wrap" :spacing="9" :rowSpacing="12">
        <Stack class="artrade-footer-links" flex direction="column">
          <Text size="md" fontWeight="semibold">Marketplace</Text>
          <Text
            as="router-link"
            :to="{ name: RouteName.FeedArtwork, query: { sale: 'auction' } }"
            fontWeight="medium"
            color="grey.700"
            >{{ t('Auctions') }}</Text
          >
          <Text
            as="router-link"
            :to="{ name: RouteName.FeedArtwork, query: { sale: 'opentooffers' } }"
            fontWeight="medium"
            color="grey.700"
            >{{ t('Open to offers') }}</Text
          >
          <Text
            as="router-link"
            :to="{ name: RouteName.FeedArtwork, query: { sale: 'fixedprice' } }"
            fontWeight="medium"
            color="grey.700"
            >{{ t('Fixed price') }}</Text
          >
          <Text
            as="router-link"
            :to="{ name: RouteName.FeedArtwork }"
            fontWeight="medium"
            color="grey.700"
            >{{ t('Artworks') }}</Text
          >
        </Stack>
        <Stack class="artrade-footer-links" flex direction="column">
          <Text size="md" fontWeight="semibold">{{ t('Resources') }}</Text>
          <Text
            as="a"
            target="_blank"
            href="https://artrade.medium.com"
            fontWeight="medium"
            color="grey.700"
            >{{ t('Blog') }}</Text
          >
          <Text
            as="a"
            target="_blank"
            href="https://artradeapp.notion.site/Artrade-FAQ-5bb50865a81743b1be9498cf89dea3ee?pvs=4"
            fontWeight="medium"
            color="grey.700"
            >FAQ</Text
          >
          <Text
            as="a"
            target="_blank"
            href="mailto:support@artrade.app"
            fontWeight="medium"
            color="grey.700"
            >{{ t('Support') }}</Text
          >
          <Text
            as="a"
            href="mailto:contact@artrade.app"
            fontWeight="medium"
            lineHeight="md"
            color="grey.700"
            >{{ t('Suggest a feature') }}</Text
          >
          <Text
            as="a"
            href="https://www.dextools.io/app/en/solana/pair-explorer/2Ky6BskrcKNCJSrP4X6bgrPPe1erBArBAhyZi2C8nPwy?t=1709643401364"
            target="_blank"
            fontWeight="medium"
            lineHeight="md"
            color="grey.700"
            >Dextool</Text
          >
        </Stack>
        <Stack class="artrade-footer-links" flex direction="column">
          <Text size="md" fontWeight="semibold">{{ t('Account') }}</Text>
          <template v-if="user?.data?.id">
            <Text
              as="router-link"
              :to="{ name: RouteName.Profile, params: { id: user.data.urlName } }"
              fontWeight="medium"
              color="grey.700"
              >{{ t('View my profile') }}</Text
            >
            <Text
              as="router-link"
              :to="{ name: RouteName.ProfileSettings }"
              fontWeight="medium"
              color="grey.700"
              >{{ t('Edit profile') }}</Text
            >
            <Text
              as="router-link"
              :to="{ name: RouteName.Activity }"
              fontWeight="medium"
              color="grey.700"
              >{{ t('Activity') }}</Text
            >
          </template>
          <template v-else>
            <Text @click="openSignin" fontWeight="medium" color="grey.700" class="artrade-link">{{
              t('Sign in')
            }}</Text>
          </template>
        </Stack>
        <Stack class="artrade-footer-links" flex direction="column">
          <Text size="md" fontWeight="semibold">{{ t('Community') }}</Text>
          <Text
            as="a"
            target="_blank"
            href="https://twitter.com/ArtradeApp"
            fontWeight="medium"
            color="grey.700"
            >X</Text
          >
          <Text
            as="a"
            target="_blank"
            href="https://www.instagram.com/artrade.app"
            fontWeight="medium"
            color="grey.700"
            >Instagram</Text
          >
          <Text
            as="a"
            target="_blank"
            href="https://artrade.medium.com"
            fontWeight="medium"
            color="grey.700"
            >Medium</Text
          >
          <Text
            as="a"
            target="_blank"
            href="http://discord.gg/artrade"
            fontWeight="medium"
            color="grey.700"
            >Discord</Text
          >
          <Text
            as="a"
            target="_blank"
            href="https://t.me/ArtradeEnglish"
            fontWeight="medium"
            color="grey.700"
            >Telegram EN</Text
          >
          <Text
            as="a"
            target="_blank"
            href="https://t.me/ArtradeFR"
            fontWeight="medium"
            color="grey.700"
            >Telegram FR</Text
          >
        </Stack>
      </Stack>
    </Stack>
    <Divider />
    <Stack
      class="artrade-footer-bottom"
      flex
      :direction="{
        base: 'column',
        md: 'row'
      }"
      :justifyContent="{
        base: 'start',
        md: 'space-between'
      }"
      :alignItems="{
        base: 'center',
        md: 'center'
      }"
    >
      <Text color="grey.500" size="xs"
        >{{ t('Copyright') }} © {{ currentYear }} {{ t('ARTRADE.APP, All rights reserved') }}</Text
      >
      <Stack
        :direction="{
          base: 'column',
          md: 'row'
        }"
        :alignItems="{
          base: 'center',
          md: 'center'
        }"
      >
        <Stack :spacing="8" alignItems="center">
          <Text
            as="a"
            size="xs"
            :href="`/legal/tc-${locale}.pdf`"
            target="_blank"
            fontWeight="medium"
            color="grey.500"
            >{{ t('Terms of Service') }}</Text
          >
          <Text
            as="a"
            size="xs"
            :href="`/legal/pp-${locale}.pdf`"
            target="_blank"
            fontWeight="medium"
            color="grey.500"
            >{{ t('Privacy') }}</Text
          >
        </Stack>
        <LanguageModal v-slot="{ open, locale }">
          <Button variant="ghost" size="sm" leftIcon="language" @click="open">{{
            t(locale)
          }}</Button>
        </LanguageModal>
      </Stack>
    </Stack>
  </Container>
</template>

<script setup lang="ts">
import Brand from '@/components/BrandFull.vue'
import { SigninModal } from '@/components/modals'
import { RouteName } from '@/router/types'
import { user } from '@/store/user'
import { Button, Container, Divider, Stack, Text, useModal } from '@/theme'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import LanguageModal from './LanguageModal.vue'

const { t, locale } = useI18n()
const modal = useModal()

function openSignin() {
  modal.open(SigninModal)
}

const currentYear = computed(() => new Date().getFullYear())
</script>

<style scoped lang="scss">
.artrade-footer {
  padding-top: 0;
  padding-bottom: 0;
  flex: initial;

  .artrade-footer-brand {
    @media screen and (min-width: 768px) {
      width: calc(100% / 12 * 4);
    }
  }

  a,
  .artrade-link {
    align-self: start;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      color: var(--artrade-colors-black);
    }
  }

  .artrade-footer-nav {
    .artrade-footer-links {
      @media screen and (max-width: 768px) {
        flex: 0 0 calc(50% - 10px);
      }

      .artrade-social-linkbox {
        width: 32px;
        height: 32px;
        background-color: var(--artrade-colors-grey-100);
        border-radius: var(--artrade-radii-full);
      }
    }
  }

  .artrade-footer-bottom {
    padding-bottom: var(--artrade-space-16);
    width: 100%;
  }
}
</style>
