<template>
  <slot></slot>
</template>

<script setup lang="ts">
import Lenis from '@studio-freight/lenis'
import { gsap } from 'gsap'
import { onBeforeUnmount, onMounted, ref } from 'vue'

import { ScrollTrigger } from 'gsap/ScrollTrigger'

const lenis = ref<Lenis>(new Lenis())

function ticker(time: number) {
  lenis.value.raf(time * 1000)
}

onMounted(() => {
  lenis.value.on('scroll', ScrollTrigger.update)

  gsap.ticker.add(ticker)
  gsap.ticker.lagSmoothing(0)
})

onBeforeUnmount(() => {
  gsap.ticker.remove(ticker)

  lenis.value.off('scroll', ScrollTrigger.update)
  lenis.value.destroy()

  ScrollTrigger.clearScrollMemory()
})

defineExpose({
  lenis: lenis.value
})
</script>

<style>
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
</style>
