<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#0C192B" />
    <g clip-path="url(#clip0_4826_42883)">
      <path
        d="M7.83933 14.5426C7.9137 14.4682 8.01596 14.4248 8.12442 14.4248H17.9598C18.1396 14.4248 18.2294 14.6417 18.1024 14.7688L16.1595 16.7117C16.0851 16.7861 15.9828 16.8294 15.8744 16.8294H6.03896C5.85923 16.8294 5.76937 16.6125 5.89641 16.4855L7.83933 14.5426Z"
        fill="url(#paint0_linear_4826_42883)"
      />
      <path
        d="M7.83835 7.28865C7.91582 7.21428 8.01808 7.1709 8.12344 7.1709H17.9589C18.1386 7.1709 18.2285 7.38781 18.1014 7.51486L16.1585 9.45778C16.0841 9.53215 15.9819 9.57553 15.8734 9.57553H6.03798C5.85825 9.57553 5.76839 9.35862 5.89544 9.23157L7.83835 7.28865Z"
        fill="url(#paint1_linear_4826_42883)"
      />
      <path
        d="M16.1595 10.8927C16.0851 10.8183 15.9828 10.7749 15.8744 10.7749H6.03896C5.85923 10.7749 5.76937 10.9918 5.89641 11.1189L7.83933 13.0618C7.9137 13.1362 8.01596 13.1795 8.12442 13.1795H17.9598C18.1396 13.1795 18.2294 12.9626 18.1024 12.8356L16.1595 10.8927Z"
        fill="url(#paint2_linear_4826_42883)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4826_42883"
        x1="17.0203"
        y1="6.00998"
        x2="10.2134"
        y2="19.0479"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00FFA3" />
        <stop offset="1" stop-color="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4826_42883"
        x1="14.043"
        y1="4.45635"
        x2="7.23605"
        y2="17.4943"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00FFA3" />
        <stop offset="1" stop-color="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4826_42883"
        x1="15.5226"
        y1="5.22851"
        x2="8.71572"
        y2="18.2665"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00FFA3" />
        <stop offset="1" stop-color="#DC1FFF" />
      </linearGradient>
      <clipPath id="clip0_4826_42883">
        <rect width="12.3237" height="9.6588" fill="white" transform="translate(5.83789 7.1709)" />
      </clipPath>
    </defs>
  </svg>
</template>
