import { createI18n } from 'vue-i18n'
import en from './en.json'
import fr from './fr.json'

type MessageSchema = typeof en
export const I18N_KEY = '$$artrade-language'

const messages = {
  en,
  fr
}

const i18n = createI18n<[MessageSchema], 'en' | 'fr'>({
  // Enable composition API
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages
})

// When the browser is configured on french language, set correct translation
if (localStorage.getItem(I18N_KEY)) {
  i18n.global.locale.value = localStorage.getItem(I18N_KEY)
} else {
  if (navigator?.language === 'fr-FR') {
    i18n.global.locale.value = 'fr'
  }
}

export default i18n
