<template>
  <Stack :class="['artrade-skeleton', { '-animate': animation }]">
    <slot></slot>
  </Stack>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import type { SkeletonProps } from '../types'
import Stack from './Stack.vue'

const props = withDefaults(defineProps<SkeletonProps>(), {
  startColor: 'grey.50',
  endColor: 'grey.100',
  animation: true
})

const startColor = computed(() => {
  return `var(--artrade-colors-${props.startColor.replace('.', '-')})`
})

const endColor = computed(() => {
  return `var(--artrade-colors-${props.endColor.replace('.', '-')})`
})
</script>

<style scoped lang="scss">
@keyframes skeleton {
  0% {
    background-color: v-bind('startColor');
  }
  100% {
    background-color: v-bind('endColor');
  }
}

.artrade-skeleton {
  border-radius: var(--artrade-radii-md);
  background-color: var(--artrade-colors-grey-100);

  &.-animate {
    animation: skeleton 1s linear infinite alternate;
  }
}
</style>
