<template>
  <Stack direction="column" :spacing="12" v-if="data?.nfts?.length > 3 && !isLoading">
    <Stack justifyContent="space-between">
      <Stack :spacing="5">
        <Heading as="h3" fontWeight="semibold" size="lg">{{ t('Last auctions') }}</Heading>
        <Text color="grey.500" size="md">{{ data.count }}</Text>
      </Stack>
      <Button
        size="md"
        variant="outline"
        as="router-link"
        :to="{ name: RouteName.Feed, query: { sale: 'auction' } }"
        class="d-none d-md-flex"
        >{{ t('View all Auctions') }}</Button
      >
    </Stack>
    <Grid>
      <template v-if="isLoading">
        <ArtworkCardSkeleton />
        <ArtworkCardSkeleton />
        <ArtworkCardSkeleton />
        <ArtworkCardSkeleton />
      </template>
      <template v-else-if="data">
        <NftCard v-for="nft in data.nfts" :key="nft?.id" :nft="nft" />
      </template>
    </Grid>
    <Button
      size="lg"
      variant="outline"
      as="router-link"
      :to="{ name: RouteName.Feed }"
      class="d-flex d-md-none"
      >{{ t('View all Auctions') }}</Button
    >
  </Stack>
</template>

<script setup lang="ts">
import api from '@/api'
import NftCard from '@/components/misc/NftCard.vue'
import ArtworkCardSkeleton from '@/components/skeletons/ArtworkCardSkeleton.vue'
import { QUERIES } from '@/constants'
import { RouteName } from '@/router/types'
import { Button, Grid, Heading, Stack, Text } from '@/theme'
import { useQuery } from '@tanstack/vue-query'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const { isLoading, data } = useQuery({
  queryKey: [QUERIES.LAST_AUCTIONS],
  queryFn: api.nft.getLastAuctions
})
</script>
