import { SOLANA_CLUSTER } from '@/constants';
import idl from "@/idl_real.json";
import { AnchorProvider, Program } from "@coral-xyz/anchor";
import { Connection, PublicKey } from "@solana/web3.js";
import { anchorWallet } from '@/wallet-connect-vue/init.ts'
import { computed } from "vue";

const preflightCommitment = "processed";
const commitment = "confirmed";
const programID = new PublicKey(idl.metadata.address);

let workspace = null;
export const useWorkspaceReal = () => workspace;

export const initWorkspaceReal = () => {
  const wallet = anchorWallet();
  const connection = new Connection(SOLANA_CLUSTER, commitment);
  const provider = computed(
    () =>
      new AnchorProvider(connection, wallet, {
        preflightCommitment,
        commitment,
      })
  );
  const programReal = computed(() => new Program(idl, programID, provider.value));

  workspace = {
    wallet,
    connection,
    provider,
    programReal,
  };
};
