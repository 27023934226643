<template>
  <Stack
    :spacing="10"
    direction="column"
    :as="as"
    :class="classnames"
    role="dialog"
    tabindex="-1"
    aria-modal="true"
  >
    <slot :props="config.props" :close="config.close"></slot>
  </Stack>
</template>

<script setup lang="ts">
import { cx } from '@/theme/utils'
import { computed } from 'vue'
import Stack from '../../../Stack.vue'
import { useModalConfig } from '../../composables/useModalConfig'
import type { ModalProps } from '../../types/ModalProps'

const props = defineProps<ModalProps>()

const config = useModalConfig()
const size = computed(() => {
  if (props.size) {
    return props.size
  }

  return config.modalProps.size
})
const classnames = computed(() =>
  cx(`modal-content`, `-${size.value}`, { '-no-padding': props.noPadding })
)
</script>

<style scoped lang="scss">
@import '../../../../styles/variables.scss';

.modal-content {
  position: relative;
  width: 100%;
  border-radius: var(--artrade-radii-3xl);
  background-color: var(--artrade-colors-white);
  z-index: 9999;
  box-shadow: var(--artrade-shadows-lg);
  padding: var(--artrade-space-9);
  transition: all 0.3s ease;
  margin-top: auto;
  margin-bottom: auto;
  margin-inline: auto;
  pointer-events: all;
  overflow: hidden;

  @media (min-width: map-get($breakpoints, md)) {
    padding: var(--artrade-space-12);
  }

  &.-no-padding {
    padding: 0 !important;
  }

  &.-sm {
    max-width: var(--artrade-modal-sm);
  }

  &.-md {
    max-width: var(--artrade-modal-md);
  }

  &.-lg {
    max-width: var(--artrade-modal-lg);
  }

  &.-xl {
    max-width: var(--artrade-modal-xl);
  }

  &.\-2xl {
    max-width: var(--artrade-modal-2xl);
  }

  &.\-3xl {
    max-width: var(--artrade-modal-3xl);
  }

  &.\-4xl {
    max-width: var(--artrade-modal-4xl);
  }
}
</style>
