import { formatNumber } from '@/utils'
import backend from '@/utils/backend'
import { TokenSymbol } from '@/wallet-connect-vue'

export function useSpot() {
  async function getATR(): Promise<number> {
    try {
      const { price } = await backend.get('spot-price/get')

      return price
    } catch (err) {
      console.error(err)
      return 0
    }
  }

  async function getSOL(): Promise<number> {
    try {
      return backend.get('spot-price/sol/get')
    } catch (err) {
      console.error(err)
      return 0
    }
  }

  async function getWARH(): Promise<number> {
    try {
      return 0.95
    } catch (err) {
      console.error(err)
      return 0
    }
  }

  async function get(token: TokenSymbol) {
    if (token === TokenSymbol.ATR) {
      return getATR()
    } else if (token === TokenSymbol.SOL) {
      return getSOL()
    } else if (token === TokenSymbol.WARH) {
      return getWARH()
    } else {
      return 0
    }
  }

  async function getFormattedUSD(price: number, token: TokenSymbol) {
    const formatOptions = {
      currency: 'USD',
      style: 'currency'
    } as Intl.NumberFormatOptions

    try {
      if ([TokenSymbol.SOL, TokenSymbol.ATR, TokenSymbol.WARH].includes(token)) {
        const spotAmount = await get(token)
        return formatNumber(price * spotAmount, formatOptions)
      } else if (token === TokenSymbol.USDC) {
        return formatNumber(price, formatOptions)
      } else {
        return formatNumber(0, formatOptions)
      }
    } catch (err) {
      console.error(err)
      return formatNumber(0, formatOptions)
    }
  }

  return {
    get,
    getFormattedUSD
  }
}
