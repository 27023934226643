<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
      fill="#E6E6E6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.2319 16.2001C10.2213 16.2001 10.2107 16.2002 10.2 16.2002C7.88043 16.2002 6.00003 14.3198 6.00003 12.0002C6.00003 9.68057 7.88043 7.80017 10.2 7.80017C12.5196 7.80017 14.4 9.68051 14.4 12.0001H14.4002V16.2001H10.2319Z"
      fill="#1A1A1A"
    />
    <circle cx="16.5001" cy="9.30017" r="1.5" fill="#1A1A1A" />
  </svg>
</template>
