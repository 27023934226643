import { Component } from 'vue'
import { ComponentProps } from '../../../types'
import type { BottomSheetProps } from '../types'
import { useBottomSheetStore } from './useBottomSheetStore'

export function useBottomSheet() {
  const store = useBottomSheetStore()

  function open<C extends Component, P extends ComponentProps>(
    component: C,
    props?: P,
    options?: BottomSheetProps
  ) {
    return store.open(component, props, options)
  }

  return {
    open,
    closeAll: store.closeAll
  }
}
