import { createJupiterApiClient } from '@jup-ag/api'
import { VersionedTransaction } from '@solana/web3.js'
import { publicKey } from '@/wallet-connect-vue/init.ts'

const jupiterQuoteApi = createJupiterApiClient() // config is optional

export function useJupiter() {
  async function getRate(inputMint: string, outputMint: string, amount: number): Promise<number> {
    const decimals = inputMint == 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v' ? 6 : 9

    const quote = await jupiterQuoteApi.quoteGet({
      inputMint: inputMint,
      outputMint: outputMint,
      amount: amount * Math.pow(10, decimals)
      // platformFeeBps: 10,
      // asLegacyTransaction: true, // legacy transaction, default is versoined transaction
    })

    return quote
  }

  async function getSpotRate(
    inputMint: string,
    outputMint: string,
    amount: number
  ): Promise<number> {
    if (!amount) return 0

    if (amount == 0) return 0

    const quote = await getRate(inputMint, outputMint, amount)

    return Math.round(quote.outAmount / 10000000) / 100
  }

  async function createSwapTransaction(inputMint: string, outputMint: string, amount: number) {
    const quote = await getRate(inputMint, outputMint, amount)

    const swapResult = await jupiterQuoteApi.swapPost({
      swapRequest: {
        quoteResponse: quote,
        userPublicKey: publicKey.value.toString(),
        dynamicComputeUnitLimit: true
      }
    })

    // submit transaction
    const swapTransactionBuf = Buffer.from(swapResult.swapTransaction, 'base64')
    const transaction = VersionedTransaction.deserialize(swapTransactionBuf)

    return transaction
  }

  return {
    getSpotRate,
    createSwapTransaction
  }
}
