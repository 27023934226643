<template>
  <Modal size="sm" v-slot="{ props, close }">
    <ModalHeader direction="column" alignItems="center">
      <Heading align="center" as="h4" size="md">{{ t('An error occurred') }}</Heading>
    </ModalHeader>
    <ModalBody justifyContent="center">
      <Text lineHeight="md" color="grey.700" align="center">{{ t(props.description) }}</Text>
    </ModalBody>
    <ModalFooter>
      <Button variant="outline" @click="close">{{ t('Cancel') }}</Button>
    </ModalFooter>
  </Modal>
</template>

<script setup lang="ts">
import { Button, Heading, Modal, ModalBody, ModalFooter, ModalHeader, Text } from '@/theme'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
