<template>
  <Stack class="artrade-ads">
    <Container
      class="artrade-ads-wrapper"
      :direction="{
        base: 'column',
        md: 'row'
      }"
      :spacing="{
        base: 13,
        md: 0
      }"
    >
      <Stack justifyContent="center" alignItems="center">
        <Stack class="artrade-ads-content" direction="column" justifyContent="center" :spacing="9">
          <Heading align="center" as="h1" lineHeight="xs" size="3xl"
            >{{ t('Create your first artwork') }}
            <Heading as="span" fontFamily="serif" fontWeight="light" size="3xl">{{
              t('for free')
            }}</Heading></Heading
          >
          <Text size="md" lineHeight="md" align="center">{{
            t(
              "When you register for the first time, you'll get 6 mints free of charge, allowing you to create artworks or collections for free. Enjoy now!"
            )
          }}</Text>
          <template v-if="isConnected">
            <template v-if="!isWhitelisted">
              <Button
                as="router-link"
                :to="{ name: RouteName.Artist }"
                size="lg"
                alignSelf="center"
                >{{ t('Apply as an artist') }}</Button
              >
            </template>
            <template v-else-if="!isVerified">
              <VerifyAccountModal v-slot="{ open }">
                <Button size="lg" alignSelf="center" @click="open">{{ t('Get started') }}</Button>
              </VerifyAccountModal>
            </template>
            <template v-else>
              <Button
                as="router-link"
                :to="{ name: RouteName.Create }"
                size="lg"
                alignSelf="center"
                >{{ t('Create now') }}</Button
              >
            </template>
          </template>
          <template v-else>
            <Button
              size="lg"
              :alignSelf="{
                base: null,
                md: 'center'
              }"
              @click="openSignin"
              >{{ t('Get started') }}</Button
            >
          </template>
        </Stack>
      </Stack>
      <Stack class="artrade-ads-image">
        <img
          src="/images/ads/artrade-ads-create.png"
          :alt="t('Create your first artwork for free')"
          loading="lazy"
          decoding="async"
        />
      </Stack>
    </Container>
  </Stack>
</template>

<script setup lang="ts">
import { SigninModal } from '@/components/modals'
import { RouteName } from '@/router/types'
import { user } from '@/store/user'
import { Button, Container, Heading, Stack, Text, useModal } from '@/theme'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import VerifyAccountModal from './VerifyAccountModal.vue'
const { t } = useI18n()
const modal = useModal()

function openSignin() {
  modal.open(SigninModal)
}

const isConnected = computed(() => Object.keys(user.data)?.length !== 0)
const isWhitelisted = computed(() => user.isWhitelisted())
const isVerified = computed(() => user.isVerified())
</script>

<style scoped lang="scss">
.artrade-ads {
  background-color: var(--artrade-colors-grey-100);
  overflow: hidden;

  .artrade-ads-wrapper {
    padding: var(--artrade-space-17) 0;

    @media screen and (min-width: 768px) {
      padding: 0;
    }
  }

  .artrade-ads-content {
    padding: 0 var(--artrade-space-9);

    @media screen and (min-width: 768px) {
      width: 50%;
      padding: var(--artrade-space-17) 0;
    }
  }

  .artrade-ads-image {
    position: relative;

    @media screen and (min-width: 768px) {
      height: 100%;
      width: 50%;
    }

    img {
      @media screen and (min-width: 768px) {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}
</style>
