<template>
  <Avatar v-bind="props" :src="null" ref="avatarRef">
    <OptimizedImage
      :src="src"
      :alt="alt"
      maxWidth="100%"
      maxHeight="auto"
      :rounded="!props.squared"
      :squared="props.squared"
      :objectFit="objectFit"
      :objectPosition="objectPosition"
      :size="imageSize"
    />
    <slot></slot>
  </Avatar>
</template>

<script setup lang="ts">
import { Avatar, AvatarProps } from '@/theme'
import { ref } from 'vue'
import OptimizedImage from './OptimizedImage.vue'
import { OptimizedImageProps } from './types'

const props = withDefaults(
  defineProps<
    AvatarProps & {
      imageSize?: OptimizedImageProps['size']
      objectFit?: OptimizedImageProps['objectFit']
      objectPosition?: OptimizedImageProps['objectPosition']
    }
  >(),
  {
    imageSize: 'sm',
    objectFit: 'cover',
    objectPosition: 'center'
  }
)
const avatarRef = ref<{ el: HTMLElement }>()

defineExpose({
  avatarRef
})
</script>
