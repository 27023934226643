import { Component, markRaw, reactive, toRefs } from 'vue'
import { ComponentProps } from '../../../types'
import type {
  BottomSheetConfig,
  BottomSheetProps,
  BottomSheetStore,
  UseBottomSheetStoreReturn
} from '../types'

const INITIAL_PROPS: BottomSheetProps = {
  closeable: true
}

const store = reactive<BottomSheetStore>({
  sheets: []
})

export function useBottomSheetStore(): UseBottomSheetStoreReturn {
  function open<C extends Component, P extends ComponentProps>(
    component: C,
    props?: P,
    bottomSheetProps?: BottomSheetProps
  ) {
    const config: BottomSheetConfig = reactive({
      component: markRaw(component),
      props,
      isOpen: true,
      index: store.sheets.length,
      bottomSheetProps: {
        ...INITIAL_PROPS,
        ...bottomSheetProps
      },
      close() {
        config.isOpen = false
        store.sheets = store.sheets.filter((sheet: BottomSheetConfig) => {
          return sheet.index !== config.index
        })
      }
    })

    store.sheets = [...store.sheets, config]

    return config
  }

  function closeAll() {
    store.sheets = []
  }

  return {
    ...toRefs(store),
    open,
    closeAll
  }
}
