<template>
  <Stack direction="column" class="artrade-artist-card" :spacing="9">
    <Stack
      class="artrade-artist-card-cover"
      as="router-link"
      :to="{ name: RouteName.Profile, params: { id: artist.urlName } }"
    >
      <OptimizedImage
        maxWidth="100%"
        maxHeight="100%"
        objectFit="cover"
        :src="artist.avatarUrlUid"
        :alt="artist.nickname"
      />
    </Stack>
    <Stack class="artrade-artist-card-footer" direction="column">
      <Stack justifyContent="space-between">
        <Stack :spacing="6" alignItems="center">
          <OptimizedAvatar size="sm" :src="artist.avatarUrlUid" />
          <Text size="md" lineHeight="md">{{ artist.name }}</Text>
        </Stack>
        <Button
          size="sm"
          variant="outline"
          @click.stop="follow.mutate"
          :isLoading="follow.isLoading.value"
          class="d-none d-md-flex"
          v-if="connectedUser.id"
          >{{ artist.followed ? t('Unfollow') : t('Follow') }}</Button
        >
      </Stack>
      <Stack :spacing="8">
        <Stack direction="column" :spacing="3">
          <Text color="grey.600">{{ t('Followers') }}</Text>
          <Text fontWeight="semibold">{{ formatCash(artist.count.follower) }}</Text>
        </Stack>
        <Divider vertical />
        <Stack direction="column" :spacing="3">
          <Text color="grey.600">{{ t('Artworks') }}</Text>
          <Text fontWeight="semibold">{{ formatCash(artist.count.artwork) }}</Text>
        </Stack>
        <Divider vertical />
        <Stack direction="column" :spacing="3">
          <Text color="grey.600">{{ t('Collections') }}</Text>
          <Text fontWeight="semibold">{{ formatCash(artist.count.collection) }}</Text>
        </Stack>
      </Stack>
      <Button
        size="sm"
        variant="outline"
        @click.stop="follow.mutate"
        :isLoading="follow.isLoading.value"
        class="d-md-none d-flex"
        v-if="connectedUser.id"
        >{{ artist.followed ? t('Unfollow') : t('Follow') }}</Button
      >
    </Stack>
  </Stack>
</template>

<script setup lang="ts">
import api from '@/api'
import { QUERIES } from '@/constants'
import { User } from '@/models'
import { RouteName } from '@/router'
import { connectedUser } from '@/store/connectedUser'
import { Button, Divider, Stack, Text } from '@/theme'
import { formatCash } from '@/utils'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { useI18n } from 'vue-i18n'
import OptimizedAvatar from './OptimizedAvatar.vue'
import OptimizedImage from './OptimizedImage.vue'

const props = defineProps<{
  artist: User
}>()

const { t } = useI18n()
const queryClient = useQueryClient()

const follow = useMutation({
  mutationFn: async () => {
    return api.user.follow(props.artist.id, connectedUser.id)
  },
  async onSuccess() {
    queryClient.invalidateQueries([QUERIES.POPULAR_ARTISTS])
  }
})
</script>

<style scoped lang="scss">
.artrade-artist-card {
  position: relative;

  @media screen and (min-width: 768px) {
    min-width: 400px;
  }

  .artrade-artist-card-cover {
    background-color: var(--artrade-colors-grey-100);
    border-radius: var(--artrade-radii-2xl);
    overflow: hidden;
    height: 280px;

    @media screen and (min-width: 768px) {
      height: 440px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
