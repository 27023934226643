import { PublicKey, Transaction, TransactionInstruction } from '@solana/web3.js'
const MEMO_PROGRAM_ID = new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr')

export async function createSignMessageTransaction(message: string) {
  const transaction = new Transaction()

  transaction.add(
    new TransactionInstruction({
      programId: MEMO_PROGRAM_ID,
      keys: [],
      data: Buffer.from(message, 'utf8')
    })
  )

  return transaction
}
