import { Collection } from '@/models'
import backend from '@/utils/backend'

async function getCollectionByUserId(id: number): Promise<Collection[]> {
  return await backend.get(`collection/by-user/${id}`)
}

export default {
  getCollectionByUserId
}
