<script setup lang="ts">
import { user } from '@/store/user'
import backend from '@/utils/backend'
import { disconnect, publicKey, signMessage } from '@/wallet-connect-vue/init.ts'
import { computed, reactive, ref, watch } from 'vue'

import FileInput from '@/components/FileInput.vue'
import FormControl from '@/components/FormControl.vue'
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  useModalConfig
} from '@/theme'
import { isInvalidString } from '@/utils'
import base58 from 'bs58'
import { useI18n } from 'vue-i18n'
import { useCookies } from 'vue3-cookies'
import {
  FormControl as FormControlV2,
  FormHint,
  FormLabel,
  Input,
  InputGroup,
  InputPrefix
} from '../design/form'

const { t } = useI18n()
const modalConfig = useModalConfig()

const isLoading = ref(false)
const form = reactive({
  avatar: null,
  email: null,
  username: null,
  displayName: null,
  description: null
})
const errors = reactive({
  username: true
})
const isUsernameAvailable = ref(false)

// Compute hint message for username field
const usernameHint = computed(() =>
  t(`Your profile will be available on artrade.app/{username}`, {
    username: form.username ?? '<username>'
  })
)

// Signup process
async function signup() {
  isLoading.value = true

  const formData = new FormData()

  formData.append('file', form.avatar)
  formData.append('email', form.email)
  formData.append('username', form.username)
  formData.append('description', form.description)
  formData.append('name', form.displayName)
  formData.append('walletAddress', publicKey.value.toString())

  try {
    const backendResponse = await backend.post('user/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    if (backendResponse.user) {
      // isSignupModalOpened.value = false
      modalConfig.close()

      const messageToSign = 'Please sign the message below to authenticate yourself'

      const message = new TextEncoder().encode(messageToSign)
      const uint8arraySignature = await signMessage(message)

      // the user didn't sign
      if (!uint8arraySignature) {
        disconnect()
        user.disconnect()
        return
      }

      const { cookies } = useCookies()

      const backendBearer = await backend.postNode('check-signature', {
        signature: base58.encode(uint8arraySignature),
        publicKey: publicKey.value.toString()
      })

      if (backendBearer !== false) {
        cookies.set('artrade-bearer', backendBearer, 60 * 60 * 24 * 60)
        await user.fetch(true)
      } else {
        disconnect()
        user.disconnect()
      }
    }
  } catch (err) {
    console.error(err)
  } finally {
    isLoading.value = false
  }
}

async function checkUsername() {
  isUsernameAvailable.value = await backend.post(`user/username`, {
    username: form.username
  })
}

watch(form, (current) => {
  errors.username = isInvalidString(current.username)
})

const isDisabled = computed(() => errors.username || !isUsernameAvailable.value)
</script>

<template>
  <Modal @submit.prevent="signup" as="form">
    <ModalHeader alignItems="center">
      <Heading as="h4" size="md">{{ t('Create an account') }}</Heading>
    </ModalHeader>
    <ModalBody>
      <Text size="md" color="grey.700" lineHeight="md">{{
        t('We need some information about you')
      }}</Text>
      <FileInput v-model="form.avatar" :hint="t('Max 5 MB in JPG, JPEG or PNG format')" />
      <FormControl v-model="form.email" label="Email" :placeholder="t('e.g. you at domain.com')" />
      <FormControlV2 isRequired>
        <FormLabel>{{ t('Username') }}</FormLabel>
        <InputGroup>
          <InputPrefix divider> @ </InputPrefix>
          <Input
            required
            v-model="form.username"
            @input="checkUsername"
            :placeholder="t('Enter your username')"
          />
        </InputGroup>
        <FormHint color="red.500" v-if="!isUsernameAvailable">
          {{ t('Username already exists') }}
        </FormHint>
        <FormHint color="red.500" v-else-if="errors.username">{{
          t('This field is required')
        }}</FormHint>
        <FormHint v-else>
          {{ usernameHint }}
        </FormHint>
      </FormControlV2>
      <FormControl
        v-model="form.displayName"
        label="Display name"
        :placeholder="t('e.g. Banksy')"
      />
      <FormControl
        v-model="form.description"
        type="textarea"
        label="Short bio"
        :placeholder="t('Tell about yourself in a few words')"
      />
    </ModalBody>
    <ModalFooter>
      <Button size="lg" type="submit" :isLoading="isLoading" :isDisabled="isDisabled">{{
        t('Create an account')
      }}</Button>
      <Text size="xs" color="grey.600" lineHeight="md" align="center">{{
        t(
          'By clicking the "Create an account" button above, I agree to Artrade Terms of Service and Privacy Policy'
        )
      }}</Text>
    </ModalFooter>
  </Modal>
</template>
