import { connection } from './initConnection'
import { USDC_MINT_ADDRESS } from '@/constants'
import { PublicKey } from '@solana/web3.js'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { getAssociatedTokenAccount } from './getAssociatedTokenAccount.js'

export async function getUsdcTokenAccount(walletAddress: string) {
  const standardAssociatedTokenAccount = await getAssociatedTokenAccount(
    USDC_MINT_ADDRESS,
    new PublicKey(walletAddress)
  )

  const accounts = await connection.getParsedProgramAccounts(TOKEN_PROGRAM_ID, {
    encoding: 'jsonParsed',
    filters: [
      {
        dataSize: 165
      },
      {
        memcmp: {
          offset: 0,
          bytes: USDC_MINT_ADDRESS.toString()
        }
      },
      {
        memcmp: {
          offset: 32,
          bytes: walletAddress.toString()
        }
      }
    ]
  })

  for (const account of accounts) {
    if (
      account.account.data.parsed.info.tokenAmount.amount > 0 &&
      account.pubkey.toString() == standardAssociatedTokenAccount.toString()
    ) {
      return account.pubkey.toString()
    }
  }

  for (const account of accounts) {
    if (account.account.data.parsed.info.tokenAmount.amount > 0) return account.pubkey.toString()
  }

  return standardAssociatedTokenAccount
}
