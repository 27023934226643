<template>
  <input
    :class="['form-control-input', { '-rounded': rounded, '-borderless': borderless }]"
    :type="type"
    :placeholder="placeholder"
    :readonly="isReadonly || ctx?.isReadonly"
    :required="isRequired || ctx?.isRequired"
    :disabled="isDisabled || ctx?.isDisabled"
    :aria-readonly="isReadonly || ctx?.isReadonly ? true : undefined"
    :aria-required="isRequired || ctx?.isRequired ? true : undefined"
    :aria-invalid="isInvalid || ctx?.isInvalid ? true : undefined"
    :value="modelValue"
    :maxlength="maxlength"
    @input="onInput"
    ref="inputRef"
  />
</template>

<script setup lang="ts">
import { inject, onMounted, onUnmounted, ref } from 'vue'
import { FormControlContext, FormControlProvider } from './types'

const props = withDefaults(
  defineProps<{
    placeholder?: string
    type?: 'text' | 'number' | 'date' | 'datetime' | 'email'
    isDisabled?: boolean
    isRequired?: boolean
    isReadonly?: boolean
    isInvalid?: boolean
    modelValue?: string | number
    rounded?: boolean
    maxlength?: number
    borderless?: boolean
  }>(),
  {
    type: 'text',
    placeholder: '',
    isDisabled: undefined,
    isRequired: undefined,
    isReadonly: undefined,
    isInvalid: undefined,
    rounded: false
  }
)

const inputRef = ref<HTMLInputElement>(null)
const ctx = inject<FormControlContext>(FormControlProvider)

defineExpose({
  inputRef
})

const emits = defineEmits(['update:modelValue', 'input'])

// Fix change value when scroll wheel for type number field
function handleOnWheel(e: WheelEvent) {
  const { type } = e.target as HTMLInputElement

  if (type === 'number') {
    e.preventDefault()
  }
}

function onInput(evt: Event) {
  const { value } = evt.target as HTMLInputElement

  emits('update:modelValue', props.type === 'number' ? Number(value) : value)
  emits('input', value)
}

onMounted(() => {
  document.addEventListener('wheel', handleOnWheel, { passive: false })
})

onUnmounted(() => {
  document.removeEventListener('wheel', handleOnWheel)
})
</script>

<style scoped lang="scss">
input {
  position: relative;
  width: 100%;
  padding-inline-start: var(--artrade-space-8);
  padding-inline-end: var(--artrade-space-8);
  appearance: none;
  outline: 0;
  transition: border-color box-shadow color 0.2s ease;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
  border-color: var(--artrade-colors-grey-200);
  box-shadow: 0px 2px 4px rgba(202, 207, 226, 0.16);
  background-color: transparent;
  height: 42px;
  z-index: 1;

  &.-rounded {
    border-radius: var(--artrade-radii-full);
  }

  &:focus {
    border-color: var(--artrade-colors-black);
    box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.1);
  }

  &::placeholder {
    color: var(--artrade-colors-grey-500);
  }

  &.-borderless {
    border: 0 !important;
    box-shadow: none !important;
  }

  &[readonly],
  &[disabled] {
    background-color: var(--artrade-colors-grey-100);
    color: var(--artrade-colors-grey-700);
    box-shadow: none;
  }
}
</style>
