<template>
  <template v-if="connectedUser.connected">
    <Button v-bind="props">
      <slot></slot>
    </Button>
  </template>
  <template v-else>
    <Button
      v-bind="{
        ...sanitizedProps,
        onClick() {
          signin()
        }
      }"
    >
      <slot></slot>
    </Button>
  </template>
</template>

<script setup lang="ts">
import { connectedUser } from '@/store/connectedUser'
import { Button, useModal, type ButtonProps } from '@/theme'
import { computed } from 'vue'
import { SigninModal } from './modals'

const props = defineProps<ButtonProps & { onClick: () => void }>()
const modal = useModal()

function signin() {
  modal.open(SigninModal)
}

/**
 * Remove onClick function from source props (@click), vue merge two prop with same name!
 */
function sanitizeProps(props: ButtonProps & { onClick: () => void }) {
  delete props?.onClick
  return props
}

const sanitizedProps = computed(() => sanitizeProps(props))
</script>
