<template>
  <Stack as="header" class="artrade-modal-header">
    <slot></slot>
    <IconButton v-if="ctx.closeable" @click="ctx.close" variant="outline" icon="cancel" />
  </Stack>
</template>

<script setup lang="ts">
import IconButton from '@/components/design/IconButton.vue'
import Stack from '@/components/design/Stack.vue'

import { inject } from 'vue'
import { ModalProvider, type ModalContext } from './types'

const ctx = inject<ModalContext>(ModalProvider)
</script>

<style scoped lang="scss">
.artrade-modal-header {
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--artrade-space-12);

  @media screen and (max-width: 768px) {
    padding: var(--artrade-space-9) var(--artrade-space-9) 0;
  }
}
</style>
