<template>
  <component :is="as" :class="classname" ref="el">
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
import { getResponsiveValue } from '@/utils'
import { computed, ref } from 'vue'
import { StackProps } from '../types'

const props = withDefaults(defineProps<StackProps>(), {
  wrap: 'nowrap',
  spacing: 9,
  rowSpacing: null,
  columnSpacing: null,
  direction: 'row',
  justifyContent: null,
  alignItems: null,
  flex: false,
  as: 'div'
})

const el = ref<HTMLElement>(null)

defineExpose({
  el
})

const classname = computed(() => {
  const output = ['artrade-stack']

  if (props.spacing) {
    output.push(getResponsiveValue(props.spacing, `-gap`))
  }

  if (props.rowSpacing) {
    output.push(getResponsiveValue(props.rowSpacing, `-row-gap`))
  }

  if (props.columnSpacing) {
    output.push(getResponsiveValue(props.columnSpacing, `-column-gap`))
  }

  if (props.alignItems) {
    output.push(getResponsiveValue(props.alignItems, '-align-items'))
  }

  if (props.justifyContent) {
    output.push(getResponsiveValue(props.justifyContent, '-justify-content'))
  }

  if (props.wrap) {
    output.push(getResponsiveValue(props.wrap))
  }

  if (props.direction) {
    output.push(getResponsiveValue(props.direction))
  }

  if (props.flex) {
    output.push(`-flex`)
  }

  return output.join(' ')
})
</script>

<style scoped lang="scss">
$breakpoints: (
  base: 0px,
  sm: 320px,
  md: 768px,
  lg: 960px,
  xl: 1200px,
  \2xl: 1536px
);

.artrade-stack {
  position: relative;
  display: flex;

  @each $breakpoint, $value in $breakpoints {
    @if $breakpoint == base {
      @for $i from 1 through 19 {
        &.-gap-#{$i} {
          gap: var(--artrade-space-#{$i});
        }

        &.-row-gap-#{$i} {
          row-gap: var(--artrade-space-#{$i});
        }

        &.-column-gap-#{$i} {
          column-gap: var(--artrade-space-#{$i});
        }
      }

      &.-flex {
        flex: 1;
      }

      &.-column {
        flex-direction: column;
      }

      &.-row {
        flex-direction: row;
      }

      &.-column-reverse {
        flex-direction: column-reverse;
      }

      &.-row-reverse {
        flex-direction: row-reverse;
      }

      &.-align-items-center {
        align-items: center;
      }

      &.-align-items-start {
        align-items: start;
      }

      &.-align-items-end {
        align-items: end;
      }

      &.-align-items-space-between {
        align-items: space-between;
      }

      &.-align-items-space-around {
        align-items: space-around;
      }

      &.-align-items-space-evenly {
        align-items: space-evenly;
      }

      &.-align-items-stretch {
        align-items: stretch;
      }

      &.-justify-content-center {
        justify-content: center;
      }

      &.-justify-content-start {
        justify-content: start;
      }

      &.-justify-content-end {
        justify-content: end;
      }

      &.-justify-content-space-between {
        justify-content: space-between;
      }

      &.-justify-content-space-around {
        justify-content: space-around;
      }

      &.-justify-content-space-evenly {
        justify-content: space-evenly;
      }

      &.-justify-content-stretch {
        justify-content: stretch;
      }

      &.-wrap {
        flex-wrap: wrap;
      }

      &.-wrap-reverse {
        flex-wrap: wrap-reverse;
      }

      &.-nowrap {
        flex-wrap: nowrap;
      }
    } @else {
      @media screen and (min-width: $value) {
        @for $i from 1 through 19 {
          &.-gap-#{$breakpoint}-#{$i} {
            gap: var(--artrade-space-#{$i});
          }

          &.-row-gap-#{$breakpoint}-#{$i} {
            row-gap: var(--artrade-space-#{$i});
          }

          &.-column-gap-#{$breakpoint}-#{$i} {
            column-gap: var(--artrade-space-#{$i});
          }
        }

        &.-#{$breakpoint}-flex {
          flex: 1;
        }

        &.-#{$breakpoint}-column {
          flex-direction: column;
        }

        &.-#{$breakpoint}-row {
          flex-direction: row;
        }

        &.-#{$breakpoint}-column-reverse {
          flex-direction: column-reverse;
        }

        &.-#{$breakpoint}-row-reverse {
          flex-direction: row-reverse;
        }

        &.-align-items-#{$breakpoint}-center {
          align-items: center;
        }

        &.-align-items-#{$breakpoint}-start {
          align-items: start;
        }

        &.-align-items-#{$breakpoint}-end {
          align-items: end;
        }

        &.-align-items-#{$breakpoint}-space-between {
          align-items: space-between;
        }

        &.-align-items-#{$breakpoint}-space-around {
          align-items: space-around;
        }

        &.-align-items-#{$breakpoint}-space-evenly {
          align-items: space-evenly;
        }

        &.-align-items-#{$breakpoint}-stretch {
          align-items: stretch;
        }

        &.-justify-content-#{$breakpoint}-center {
          justify-content: center;
        }

        &.-justify-content-#{$breakpoint}-start {
          justify-content: start;
        }

        &.-justify-content-#{$breakpoint}-end {
          justify-content: end;
        }

        &.-justify-content-#{$breakpoint}-space-between {
          justify-content: space-between;
        }

        &.-justify-content-#{$breakpoint}-space-around {
          justify-content: space-around;
        }

        &.-justify-content-#{$breakpoint}-space-evenly {
          justify-content: space-evenly;
        }

        &.-justify-content-#{$breakpoint}-stretch {
          justify-content: stretch;
        }

        &.-#{$breakpoint}-wrap {
          flex-wrap: wrap;
        }

        &.-#{$breakpoint}-wrap-reverse {
          flex-wrap: wrap-reverse;
        }

        &.-#{$breakpoint}-nowrap {
          flex-wrap: nowrap;
        }
      }
    }
  }
}
</style>
