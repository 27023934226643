import solanaTime from '@/utils/solana/solanaTime'
import { computed, onUnmounted, reactive, ref, toRefs } from 'vue'

export type UseCountdownConfig = {
  format?: {
    days?: boolean
    hours?: boolean
    minutes?: boolean
    seconds?: boolean
  }
  fromBlockchain?: boolean
}

type CountDownState = {
  time: number
  intervalId: NodeJS.Timeout
  days: number
  hours: number
  minutes: number
  seconds: number
  isEnded: boolean
}

const DEFAULT_CONFIG = {
  format: {
    days: false,
    hours: true,
    minutes: true,
    seconds: true
  },
  fromBlockchain: true
}

const DEFAULT_STATE: CountDownState = {
  time: null,
  intervalId: null,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
  isEnded: false
}

/**
 * Create countdown with provided date (ex: 23d 14h 26m 12s)
 * @param endDate end date for the countdown
 * @param config configuration object
 *
 * @example
 *
 * const endDate = new Date("2023-12-31");
 * const countdown = useCountdown(endDate);
 *
 * countdown.run();
 *
 * // Template
 * <p>{{ countdown.display }}</p>
 *
 * // or
 * <p>{{ countdown.days }} d {{ countdown.hours }} h {{ countdown.minutes }} m {{ countdown.seconds }} s</p>
 */
export function useCountdown(config: UseCountdownConfig = {}) {
  const { format, fromBlockchain } = {
    ...DEFAULT_CONFIG,
    ...config
  }

  const state = reactive({ ...DEFAULT_STATE })
  const onEndedListeners = ref<Array<() => void>>([])

  async function update() {
    let now: number

    if (fromBlockchain) {
      now = await solanaTime.get()
    } else {
      now = Date.now()
    }

    let distance = state.time - now

    if (distance < 0) {
      state.isEnded = true
      clearInterval(state.intervalId)

      for (const listener of onEndedListeners.value) {
        listener()
      }
    }

    if (fromBlockchain) {
      // state.days = Math.floor(distance / 86400)
      // distance -= state.days * 86400

      state.hours = Math.floor(distance / 3600)
      distance -= state.hours * 3600

      state.minutes = Math.floor(distance / 60)
      distance -= state.minutes * 60

      state.seconds = distance
    } else {
      state.days = Math.floor(distance / (1000 * 60 * 60 * 24))
      state.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      state.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      state.seconds = Math.floor((distance % (1000 * 60)) / 1000)
    }
  }

  function humanize(n: number) {
    return n > 9 ? n : `0${n}`
  }

  function stop() {
    if (state.intervalId) {
      clearInterval(state.intervalId)
    }

    Object.assign(state, { ...DEFAULT_STATE })
  }

  function run(endDate?: number) {
    stop()

    state.time = endDate

    // Start countdown interval
    state.intervalId = setInterval(update, 1000)
  }

  function onEnded(cb: () => void) {
    onEndedListeners.value.push(cb)
  }

  // Get formatted countdown for display
  const display = computed(() => {
    let output = []

    if (format.days && state.days > 0) {
      output.push(`${state.days}d`)
    }

    if (format.hours && state.hours > 0) {
      output.push(`${state.hours}h`)
    }

    if (format.minutes && state.minutes > 0) {
      output.push(`${humanize(state.minutes)}m`)
    }

    if (format.seconds) {
      output.push(`${humanize(state.seconds)}s`)
    }
    return output.join(' ')
  })

  // When the component is unmounted clear interval
  onUnmounted(stop)

  return {
    run,
    display,
    onEnded,
    ...toRefs(state)
  }
}

export type UseCountDownReturn = ReturnType<typeof useCountdown>
