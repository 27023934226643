<script setup lang="ts">
import ArtworkAuctionCard from '@/components/misc/ArtworkAuctionCard.vue'
import ArtworkFixedPriceCard from '@/components/misc/ArtworkFixedPriceCard.vue'
import ArtworkOpenToOfferCard from '@/components/misc/ArtworkOpenToOfferCard.vue'
import { Nft } from '@/models'
import { computed } from 'vue'

const props = defineProps<{
  nft: Nft
}>()

const isFixedPrice = computed(() => props?.nft?.sale?.saleType === 1)
const isAuction = computed(() => props?.nft?.sale?.saleType === 2)
const isOpenToOffer = computed(() => props?.nft?.sale === null)
</script>

<template>
  <ArtworkOpenToOfferCard v-if="isOpenToOffer" :nft="nft"></ArtworkOpenToOfferCard>
  <ArtworkAuctionCard v-if="isAuction" :nft="nft"></ArtworkAuctionCard>
  <ArtworkFixedPriceCard v-if="isFixedPrice" :nft="nft"></ArtworkFixedPriceCard>
</template>
