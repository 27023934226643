import { createMetaTag } from '@/utils'
import Home from '@/views/Home.vue'
import Marketplace from '@/views/Marketplace.vue'
import { RouteRecordRaw } from 'vue-router'
import { RouteName } from './types'

export const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/',
    name: RouteName.Home,
    component: Home,
    ...createMetaTag({
      title: `THE FIRST MARKETPLACE FOR ART #RWA`,
      description:
        'Our goal is to bring a new and optimistic perspective for the stakeholders of the art market. We aim to provide artists, gallery owners, collectors and whoever taking up interest in art space for more independence, in total transparency.',
      image: `${location.origin}/images/opengraph.jpg`
    })
  },
  {
    path: '/marketplace',
    name: RouteName.Marketplace,
    component: Marketplace,
    ...createMetaTag()
  },
  {
    path: '/artist',
    name: RouteName.Artist,
    component: () => import('@/views/Artist.vue'),
    ...createMetaTag({
      title: `Artrade's open call`,
      description: `Artrade's open call signifies a pivotal moment in NFT evolution, emphasizing the integration of real-world assets into the digital space. Participating artists contribute to reshaping how art is valued and traded in the digital age.`
    })
  },
  {
    path: '/artist-thank-you',
    name: RouteName.ArtistThankYou,
    component: () => import('@/views/ArtistThankYou.vue'),
    ...createMetaTag({
      title: `Thank you`,
      description: `Thank you! We'll be in touch shortly by email`
    })
  },
  {
    path: '/feed',
    name: RouteName.Feed,
    component: () => import('@/views/Feed.vue'),
    redirect: {
      name: RouteName.FeedArtwork
    },
    children: [
      {
        path: 'artworks',
        name: RouteName.FeedArtwork,
        component: () => import('@/views/ArtworkGrid.vue'),
        ...createMetaTag({
          title: 'Artworks | Artrade'
        })
      },
      {
        path: 'collections',
        name: RouteName.FeedCollection,
        component: () => import('@/views/CollectionGrid.vue'),
        ...createMetaTag({
          title: 'Collections | Artrade'
        })
      }
    ]
  },
  {
    path: '/fragments',
    name: RouteName.Fragment,
    component: () => import('@/views/fragments/Main.vue'),
    redirect: {
      name: RouteName.FragmentInvest
    },
    children: [
      {
        path: 'invest',
        name: RouteName.FragmentInvest,
        component: () => import('@/views/fragments/Invest.vue'),
        ...createMetaTag({
          title: 'Fragments | Artrade'
        })
      },
      {
        path: 'trading',
        name: RouteName.FragmentTrading,
        component: () => import('@/views/fragments/Trading.vue'),
        ...createMetaTag({
          title: 'Fragments | Artrade'
        })
      },
      {
        path: 'portfolio',
        name: RouteName.FragmentPortfolio,
        component: () => import('@/views/fragments/Portfolio.vue'),
        ...createMetaTag({
          title: 'Fragments | Artrade'
        })
      }
    ]
  },
  {
    path: '/fragments/:slug',
    name: RouteName.FragmentArtwork,
    component: () => import('@/views/fragments/Artwork.vue'),
    ...createMetaTag({
      title: 'Fragments | Artrade'
    })
  },
  {
    path: '/category/:slug',
    name: RouteName.Category,
    component: () => import('@/views/Category.vue'),
    redirect: {
      name: RouteName.CategoryArtwork
    },
    children: [
      {
        path: 'artworks',
        name: RouteName.CategoryArtwork,
        component: () => import('@/views/ArtworkGrid.vue'),
        ...createMetaTag()
      },
      {
        path: 'collections',
        name: RouteName.CategoryCollection,
        component: () => import('@/views/CollectionGrid.vue'),
        ...createMetaTag()
      }
    ]
  },
  {
    path: '/profile/:id',
    name: RouteName.Profile,
    component: () => import('@/views/Profile.vue'),
    ...createMetaTag()
  },
  {
    path: '/settings',
    name: RouteName.Settings,
    component: () => import('@/views/settings/Settings.vue'),
    ...createMetaTag({
      title: 'Settings | Artrade'
    }),
    redirect: {
      name: RouteName.ProfileSettings
    },
    children: [
      {
        path: '/profile',
        name: RouteName.ProfileSettings,
        component: () => import('@/views/settings/ProfileSettings.vue')
      },
      {
        path: '/notifications',
        name: RouteName.NotificationSettings,
        component: () => import('@/views/settings/NotificationSettings.vue')
      }
    ]
  },
  {
    path: '/orders',
    name: RouteName.Orders,
    component: () => import('@/views/Orders.vue'),
    ...createMetaTag({
      title: 'Orders | Artrade'
    })
  },
  {
    path: '/create',
    name: RouteName.Create,
    component: () => import('@/views/Create.vue'),
    ...createMetaTag({
      title: 'Create an artwork | Artrade'
    }),
    redirect: {
      name: RouteName.CreateWelcome
    },
    children: [
      {
        path: 'welcome',
        name: RouteName.CreateWelcome,
        component: () => import('@/components/create/CreateWelcome.vue'),
        ...createMetaTag({
          title: 'Create an artwork | Artrade'
        })
      },
      {
        path: 'upload',
        name: RouteName.CreateUpload,
        component: () => import('@/components/create/CreateUpload.vue'),
        ...createMetaTag({
          title: 'Create an artwork | Artrade'
        })
      },
      {
        path: 'artwork',
        name: RouteName.CreateArtwork,
        component: () => import('@/components/create/CreateArtwork.vue'),
        ...createMetaTag({
          title: 'Create an artwork | Artrade'
        })
      },
      {
        path: 'royalties',
        name: RouteName.CreateRoyalties,
        component: () => import('@/components/create/CreateRoyalties.vue'),
        ...createMetaTag({
          title: 'Create an artwork | Artrade'
        })
      },
      {
        path: 'sale',
        name: RouteName.CreateSale,
        component: () => import('@/components/create/CreateSale.vue'),
        ...createMetaTag({
          title: 'Create an artwork | Artrade'
        })
      },
      {
        path: 'collection',
        name: RouteName.CreateCollection,
        component: () => import('@/components/create/CreateCollection.vue'),
        ...createMetaTag({
          title: 'Create an artwork | Artrade'
        })
      },
      {
        path: 'summary',
        name: RouteName.CreateSummary,
        component: () => import('@/components/create/CreateSummary.vue'),
        ...createMetaTag({
          title: 'Create an artwork | Artrade'
        })
      }
    ]
  },
  {
    path: '/nft/:id',
    name: RouteName.Nft,
    component: () => import('@/views/Nft.vue'),
    ...createMetaTag()
  },
  {
    path: '/edit-artwork/:id',
    name: RouteName.EditArtwork,
    component: () => import('@/views/EditArtwork.vue'),
    ...createMetaTag()
  },
  {
    path: '/collection/:slug',
    name: RouteName.Collection,
    component: () => import('@/views/Collection.vue'),
    ...createMetaTag()
  },
  {
    path: '/activity',
    name: RouteName.Activity,
    component: () => import('@/views/activity/Activity.vue'),
    redirect: '/activity/auctions',
    ...createMetaTag({
      title: 'Activity | Artrade'
    }),
    children: [
      {
        path: 'offers',
        name: RouteName.ActivityOffer,
        component: () => import('@/views/activity/ActivityOffers.vue'),
        ...createMetaTag({
          title: 'Offers | Artrade'
        })
      },
      {
        path: 'auctions',
        name: RouteName.ActivityAuction,
        component: () => import('@/views/activity/ActivityAuctions.vue'),
        ...createMetaTag({
          title: 'Auctions | Artrade'
        })
      },
      {
        path: 'bookmarks',
        name: RouteName.ActivityBookmark,
        component: () => import('@/views/activity/ActivityBookmarks.vue'),
        ...createMetaTag({
          title: 'Bookmarks | Artrade'
        })
      }
    ]
  },
  {
    path: '/staking',
    name: RouteName.Staking,
    component: () => import('@/views/Staking.vue'),
    ...createMetaTag({
      title: 'Staking | Artrade'
    })
  },
  {
    path: '/messages',
    name: RouteName.Message,
    component: () => import('@/views/Messages.vue'),
    ...createMetaTag({
      title: 'Messages | Artrade'
    })
  },
  {
    path: '/404',
    name: RouteName.PageNotFound,
    component: () => import('@/views/404.vue'),
    ...createMetaTag({
      title: '404 | Artrade'
    })
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]
