<template>
  <Modal v-slot="{ close }">
    <ModalHeader alignItems="center">
      <Heading as="h4" size="md">{{ t('Verify your account') }}</Heading>
    </ModalHeader>
    <ModalBody>
      <Text size="md" color="grey.700" lineHeight="md">{{
        t(
          'Oops! To create an artwork you need to verify your account by connecting your X or Instagram account'
        )
      }}</Text>
    </ModalBody>
    <ModalFooter>
      <Button as="router-link" @click="close" :to="{ name: RouteName.ProfileSettings }" size="lg">{{
        t('Get verified')
      }}</Button>
    </ModalFooter>
  </Modal>
</template>

<script setup lang="ts">
import { RouteName } from '@/router/types'
import { Button, Heading, Modal, ModalBody, ModalFooter, ModalHeader, Text } from '@/theme'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
