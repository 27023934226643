<template>
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5H28C34.6274 0.5 40 5.87258 40 12.5V28.5C40 35.1274 34.6274 40.5 28 40.5H12C5.37258 40.5 0 35.1274 0 28.5V12.5Z"
      fill="#1A1A1A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 27.1001C16.3558 27.1001 13.4016 24.1454 13.4016 20.5001C13.4016 16.8551 16.3558 13.8999 20 13.8999V8.5C13.374 8.5 8.00266 13.8726 8.00266 20.5001C8.00266 27.1274 13.374 32.5 20 32.5C26.6258 32.5 31.9973 27.1274 31.9973 20.5001H26.5984C26.5984 24.1454 23.6442 27.1001 20 27.1001Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.9801 20.5211H26.5785V13.9211H19.9801V20.5211Z"
      fill="white"
    />
  </svg>
</template>
