<template>
  <component class="artrade-icon" :is="Iconoir[name]" :width="size" :height="size"></component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { IconProps } from '../../types'
import { Iconoir } from './Iconoir'

const props = withDefaults(defineProps<IconProps>(), {
  size: 20
})

const size = computed(() => `${props.size}px`)
</script>

<style scoped lang="scss">
.artrade-icon {
  width: v-bind('size');
  height: v-bind('size');
  flex: 0 0 v-bind('size');
}
</style>
