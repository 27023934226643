import { API_URL, PUSHER_APP_KEY } from '@/constants'
import { User } from '@/models'
import { connectedUser } from '@/store/connectedUser'
import { disconnect, publicKey } from '@/wallet-connect-vue/init.ts'
import { PublicKey } from '@solana/web3.js'
import { reactive } from 'vue'
import { useCookies } from 'vue3-cookies'
import backend from '../utils/backend'
import { getUsdcBalance } from '../utils/solana/getUsdcBalance'
import { getAtrBalance } from '../utils/solana/getAtrBalance'
import { connection } from '../utils/solana/initConnection'

const { cookies } = useCookies()

export const user = reactive({
  id: null,
  isSignedUp: false,
  savedBearer: null,
  balance: 0,
  balanceUsdc: 0,
  balanceAtr: 0,
  pusher: null,
  data: {} as User,
  message: {
    unread: 0
  },
  async fetch(save) {
    const { cookies } = useCookies()

    const userInBackend = await backend.get('user/walletAddress/' + publicKey.value)
    this.getBalance(publicKey.value)
    this.getBalanceUsdc(publicKey.value)
    this.getBalanceAtr(publicKey.value)

    if (userInBackend === null || userInBackend.id === undefined) {
      // need to create a user
      this.isSignedUp = false
      this.data = {}
      this.dataWithoutSIgnedMessage = {}
    } else {
      // user already exists
      this.isSignedUp = true
      this.savedBearer = userInBackend.bearer

      if (save) {
        this.data = userInBackend
        connectedUser.connected = true
        connectedUser.slug = this.data.urlName
        connectedUser.setPublicKey(publicKey.value)
        connectedUser.setId(this.data.id)

        const bearer = cookies.get('artrade-bearer')

        this.pusher = new window.Pusher(PUSHER_APP_KEY, {
          cluster: 'eu',
          authEndpoint: API_URL + 'messages/auth',
          auth: {
            headers: {
              authorization: 'Bearer ' + user.data.id,
              'Artrade-Bearer': bearer.bearer,
              'Artrade-UserId': user.data.id
            }
          }
        })

        this.message.unread = await backend.get(`messages/get-unread-count/${this.data.id}`)

        this.pusher
          .subscribe(`private-dmscount-${this.data.id}`)
          .bind('newMessage', async (data) => {
            this.message.unread = data.unreadsDm
          })
      }
    }
  },
  disconnect() {
    disconnect()
    this.data = {}
    this.isSignedUp = false
    this.savedBearer = null
    connectedUser.connected = false
    cookies.remove('wallet-autoconnect')
  },
  /**
   * Check if the user has collect address
   */
  hasCollectAddress() {
    if (
      !this.data.shippingCollectFirstname ||
      !this.data.shippingCollectLastname ||
      !this.data.shippingCollectPhone ||
      !this.data.shippingCollectAddress1
    ) {
      return false
    }

    return true
  },
  displayAddress() {
    if (this.data.walletAddress == undefined) return ''

    const first4 = this.data.walletAddress.slice(0, 4)
    const last4 = this.data.walletAddress.slice(-4)

    return first4 + '...' + last4
  },
  async getBalance(publicKey) {
    const balance = await connection.getBalance(new PublicKey(publicKey))
    this.balance = Math.floor((balance / 1000000000) * 1000) / 1000
  },
  async getBalanceUsdc(publicKey) {
    this.balanceUsdc = await getUsdcBalance(publicKey)
  },
  async getBalanceAtr(publicKey) {
    this.balanceAtr = await getAtrBalance(publicKey)
  },
  isWhitelisted() {
    return user.data.whiteListed === 1
  },
  isVerified() {
    return Boolean(user?.data?.twitterHandle || user?.data?.instagramHandle)
  }
})

/**
 * This is next store :)
 */
const store = {
  state: reactive<{
    user: User
    publicKey: PublicKey
  }>({
    user: null,
    publicKey: null
  }),
  /**
   * @note Here you can get data from state (Modification is prohibed here !)
   */
  getters: {
    /**
     * Get state
     */
    getState() {
      return store.state
    },
    /**
     * Get user whitelist status
     */
    isWhitelistedUser() {
      return Boolean(store.state.user.whiteListed)
    },
    /**
     * Check if user is account verified with social
     */
    isVerified() {
      return store.state.user?.twitterHandle || store.state.user?.instagramHandle
    },
    /**
     * Get short wallet address
     */
    shortWalletAddress() {
      const address = store.state.user.walletAddress

      if (!address) {
        return ''
      }

      return `${address.slice(0, 4)}...${address.slice(-4)}`
    }
  },
  /**
   * @note Here you can modify the state
   */
  actions: {
    setUser(user: User) {
      store.state.user = user
    },
    setPublicKey(key: PublicKey) {
      store.state.publicKey = key
    },
    /**
     * Retrieve public key from wallet
     */
    /* fetchPublicKey() {

      store.state.publicKey = publicKey.value
    }, */
    /**
     * Fetch user from backend
     */
    /* async fetchUser() {
      const user = await backend.get(`user/walletAddress/${store.state.publicKey}`)

      if (!user) {
        return
      }

      store.state.user = user
    }, */

    /**
     * Fetch balance amount with wallet public key
     */
    async getBalance() {
      const walletBalance = await connection.getBalance(store.state.publicKey)
      return Math.floor((walletBalance / 1000000000) * 1000) / 1000
    }
    /**
     * Signin user with public key
     */
    /* async signin() {
      await store.actions.fetchPublicKey()
      await store.actions.fetchBalance()
      await store.actions.fetchUser()
    } */
  }
}

/**
 * Signin
 *
 * 1. wallet.connect();
 * 2.
 */
