import axios from 'axios'

async function getCurrentSolanaPriceFromUsd(usd: number) {
  const response = await axios.get(
    'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd'
  )

  return Math.round((usd / response.data?.solana?.usd) * 10000) / 10000
}

export default {
  getCurrentSolanaPriceFromUsd
}
