<script setup lang="ts">
import {
  Facebook,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalHeader,
  Stack,
  Text,
  Twitter,
  useModalProps
} from '@/theme'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = useModalProps<{
  url: string
}>()

const { t } = useI18n()
const isCopied = ref<boolean>(false)

function copyUrl() {
  isCopied.value = true
  navigator.clipboard.writeText(props.url)

  setTimeout(() => {
    isCopied.value = false
  }, 2000)
}
</script>

<template>
  <Modal>
    <ModalHeader>
      <Heading as="h4" size="md">{{ t('Share link') }}</Heading>
    </ModalHeader>
    <ModalBody>
      <Stack direction="column" :spacing="7">
        <a :href="`http://www.twitter.com/share?url=${props?.url}`" target="_blank">
          <Stack class="artrade-sharebox" alignItems="center" justifyContent="space-between">
            <Stack :spacing="7" alignItems="center">
              <div class="artrade-icon-wrapper">
                <Twitter color="white" />
              </div>
              <Text size="md" fontWeight="semibold">Twitter</Text>
            </Stack>
            <Icon name="arrowRight" />
          </Stack>
        </a>
        <a :href="`https://www.facebook.com/sharer/sharer.php?u=${props?.url}`" target="_blank">
          <Stack class="artrade-sharebox" alignItems="center" justifyContent="space-between">
            <Stack :spacing="7" alignItems="center">
              <div class="artrade-icon-wrapper">
                <Facebook color="white" />
              </div>
              <Text size="md" fontWeight="semibold">Facebook</Text>
            </Stack>
            <Icon name="arrowRight" />
          </Stack>
        </a>
        <Stack
          class="artrade-linkbox"
          alignItems="center"
          justifyContent="space-between"
          @click="copyUrl"
        >
          <Text truncate>{{ props?.url }}</Text>
          <IconButton
            variant="ghost"
            :icon="isCopied ? 'check' : 'copy'"
            v-tooltip="t('Copy URL')"
          />
        </Stack>
      </Stack>
    </ModalBody>
  </Modal>
</template>

<style scoped lang="scss">
.artrade-sharebox {
  border: 1px solid var(--artrade-colors-grey-200);
  box-shadow: var(--artrade-shadows-sm);
  border-radius: var(--artrade-radii-md);
  padding: var(--artrade-space-8) var(--artrade-space-10) var(--artrade-space-8)
    var(--artrade-space-8);

  .artrade-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: var(--artrade-radii-md);
    background-color: var(--artrade-colors-black);
  }
}

.artrade-linkbox {
  border: 1px solid var(--artrade-colors-grey-200);
  box-shadow: var(--artrade-shadows-sm);
  border-radius: var(--artrade-radii-md);
  padding: var(--artrade-space-2) var(--artrade-space-2) var(--artrade-space-2)
    var(--artrade-space-8);
}
</style>
