import backend from '@/utils/backend'

/**
 * Subscribe email to the newsletter
 */
async function subscribe(email: string) {
  return backend.post('user/newsletter', {
    email
  })
}

export default {
  subscribe
}
