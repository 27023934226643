<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#1A1A1A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4 11.9998C14.4 9.68021 12.5196 7.7998 10.2 7.7998C7.8804 7.7998 6 9.68021 6 11.9998C6 14.3194 7.8804 16.1998 10.2 16.1998H14.4L14.4 11.9998Z"
      fill="white"
    />
    <circle cx="16.5" cy="9.2998" r="1.5" fill="white" />
  </svg>
</template>
