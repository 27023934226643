<template>
  <component :is="as" ref="el" class="artrade--animated-text">
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
import { isMobile } from '@/utils'
import { gsap } from 'gsap'
import { type ScrollTrigger } from 'gsap/all'
import SplitType from 'split-type'
import { nextTick, onMounted, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    delay?: gsap.TweenValue
    duration?: gsap.TweenValue
    trigger?: gsap.DOMTarget
    stagger?: gsap.TweenVars['stagger']
    start?: ScrollTrigger.Vars['start']
    end?: ScrollTrigger.Vars['end']
    ease?: gsap.EaseString
    containerAnimation?: gsap.core.Tween
    scrub?: boolean
    markers?: ScrollTrigger['vars']['markers']
    as?: keyof HTMLElementTagNameMap
  }>(),
  {
    delay: 0,
    duration: 2,
    stagger: 0.05,
    start: 'center 75%',
    end: null,
    ease: 'expo',
    as: 'h1',
    scrub: false,
    markers: false
  }
)

const el = ref<HTMLElement>(null!)
const lines = ref<HTMLElement[]>([])
const tween = ref<gsap.core.Tween>(null)
const isMobileDevice = ref(isMobile())

async function animate() {
  gsap.set(lines.value, {
    y: '115%',
    skewX: -6
    // rotate: 4
  })

  await nextTick()

  tween.value = gsap.to(lines.value, {
    y: 0,
    skewX: 0,
    delay: props.delay,
    stagger: props.stagger,
    duration: props.duration,
    ease: 'expo',
    rotate: 0,

    scrollTrigger: {
      markers: props.markers,
      trigger: props.trigger ? props.trigger : el.value,
      start: props.start,
      end: props.end,
      scrub: isMobileDevice.value ? false : props.scrub,
      toggleActions: 'play none none reverse',
      containerAnimation: props.containerAnimation
    }
  })
}

onMounted(() => {
  const instance = SplitType.create(el.value, {
    types: 'lines'
  })

  if (!instance?.lines?.length) return

  lines.value = instance.lines

  for (const line of instance.lines) {
    const wrapper = document.createElement('span')
    wrapper.className = 'overflow--box'
    line.parentNode?.appendChild(wrapper)
    wrapper.appendChild(line)
  }

  animate()
})

watch(
  () => props.containerAnimation,
  () => {
    tween.value?.kill()
    animate()
  }
)

defineExpose({
  el
})
</script>

<style lang="scss">
.artrade--animated-text {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  font-kerning: none;

  .overflow--box {
    display: inline-block;
    overflow: hidden;

    .line {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      will-change: transform;
    }
  }
}
</style>
