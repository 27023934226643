<template>
  <div :class="[classnames, { '-auto-column': autoColumns }]" ref="el">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import type { GridProps } from '../types'
import { getResponsiveValue } from '../utils'

const props = withDefaults(defineProps<GridProps>(), {
  gap: 11,
  gridTemplateColumns: `repeat(4, 1fr)`,
  autoColumns: false
})

const el = ref<HTMLElement>()
const classnames = computed(() => {
  const output = ['artrade-grid']

  if (props.gap) {
    output.push(getResponsiveValue(props.gap, `grid-gap`))
  }

  return output
})

defineExpose({
  el
})
</script>

<style scoped lang="scss">
.artrade-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);

  &.-auto-column {
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
  }

  @media screen and (min-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 996px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: v-bind('gridTemplateColumns');
  }
}
</style>
