<template>
  <div :class="classname">
    <OptimizedImage :src="src" :alt="alt" size="md" />
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import OptimizedImage from '@/components/OptimizedImage.vue'
import { Sizes } from '@/components/types'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    src: string
    alt?: string
    size?: Sizes
    mode?: 'light' | 'dark'
  }>(),
  {
    size: 'md',
    mode: 'light'
  }
)

const classname = computed(() => `artrade-card-image -${props?.size} -${props.mode}`)
</script>

<style scoped lang="scss">
.artrade-card-image {
  position: relative;
  width: 100%;
  background-color: var(--artrade-colors-grey-100);
  border-radius: var(--artrade-radii-md);
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.2s ease;

  &:hover {
    background-color: var(--artrade-colors-grey-200);
  }

  &.-sm {
    height: 240px;
  }

  &.-md {
    height: 380px;

    @media (min-width: 570px) {
      height: 380px;
    }

    @media (min-width: 768px) {
      height: 400px;
    }

    @media (min-width: 1024px) {
      height: 420px;
    }

    @media (min-width: 1920px) {
      height: 520px;
    }
  }

  &.-dark {
    background-color: var(--artrade-colors-grey-1100);
  }

  img {
    max-width: 80%;
    max-height: 80%;
    image-orientation: from-image;
  }
}
</style>
