<template>
  <div class="artrade-picture" ref="wrapper">
    <Transition name="fade">
      <picture ref="picture" :class="classnames" v-if="!isLoading">
        <template v-for="source in sources">
          <source :media="source.media" :srcset="source.srcset" />
        </template>
        <div :class="['artrade-image-placeholder', imgClassnames]" v-if="color"></div>
        <img :class="imgClassnames" :alt="alt" ref="element" v-else />
      </picture>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { ImageSize } from '@/models'
import { cx } from '@/theme'
import { getImageUrlFromUid } from '@/utils'
import { computed, nextTick, ref, watchPostEffect } from 'vue'
import { OptimizedImageProps } from './types'

// Sizes mapping for media queries
const SIZES = {
  [ImageSize.XLarge]: '(min-width: 1440px)',
  [ImageSize.Large]: '(min-width: 1024px)',
  [ImageSize.Medium]: '(min-width: 768px)',
  [ImageSize.Small]: '(min-width: 320px)'
}

const props = withDefaults(defineProps<OptimizedImageProps>(), {
  loading: 'lazy',
  decoding: 'async',
  objectPosition: 'center',
  objectFit: 'contain',
  maxWidth: '80%',
  maxHeight: '80%',
  rounded: false
})

const wrapper = ref<HTMLDivElement>(null)
const picture = ref<HTMLPictureElement>(null)
const element = ref<HTMLImageElement>(null)
const isLoading = ref(true)
const color = ref<string>(getRandomColor())

const hasSize = computed(() => Boolean(props.size))
const hasFileExtension = computed(() => new RegExp(/\.([0-9a-z]+)(?:[\?#]|$)/i).test(props.src))

// Build different set of images with available sizes
const sources = computed(() => {
  if (hasFileExtension.value) {
    return []
  }

  // If size was provided doesn't compute sources (force single size display)
  if (hasSize.value) {
    return []
  }

  return Object.keys(SIZES).map((size) => ({
    media: SIZES[size],
    srcset: `${props.src}@${size}.webp`
  }))
})

const classnames = computed(() =>
  cx('artrade-picture', {
    '-rounded': props.rounded,
    '-squared': props.squared
  })
)

const imgClassnames = computed(() =>
  cx(`object-${props.objectPosition}`, `object-${props.objectFit}`)
)

/**
 * Compute source from prop, if source contains filename with extension return this otherwise computed file url from uid
 */
watchPostEffect(async () => {
  if (!props.src) {
    isLoading.value = false
    color.value = getRandomColor()
    return
  }

  let src = ''

  if (hasFileExtension.value) {
    src = props.src
  } else if (hasSize.value) {
    // If size was provided, force size display
    src = getImageUrlFromUid(props.src, convertToImageSize(props.size))
  } else {
    src = getImageUrlFromUid(props.src)
  }

  isLoading.value = true

  try {
    const url = await load(src)
    isLoading.value = false
    color.value = null

    await nextTick()

    element.value.src = url
  } catch (_) {
    isLoading.value = false
    color.value = getRandomColor()
  } finally {
    isLoading.value = false
  }
})

/**
 * Convert simple variant to ImageSize enum value
 */
function convertToImageSize(size: OptimizedImageProps['size']) {
  if (size === 'xl') {
    return ImageSize.XLarge
  } else if (size === 'lg') {
    return ImageSize.Large
  } else if (size === 'md') {
    return ImageSize.Medium
  } else if (size === 'sm') {
    return ImageSize.Small
  } else {
    return ImageSize.XSmall
  }
}

function load(src: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = () => {
      resolve(img.src)
    }

    img.onerror = () => {
      reject()
    }

    img.src = src
  })
}

function getRandomColor() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`
}
</script>

<style scoped lang="scss">
.artrade-picture {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.-rounded,
  &.-squared {
    overflow: hidden;
  }

  &.-rounded {
    border-radius: var(--artrade-radii-full);
  }

  &.-squared {
    border-radius: var(--artrade-radii-md);
  }

  .artrade-image-placeholder {
    width: 100%;
    height: 100%;
    max-height: v-bind('maxHeight');
    max-width: v-bind('maxWidth');
    background-color: var(--artrade-colors-grey-100);
  }

  img {
    width: 100%;
    height: 100%;
    max-height: v-bind('maxHeight');
    max-width: v-bind('maxWidth');

    &.object-top {
      object-position: top;
    }

    &.object-left {
      object-position: left;
    }

    &.object-right {
      object-position: right;
    }

    &.object-bottom {
      object-position: bottom;
    }

    &.object-center {
      object-position: center;
    }

    &.object-cover {
      object-fit: cover;
    }

    &.object-contain {
      object-fit: contain;
    }

    &.object-fill {
      object-fit: fill;
    }

    &.object-none {
      object-fit: none;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 1s 0.4s cubic-bezier(0.16, 1, 0.3, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
}
</style>
