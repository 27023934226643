<script setup lang="ts">
import SearchMobile from '@/components/SearchMobile.vue'
import Brand from '@/components/design/Brand.vue'
import NavbarAvatar from '@/components/design/navbar/NavbarAvatar.vue'
import Search from '@/components/design/navbar/search/Search.vue'
import { Button, Divider, IconButton, Stack, Tag, Text, useBottomSheet, useModal } from '@/theme'
import { RouterLink } from 'vue-router'

import CreateButton from '@/components/CreateButton.vue'
import { user } from '@/store/user'
import { computed, onMounted, onUnmounted, reactive, ref } from 'vue'

import { RouteName } from '@/router/types'
import { useI18n } from 'vue-i18n'

import { NavbarMode, useApp } from '@/composables/useApp'

import { formatNumber, isMobile } from '@/utils'
import spotPriceAtr from '@/utils/solana/spotPriceAtr'
import gsap from 'gsap'
import OptimizedAvatar from './OptimizedAvatar.vue'
import ProtectedButton from './ProtectedButton.vue'
import {
  ConnectedMenuBottomSheet,
  UnconnectedMenuBottomSheet,
  UserMenuBottomSheet
} from './bottomSheets'
import { PurchaseATRModal, SigninModal } from './modals'

const app = useApp()
const modal = useModal()
const bottomSheet = useBottomSheet()
const { t } = useI18n()
const element = ref<HTMLElement>()
const isMobileDevice = ref(isMobile())
const isMinimal = computed(() => app.navbar.getState().value === NavbarMode.Minimal)
const isFull = computed(() => app.navbar.getState().value === NavbarMode.Full)
const isSimple = computed(() => app.navbar.getState().value === NavbarMode.Simple)
const isConnected = computed(() => user.isSignedUp && user.data.id)
const scroll = reactive({
  top: true,
  last: 0,
  animation: null
})
const ATRSpotPrice = ref(0)

onMounted(async () => {
  window.addEventListener('resize', () => {
    isMobileDevice.value = isMobile()
  })

  scroll.animation = gsap
    .from(element.value, {
      y: '-100%',
      paused: true,
      duration: 0.2
    })
    .progress(1)

  window.addEventListener('scroll', animateOnScroll)

  ATRSpotPrice.value = await spotPriceAtr.get()
})

onUnmounted(() => {
  window.removeEventListener('resize', () => {
    isMobileDevice.value = isMobile()
  })

  window.removeEventListener('scroll', animateOnScroll)

  scroll.animation?.kill()
})

function animateOnScroll() {
  if (window.scrollY <= 0) {
    scroll.top = true

    gsap.to(element.value, {
      css: {
        backgroundColor: 'rgba(255, 255, 255, 0)'
      },
      duration: 0.6,
      ease: 'expo'
    })
  } else if (window.scrollY >= 40 && scroll.top) {
    scroll.top = false

    gsap.to(element.value, {
      css: {
        backgroundColor: 'rgba(255, 255, 255, 1)'
      },
      duration: 0.6,
      ease: 'expo'
    })
  }

  if (window.scrollY > scroll.last) {
    scroll.animation.reverse()
  } else {
    scroll.animation.play()
  }

  scroll.last = window.scrollY < 0 ? 0 : window.scrollY
}

function signin() {
  modal.open(SigninModal)
}

function buy() {
  modal.open(PurchaseATRModal)
}

function openUnconnectedMenu() {
  bottomSheet.open(UnconnectedMenuBottomSheet)
}

function openConnectedMenu() {
  bottomSheet.open(ConnectedMenuBottomSheet)
}

function openUserMenu() {
  bottomSheet.open(UserMenuBottomSheet)
}

const avatar = computed(() => user.data.avatarUrlUid)
const currentATRPrice = computed(() =>
  formatNumber(ATRSpotPrice.value, {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3
  })
)
</script>

<template>
  <header :class="['artrade-navbar', { '-minimal': isMinimal }]" ref="element">
    <nav>
      <Stack :spacing="8" flex>
        <RouterLink :to="{ name: RouteName.Home }">
          <Brand />
        </RouterLink>
        <template v-if="isFull">
          <Search />
        </template>
      </Stack>
      <Stack justifyContent="end" :spacing="5">
        <Stack :spacing="0" v-if="!isMobileDevice">
          <RouterLink :to="{ name: RouteName.Marketplace }">
            <Button variant="ghost">{{ t('Marketplace') }}</Button>
          </RouterLink>
          <RouterLink :to="{ name: RouteName.Feed }">
            <Button variant="ghost">{{ t('Explore') }}</Button>
          </RouterLink>
          <RouterLink :to="{ name: RouteName.Fragment }">
            <Button variant="ghost"
              >{{ t('Fragments') }}
              <Tag rounded alignSelf="center" size="xs" variant="primary">{{
                t('NEW')
              }}</Tag></Button
            >
          </RouterLink>
          <template v-if="isConnected">
            <CreateButton variant="ghost" />
            <RouterLink :to="{ name: RouteName.Activity }">
              <Button variant="ghost">{{ t('Activity') }}</Button>
            </RouterLink>
            <RouterLink :to="{ name: RouteName.Message }">
              <Button variant="ghost"
                >{{ t('Messages') }}
                <Stack class="notification-indicator" v-if="user.message.unread > 0"
                  ><Text size="xs" color="white">{{ user.message.unread }}</Text></Stack
                ></Button
              >
            </RouterLink>
          </template>
          <template v-else>
            <Button variant="ghost" @click="signin" data-testid="navbar-signin-button">{{
              t('Sign in')
            }}</Button>
          </template>
        </Stack>

        <template v-if="isConnected">
          <template v-if="isMobileDevice">
            <Stack :spacing="5">
              <OptimizedAvatar size="md" :src="avatar" @click="openUserMenu" />
              <SearchMobile />

              <IconButton icon="menu" variant="outline" @click="openConnectedMenu"
            /></Stack>
          </template>
          <template v-else>
            <Stack :spacing="5">
              <ProtectedButton @click="buy" data-testid="navbar-buy-atr-button"
                >{{ t('Buy ATR') }}<Divider color="grey.1000" vertical />{{
                  currentATRPrice
                }}</ProtectedButton
              >
              <NavbarAvatar />
            </Stack>
          </template>
        </template>
        <template v-else>
          <Stack :spacing="5">
            <template v-if="isMobileDevice">
              <SearchMobile />

              <IconButton icon="menu" variant="outline" @click="openUnconnectedMenu" />
            </template>
            <template v-else>
              <Button variant="outline" as="router-link" :to="{ name: RouteName.Artist }">{{
                t('Apply as an artist')
              }}</Button>
              <ProtectedButton
                rightIcon="arrowRight"
                @click="buy"
                data-testid="navbar-buy-atr-button"
                >{{ t('Buy ATR') }}</ProtectedButton
              >
            </template>
          </Stack>
        </template>
      </Stack>
    </nav>
  </header>
</template>

<style scoped lang="scss">
.artrade-navbar {
  position: sticky;
  top: 0;
  z-index: 1020;
  background-color: rgba(255, 255, 255, 0);
  width: 100%;

  &.-minimal {
    position: fixed;
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1920px;
    padding: 0 var(--artrade-space-7);
    height: 80px;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
      padding: 0 var(--artrade-space-15);
    }
  }
}
</style>
