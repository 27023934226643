<template>
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5H28C34.6274 0.5 40 5.87258 40 12.5V28.5C40 35.1274 34.6274 40.5 28 40.5H12C5.37258 40.5 0 35.1274 0 28.5V12.5Z"
      fill="#1A1A1A"
    />
    <path
      d="M31.6071 23.5396L26.4253 14.5429C25.2944 12.6693 22.5092 12.6524 21.4121 14.6273L15.9769 23.9784C14.9642 25.7001 16.2132 27.8438 18.2556 27.8438H29.1766C31.219 27.8607 32.755 25.6326 31.6071 23.5396Z"
      fill="#A2A2A2"
    />
    <path
      d="M24.1297 24.2485L23.809 23.6914C23.5051 23.1682 22.8468 22.0542 22.8468 22.0542L18.4582 14.4247C17.3273 12.7368 14.6266 12.5849 13.4957 14.7285L8.38125 23.5733C7.31786 25.43 8.58381 27.8438 10.8794 27.8606H21.7328H26.3577H29.1091C26.3071 27.8775 25.4125 26.409 24.1297 24.2485Z"
      fill="white"
    />
    <path
      d="M24.1296 24.2147L23.8089 23.6577C23.5051 23.1345 22.8468 22.0204 22.8468 22.0204L20.0111 17.0242L15.9431 23.9784C14.9304 25.7001 16.1794 27.8438 18.2219 27.8438H21.7159H26.3408H29.1091C26.2902 27.8269 25.4125 26.3922 24.1296 24.2147Z"
      fill="#D2D2D2"
    />
  </svg>
</template>
