<template>
  <RouterLink :to="{ name: RouteName.Create }" v-if="userIsWhitelisted && userIsVerified">
    <Button :variant="variant">{{ t('Create') }}</Button>
  </RouterLink>
  <Button :variant="variant" @click="applyModalIsOpen = true" v-else-if="!userIsWhitelisted">{{
    t('Create')
  }}</Button>
  <Button :variant="variant" @click="verfiedModalIsOpen = true" v-else>{{ t('Create') }}</Button>
  <Modal :isOpen="applyModalIsOpen" @onClose="applyModalIsOpen = false">
    <ModalContent>
      <ModalHeader>
        <Heading as="h4" size="md">{{ t('Apply as an artist') }}</Heading>
      </ModalHeader>
      <ModalBody>
        <Text size="md" color="grey.700" lineHeight="md">{{
          t(`Oups! It looks like you don't have access to our creative tools.`)
        }}</Text>
        <Text size="md" color="grey.700" lineHeight="md">{{
          t(
            `If you're an artist and want to join the party, click the button below to apply and get access to all the fun.`
          )
        }}</Text>
      </ModalBody>
      <ModalFooter>
        <Button
          as="a"
          href="https://application-artrade.typeform.com/apply-artist"
          target="_blank"
          size="lg"
          >{{ t('Apply as an artist') }}</Button
        >
      </ModalFooter>
    </ModalContent>
  </Modal>
  <Modal :isOpen="verfiedModalIsOpen" @onClose="verfiedModalIsOpen = false">
    <ModalContent>
      <ModalHeader>
        <Heading as="h4" size="md">{{ t('Verify your account') }}</Heading>
      </ModalHeader>
      <ModalBody>
        <Text size="md" color="grey.700" lineHeight="md">{{
          t(
            'Oops! To create an artwork you need to verify your account by connecting your X or Instagram account'
          )
        }}</Text>
      </ModalBody>
      <ModalFooter>
        <Button
          as="router-link"
          @click="verfiedModalIsOpen = false"
          :to="{ name: RouteName.ProfileSetting }"
          size="lg"
          >{{ t('Get verified') }}</Button
        >
      </ModalFooter>
    </ModalContent>
  </Modal>
</template>

<script setup lang="ts">
import Button from '@/components/design/Button.vue'
import Heading from '@/components/design/Heading.vue'
import Text from '@/components/design/Text.vue'
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@/components/modal'
import { RouteName } from '@/router/types'
import { user } from '@/store/user'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'
import { Variants } from './types'

const { t } = useI18n()

withDefaults(
  defineProps<{
    variant?: Variants
  }>(),
  {
    variant: 'primary'
  }
)

const applyModalIsOpen = ref(false)
const verfiedModalIsOpen = ref(false)
const userIsWhitelisted = computed(() => user.isWhitelisted())
const userIsVerified = computed(() => user.isVerified())
</script>
