import { Nft } from '@/models'
import backend from '@/utils/backend'

async function getLastAuctions() {
  return backend.get('home/last-auctions')
}

async function getLastOffers() {
  return backend.get('home/last-open-to-offers')
}

async function getLastFixedPrices() {
  return backend.get('home/last-fixed-price')
}

async function getFeatured(): Promise<Nft> {
  return backend.get('home/featured')
}

async function getShowcaseArtworks(): Promise<Partial<Nft>[]> {
  return backend.get('home/showcase')
}

export default {
  getLastAuctions,
  getLastOffers,
  getLastFixedPrices,
  getFeatured,
  getShowcaseArtworks
}
