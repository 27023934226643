<template>
  <svg
    :width="size"
    :height="size"
    :viewBox="`0 0 ${size} ${size}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6829 4.6875H19.1639L13.7437 10.8825L20.1202 19.3125H15.1274L11.2169 14.1997L6.74243 19.3125H4.25993L10.0574 12.6862L3.94043 4.6875H9.05993L12.5947 9.36075L16.6829 4.6875ZM15.8122 17.8275H17.1869L8.31293 6.0945H6.83768L15.8122 17.8275Z"
      :fill="color"
    />
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue'
const props = withDefaults(
  defineProps<{
    color?: string
    size?: number
  }>(),
  {
    color: 'black',
    size: 24
  }
)

const color = computed(() => `var(--artrade-colors-${props?.color}`)
</script>
<style></style>
