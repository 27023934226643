<template>
  <component :is="as" class="artrade-brand">
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.3992 21.152C29.3997 21.101 29.4 21.05 29.4 20.9988C29.4 12.8802 22.8186 6.29883 14.7 6.29883C6.58141 6.29883 0 12.8802 0 20.9988C0 29.1172 6.58099 35.6984 14.6992 35.6988V35.699H29.3992V21.152Z"
        fill="black"
      />
      <circle cx="36.75" cy="11.5488" r="5.25" fill="black" />
    </svg>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    color?: string
    as?: keyof HTMLElementTagNameMap
  }>(),
  {
    color: 'black',
    as: 'div'
  }
)

const color = computed(() => `var(--artrade-colors-${props.color})`)
</script>

<style scoped>
.artrade-brand {
  display: block;
  width: 42px;
  height: 42px;
  color: v-bind('color');
}
</style>
