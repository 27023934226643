export type MetaTagOptions = {
  title?: string
  description?: string
  image?: string
}

export function createMetaTag(opts: MetaTagOptions = {}) {
  const {
    title = 'Artrade | The first NFT marketplace for physical art',
    description = 'Create, buy and sell artworks without intermediaries thanks to our REAL protocol',
    image = `${location.origin}/opengraph.jpg`
  } = opts

  return {
    meta: {
      title,
      description,
      image,
      metaTags: {
        'twitter:card': 'summary_large_image',
        'twitter:title': title,
        'twitter:description': description,
        'twitter:image': image,
        'og:title': title,
        'og:description': description,
        'og:image': image,
        'og:type': 'website',
        keywords:
          'Marketplace, NFT, Artwork, Collection, Artist, Painting, Drawing, Colors, Gallery, Create, Sell, Buy, REAL'
      }
    }
  }
}
