<template>
  <Stack :spacing="2" as="label"
    ><slot></slot>
    <span class="optional" v-if="ctx && !ctx.isRequired">{{ t('(Optional)') }}</span>
  </Stack>
</template>

<script setup lang="ts">
import { inject } from 'vue'
import { useI18n } from 'vue-i18n'
import Stack from '../Stack.vue'
import { FormControlProvider, type FormControlContext } from './types'

const { t } = useI18n()
const ctx = inject<FormControlContext>(FormControlProvider)
</script>

<style scoped lang="scss">
label {
  display: flex;
  font-weight: var(--artrade-fontWeights-semibold);
  font-size: var(--artrade-fontSizes-sm);
  gap: var(--artrade-space-2);
  line-height: var(--artrade-lineHeights-xs);

  .optional {
    color: var(--artrade-colors-grey-500);
  }
}
</style>
