<template>
  <Stack
    class="artrade-checkbox"
    :data-checked="checked"
    :data-disabled="isDisabled"
    :data-readonly="isReadonly"
  >
    <input
      type="checkbox"
      :readonly="isReadonly"
      :required="isRequired"
      :aria-readonly="isReadonly ? true : undefined"
      :aria-required="isRequired ? true : undefined"
      :checked="checked"
      ref="inputRef"
    />
    <Stack
      @click="toggle"
      direction="row"
      alignItems="center"
      :spacing="8"
      class="artrade-checkbox-container"
      :data-disabled="isDisabled"
      :data-readonly="isReadonly"
    >
      <div
        :data-checked="checked"
        :data-disabled="isDisabled"
        :data-readonly="isReadonly"
        class="artrade-checkbox-control"
      >
        <Icon v-if="checked" name="check" :size="16" />
      </div>
      <Text class="artrade-checkbox-label" :color="isDisabled || isReadonly ? 'grey.700' : 'black'"
        ><slot></slot
      ></Text>
    </Stack>
  </Stack>
</template>

<script setup lang="ts">
import Icon from '@/components/design/Icon.vue'
import Stack from '@/components/design/Stack.vue'
import Text from '@/components/design/Text.vue'
import { ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    isDisabled?: boolean
    isRequired?: boolean
    isReadonly?: boolean
    isInvalid?: boolean
    modelValue?: boolean
    isChecked?: boolean
  }>(),
  {
    isDisabled: undefined,
    isRequired: undefined,
    isReadonly: undefined,
    isInvalid: undefined,
    isChecked: false,
    modelValue: false
  }
)

const checked = ref(props.modelValue || props.isChecked)
const inputRef = ref<HTMLInputElement>(null)

defineExpose({
  inputRef
})

const emits = defineEmits<{
  (e: 'change', value: boolean): void
  (e: 'update:modelValue', value: boolean): void
}>()

function toggle() {
  checked.value = !checked.value
  emits('update:modelValue', checked.value)
  emits('change', checked.value)
}

watch(
  () => props.modelValue,
  (current) => {
    checked.value = current
  }
)

watch(
  () => props.isChecked,
  (current) => {
    checked.value = current
  }
)
</script>

<style scoped lang="scss">
.artrade-checkbox {
  &[data-disabled],
  &[data-readonly] {
    cursor: not-allowed;
  }

  input[type='checkbox'] {
    border: 0px;
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0px;
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
    appearance: none;
  }

  .artrade-checkbox-container {
    &[data-readonly],
    &[data-disabled] {
      pointer-events: none;
    }

    .artrade-checkbox-control {
      width: 24px;
      height: 24px;
      border-radius: var(--artrade-radii-xs);
      box-shadow: var(--artrade-shadows-sm);
      border: 1px solid var(--artrade-colors-grey-200);
      background-color: var(--artrade-colors-white);
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(1);
      transition: all 0.2s ease;
      cursor: pointer;

      :deep(.artrade-icon) {
        color: var(--artrade-colors-white);
      }

      &:hover {
        &[data-checked='true'] {
          background-color: var(--artrade-colors-grey-1100);
        }
      }

      &:active {
        transform: scale(1.06);
      }

      &[data-checked='true'] {
        background-color: var(--artrade-colors-black);
        border-color: var(--artrade-colors-black);
      }

      &[data-readonly],
      &[data-disabled] {
        background-color: var(--artrade-colors-grey-100);
        border: 1px solid var(--artrade-colors-grey-200);
        box-shadow: none;
        pointer-events: none;

        :deep(.artrade-icon) {
          color: var(--artrade-colors-grey-600);
        }
      }
    }

    .artrade-checkbox-label {
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
