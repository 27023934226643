import { metaTagPlugin } from '@m-media/vue3-meta-tags'
import * as Sentry from '@sentry/vue'
import { VueQueryPlugin } from '@tanstack/vue-query'
import * as buffer from 'buffer'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { createPinia } from 'pinia'
import Pusher from 'pusher-js'
import 'solana-wallets-vue/styles.css'
import { createApp } from 'vue'
import App from './App.vue'
import { Tooltip } from './directives'
import i18n from './i18n'
import router from './router'

declare global {
  interface Window {
    Pusher: typeof Pusher
  }
}

window.Buffer = buffer.Buffer
window.Pusher = Pusher

gsap.config({
  force3D: true
})

gsap.registerPlugin(ScrollTrigger)

const app = createApp(App)

app
  .use(i18n)
  .use(VueQueryPlugin, {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnMount: false
        }
      }
    }
  })
  .use(createPinia())
  .use(router)
  .use(
    metaTagPlugin,
    {
      defaultLocale: i18n.global.locale.value,
      textCallback: (text: string) => {
        return i18n.global.t(text)
      }
    },
    router
  )
  .directive('tooltip', Tooltip)
  .mount('#app')

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: import.meta.env.DEV ? 1.0 : 0.5
  })
}
