import { Component, markRaw, mergeProps, reactive, ref, toRefs } from 'vue'
import { ComponentProps } from '../../../types'
import type {
  ModalConfig,
  ModalConfigEvents,
  ModalProps,
  ModalStore,
  UseModalStoreReturn
} from '../types'

const INITIAL_PROPS: ModalProps = {
  size: 'md',
  closeable: true
}

const store = reactive<ModalStore>({
  modals: []
})

function createConfig<C extends Component, P extends ComponentProps>(opts: {
  component: C
  props?: P
  modalProps?: ModalProps
}) {
  const props = reactive(opts.props)
  const isOpen = ref(true)
  const index = Date.now()
  const events = reactive({
    onClose() {}
  } as ModalConfigEvents)
  const modalProps = reactive({
    ...INITIAL_PROPS,
    ...opts.modalProps
  })

  function close<T>(payload?: T) {
    isOpen.value = false

    events.onClose<T>?.(payload)

    store.modals = store.modals.filter((modal: ModalConfig) => {
      return modal.index !== index
    })
  }

  function onClose<T>(cb: (payload?: T) => void) {
    events.onClose<T> = cb
  }

  function updateProps(newProps: Partial<P>) {
    Object.assign(props, mergeProps(props, newProps))
  }

  return reactive({
    component: markRaw(opts.component),
    props,
    isOpen,
    index,
    events,
    modalProps,
    close,
    onClose,
    updateProps
  }) as ModalConfig
}

export function useModalStore(): UseModalStoreReturn {
  function open<C extends Component, P extends ComponentProps>(
    component: C,
    props?: P,
    modalProps?: ModalProps
  ) {
    const config = createConfig({
      component,
      props,
      modalProps
    })

    store.modals = [...store.modals, config]

    return config
  }

  function closeAll() {
    store.modals = []
  }

  return {
    ...toRefs(store),
    open,
    closeAll
  }
}
