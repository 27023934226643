<template>
  <Stack
    :spacing="10"
    direction="column"
    :as="as"
    :class="classnames"
    role="dialog"
    tabindex="-1"
    aria-modal="true"
    v-bind="$attrs"
  >
    <slot></slot>
  </Stack>
</template>

<script setup lang="ts">
import Stack from '@/components/design/Stack.vue'
import { computed, inject } from 'vue'
import { ModalProvider, type ModalContext } from './types'

withDefaults(
  defineProps<{
    as?: keyof HTMLElementTagNameMap
  }>(),
  {
    as: 'section'
  }
)

const ctx = inject<ModalContext>(ModalProvider)

const classnames = computed(() => {
  const output = ['artrade-modal-content']

  output.push(`-${ctx.size}`)

  return output
})
</script>

<style scoped lang="scss">
.artrade-modal-content {
  position: relative;
  width: 100%;
  border-radius: var(--artrade-radii-xl);
  background-color: var(--artrade-colors-white);
  z-index: 9999;
  box-shadow: var(--artrade-shadows-lg);
  max-width: var(--artrade-sizes-md);
  padding: var(--artrade-space-12) 0;
  transition: all 0.3s ease;
  margin: auto;
  max-height: 90vh;
  pointer-events: all;
  overflow: auto;

  &.-sm {
    max-width: var(--artrade-modal-sm);
  }

  &.-md {
    max-width: var(--artrade-modal-md);
  }

  &.-lg {
    max-width: var(--artrade-modal-lg);
  }

  &.-xl {
    max-width: var(--artrade-modal-xl);
  }

  &.\-2xl {
    max-width: var(--artrade-modal-2xl);
  }

  &.\-3xl {
    max-width: var(--artrade-modal-3xl);
  }

  &.\-4xl {
    max-width: 100%;

    @media (min-width: 768px) {
      max-width: 90%;
    }

    @media (min-width: 1920px) {
      max-width: var(--artrade-modal-4xl);
    }
  }

  @media screen and (max-width: 768px) {
    overflow: auto;
    max-width: calc(100% - var(--artrade-space-9)) !important;
    padding: 0;
  }
}
</style>
