/**
 * Upload allow you to upload file without create manually a file input
 */

function createInput(accept: string, multiple = false) {
  const input = document.createElement('input')

  input.type = 'file'
  input.multiple = multiple
  input.accept = accept

  Object.assign(input.style, {
    position: 'fixed',
    top: '-9999px',
    left: '-9999px',
    opacity: 0
  })

  document.body.appendChild(input)

  return input
}

function getFileUrl(file: File): Promise<string> {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.onload = async () => {
      resolve(reader.result.toString())
    }

    reader.onerror = () => {
      reject(reader.error)
    }

    reader.readAsDataURL(file)
  })
}

export type UploadOptions = {
  accept?: string
  maxSize?: number
}

export class UploadEmptyError extends Error {
  constructor(message: string = '') {
    super(message)
    this.name = this.constructor.name
  }
}

export class UploadMaxSizeError extends Error {
  constructor(message: string = '') {
    super(message)
    this.name = this.constructor.name
  }
}

export function upload(opts: UploadOptions = {}): Promise<{ file: File; url: string }> {
  const {
    accept = '.png, .jpg, .jpeg, .gif',

    // Max 100MB
    maxSize = 100 * 1024 * 1024
  } = opts

  return new Promise((resolve, reject) => {
    const input = createInput(accept)

    input.onchange = async () => {
      try {
        const file = input.files[0]

        if (!file) {
          throw new UploadEmptyError('No file provided')
        }

        if (file.size > maxSize) {
          throw new UploadMaxSizeError()
        }

        const url = await getFileUrl(file)

        resolve({
          file,
          url
        })
      } catch (err) {
        reject(err)
      } finally {
        input.remove()
      }
    }

    input.click()
  })
}

export function uploadMultiple(opts: UploadOptions = {}): Promise<{ file: File; url: string }[]> {
  const { accept = '.png, .jpg, .jpeg, .gif' } = opts

  return new Promise((resolve, reject) => {
    const input = createInput(accept, true)

    input.onchange = async () => {
      try {
        const files = input.files

        if (!files?.length) {
          resolve(null)
          return
        }

        const output = []

        for await (const file of files) {
          const url = await getFileUrl(file)

          output.push({
            file,
            url
          })
        }

        resolve(output)
      } catch (err) {
        reject(err)
      } finally {
        input.remove()
      }
    }

    input.click()
  })
}
