<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5H28C34.6274 0.5 40 5.87258 40 12.5V28.5C40 35.1274 34.6274 40.5 28 40.5H12C5.37258 40.5 0 35.1274 0 28.5V12.5Z"
      fill="#1A1A1A"
    />
    <path
      d="M10.8399 30.2297C13.9025 30.2297 16.2042 27.6363 17.5777 25.587C17.4106 26.0404 17.3178 26.4938 17.3178 26.9291C17.3178 28.126 18.0232 28.9784 19.4153 28.9784C21.3271 28.9784 23.3689 27.3462 24.4269 25.587C24.3526 25.8409 24.3155 26.0767 24.3155 26.2943C24.3155 27.1285 24.7981 27.6545 25.7818 27.6545C28.8816 27.6545 32 22.3045 32 17.6255C32 13.9803 30.1066 10.7703 25.355 10.7703C17.0023 10.7703 8 20.7086 8 27.1285C8 29.6494 9.3921 30.2297 10.8399 30.2297ZM22.4779 17.2265C22.4779 16.3198 22.9976 15.685 23.7587 15.685C24.5011 15.685 25.0208 16.3198 25.0208 17.2265C25.0208 18.1333 24.5011 18.7862 23.7587 18.7862C22.9976 18.7862 22.4779 18.1333 22.4779 17.2265ZM26.4501 17.2265C26.4501 16.3198 26.9698 15.685 27.7308 15.685C28.4732 15.685 28.993 16.3198 28.993 17.2265C28.993 18.1333 28.4732 18.7862 27.7308 18.7862C26.9698 18.7862 26.4501 18.1333 26.4501 17.2265Z"
      fill="white"
    />
  </svg>
</template>
