<script setup lang="ts">
import { BottomSheetProvider, ModalProvider } from '@/theme'
import { IconoirProvider } from '@iconoir/vue'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import Footer from './components/Footer.vue'
import Navbar from './components/Navbar.vue'
import { createAppContext } from './composables/useApp'

const ctx = createAppContext()
const { locale } = useI18n()
const route = useRoute()

onMounted(() => {
  /**
   * If lang is provided into the url like https://artrade.app/artist?lang=fr change selected language
   */
  setTimeout(() => {
    if (route.query?.lang) {
      const language = route.query.lang as string
      if (['fr', 'en'].includes(language)) {
        locale.value = language
      }
    }
  })
})
</script>

<template>
  <IconoirProvider
    :icon-props="{
      'stroke-width': 2,
      width: 20,
      height: 20
    }"
  >
    <navbar></navbar>
    <RouterView />
    <Footer v-if="ctx.footer.getState().value"></Footer>
    <ModalProvider />
    <BottomSheetProvider />
  </IconoirProvider>
</template>

<style lang="scss">
@import './theme/styles/index.scss';
</style>
