<template>
  <div class="artrade-spacer"></div>
</template>

<style scoped>
.artrade-spacer {
  flex: 1 1 0%;
  place-self: stretch;
}
</style>
