<template>
  <Modal size="sm">
    <ModalHeader direction="column" alignItems="center">
      <Spinner size="lg" thickness="3px" />
      <Heading align="center" as="h4" size="md">{{ t('Waiting confirmation') }}</Heading>
    </ModalHeader>
    <ModalBody justifyContent="center">
      <Text lineHeight="md" color="grey.700" align="center">{{
        t('Confirm this transaction in your wallet')
      }}</Text>
    </ModalBody>
  </Modal>
</template>

<script setup lang="ts">
import { Heading, Modal, ModalBody, ModalHeader, Spinner, Text } from '@/theme'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
