import artist from './artist'
import collection from './collection'
import material from './material'
import medium from './medium'
import newsletter from './newsletter'
import nft from './nft'
import spot from './spot'
import tag from './tag'
import user from './user'

export default {
  nft,
  artist,
  user,
  newsletter,
  collection,
  tag,
  spot,
  medium,
  material
}
