<template>
  <Modal>
    <ModalHeader alignItems="center">
      <Heading as="h4" size="md">{{ t('Buy ATR') }}</Heading>
    </ModalHeader>
    <ModalBody>
      <Text lineHeight="md" color="grey.700">{{
        t('There are several ways to buy ATR, convert your tokens or buy on an exchange.')
      }}</Text>
      <Stack :spacing="7" direction="column">
        <template v-for="option in options" :key="option.type">
          <BoxButton flex @click="option.action">
            <Stack :spacing="7" alignItems="center">
              <Swap v-if="option.type === BuyOption.Swap" />
              <Mexc v-if="option.type === BuyOption.Mexc" />
              <GateIo v-if="option.type === BuyOption.GateIo" />
              <Bitmart v-if="option.type === BuyOption.Bitmart" />
              <Bitget v-if="option.type === BuyOption.Bitget" />
              <Stack :spacing="3" direction="column">
                <Text size="md">{{ option.title }}</Text>
                <Text size="xs" color="grey.600">
                  {{ option.description }}
                </Text>
              </Stack>
            </Stack>
            <Icon name="arrowRight" :size="16" color="black" />
          </BoxButton>
        </template>
      </Stack>
    </ModalBody>
  </Modal>
</template>

<script setup lang="ts">
import {
  Bitget,
  Bitmart,
  BoxButton,
  GateIo,
  Heading,
  Icon,
  Mexc,
  Modal,
  ModalBody,
  ModalHeader,
  Stack,
  Swap,
  Text,
  useModal
} from '@/theme'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import BuyATRModal from './BuyATRModal.vue'

enum BuyOption {
  Swap,
  GateIo,
  Mexc,
  Bitmart,
  Bitget
}

const { t } = useI18n()
const modal = useModal()

const options = ref([
  {
    type: BuyOption.Swap,
    title: t('Swap'),
    description: t('Convert your tokens directly in app.'),
    action() {
      modal.open(BuyATRModal)
    }
  },
  {
    type: BuyOption.Mexc,
    title: t('Buy on Mexc'),
    description: t('Sign in to the exhange and buy it.'),
    action() {
      window.open('https://www.mexc.com/exchange/ATR_USDT', '_blank')
    }
  },
  {
    type: BuyOption.GateIo,
    title: t('Buy on Gate.io'),
    description: t('Sign in to the exhange and buy it.'),
    action() {
      window.open('https://www.gate.io/trade/ATR_USDT', '_blank')
    }
  },
  /* {
    type: BuyOption.Bitmart,
    title: t('Buy on Bitmart'),
    description: t('Sign in to the exhange and buy it.'),
    action() {
      window.open('https://www.bitmart.com/trade/en-US?symbol=ATR_USDT', '_blank')
    }
  }, */
  {
    type: BuyOption.Bitget,
    title: t('Buy on Bitget'),
    description: t('Sign in to the exhange and buy it.'),
    action() {
      window.open('https://www.bitget.com/fr/spot/ATRUSDT?type=spot', '_blank')
    }
  }
])
</script>
