<template>
  <component type="button" :is="as" :disabled="isDisabled" :class="classnames" ref="el">
    <template v-if="isLoading">
      <Spinner></Spinner>
    </template>
    <template v-else>
      <Icon :name="icon" :size="20" />
    </template>
  </component>
</template>

<script setup lang="ts">
import Icon from '@/components/design/Icon.vue'
import Spinner from '@/components/misc/Spinner.vue'
import type { IconNames, Sizes, Variants } from '@/components/types'
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    variant?: Variants
    isDisabled?: boolean
    isLoading?: boolean
    size?: Sizes
    type?: HTMLButtonElement['type']
    icon?: IconNames
    as?: keyof HTMLElementTagNameMap | 'router-link'
  }>(),
  {
    variant: 'primary',
    isDisabled: undefined,
    isLoading: false,
    size: 'md',
    type: 'button',
    as: 'button'
  }
)

const el = ref<HTMLElement>(null)

defineExpose({
  el
})

const classnames = computed(() => {
  const output = []

  output.push('artrade-icon-button')
  output.push(`-${props.variant}`)
  output.push(`-${props.size}`)

  if (props.isLoading) {
    output.push(`-loading`)
  }

  return output.join(' ')
})
</script>

<style scoped lang="scss">
.artrade-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--artrade-radii-full);
  border: none;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
  appearance: none;

  :deep(svg) {
    transform: translate3d(0, 0, 0);
  }

  &:hover {
    transform: translate3d(0, -1.6px, 0);
  }

  &:active {
    transform: translate3d(0, 2px, 0);
  }

  &.-primary {
    background-color: var(--artrade-colors-black);
    color: var(--artrade-colors-white);
    box-shadow: 0px 2px 4px rgba(202, 207, 226, 0.16);

    svg {
      color: var(--artrade-colors-white);
    }

    &:hover {
      box-shadow: 0px 4px 8px rgba(202, 207, 226, 0.32);
    }

    &:active {
      background-color: var(--artrade-colors-grey-1100);
    }
  }

  &.-outline {
    background-color: var(--artrade-colors-white);
    border: 1px;
    border-style: solid;
    border-color: var(--artrade-colors-grey-200);
    color: var(--artrade-colors-black);
    box-shadow: 0px 2px 4px rgba(202, 207, 226, 0.16);
  }

  &.-ghost {
    background-color: transparent;
    color: var(--artrade-colors-black);

    &:hover {
      background-color: var(--artrade-colors-grey-100);
    }
  }

  &[disabled],
  &.-loading {
    cursor: not-allowed;
    color: var(--artrade-colors-grey-600);
    background-color: var(--artrade-colors-grey-100) !important;
    border: none !important;
    box-shadow: none !important;
    transform: translate3d(0, 0, 0) !important;

    &:deep(svg) {
      color: var(--artrade-colors-grey-600) !important;
    }

    &:hover {
      transform: translate3d(0, 0, 0);
    }

    &:active {
      transform: translate3d(0, 0, 0);
    }
  }

  &.-sm {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;

    &:deep(svg) {
      width: 16px;
      height: 16px;
    }
  }

  &.-md {
    width: 42px;
    height: 42px;
    flex: 0 0 42px;

    &:deep(svg) {
      width: 18px;
      height: 18px;
    }
  }

  &.-lg {
    width: 52px;
    height: 52px;
    flex: 0 0 52px;

    &:deep(svg) {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
