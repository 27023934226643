<template>
  <a ref="element" :class="['artrade--link', mode === 'dark' ? '-dark' : null]">
    <Icon name="arrowTr" />
    <slot></slot>
  </a>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { nextTick, onMounted, ref, watch } from 'vue'
import Icon from '../design/Icon.vue'

const props = withDefaults(
  defineProps<{
    trigger?: gsap.DOMTarget
    delay?: gsap.TweenValue
    duration?: gsap.TweenValue
    start?: ScrollTrigger.Vars['start']
    end?: ScrollTrigger.Vars['end']
    containerAnimation?: gsap.core.Tween
    scrub?: boolean
    mode?: 'light' | 'dark'
  }>(),
  {
    delay: null,
    duration: 0.6,
    start: 'center 75%',
    mode: 'light',
    scrub: false
  }
)

const element = ref<HTMLElement>(null)
const tween = ref<gsap.core.Tween>(null)

async function animate() {
  await nextTick()

  tween.value = gsap.to(element.value, {
    opacity: 1,
    yPercent: -10,
    duration: props.duration,
    delay: props.delay,
    scrollTrigger: {
      trigger: props.trigger,
      start: props.start,
      end: props.end,
      scrub: props.scrub,
      toggleActions: 'play none none reverse',
      containerAnimation: props.containerAnimation
    }
  })
}

onMounted(() => {
  animate()
})

watch(
  () => props.containerAnimation,
  () => {
    tween.value?.kill()
    animate()
  }
)
</script>

<style scoped lang="scss">
.artrade--link {
  position: relative;
  height: 42px;
  padding: 0;
  gap: var(--artrade-space-5);
  display: inline-flex;
  align-items: center;
  font-size: clamp(18px, 2vw, 20px);
  font-weight: var(--artrade-fontWeights-medium);
  align-self: flex-start;
  width: auto;
  opacity: 0;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  will-change: transform;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.275em;
    border-bottom: 1px solid currentColor;
    transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:before {
    transform: scaleX(0);
    transform-origin: left;
  }

  &:after {
    transform: scaleX(1);
    transform-origin: right;
    transition-delay: 0.25s;
  }

  &:hover {
    &:before {
      transition-delay: 0.25s;
      transform: scaleX(1);
    }

    &:after {
      transition-delay: 0s;
      transform: scaleX(0);
    }
  }

  &.-dark {
    color: var(--artrade-colors-white);
  }
}
</style>
