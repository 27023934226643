<template>
  <Stack alignItems="center">
    <Avatar size="2xl" :src="selectedImage" />
    <Stack direction="column" :spacing="5">
      <Stack>
        <Button variant="outline" @click="select" :isDisabled="isDisabled">{{
          t('Select image')
        }}</Button>
      </Stack>
      <FormHint color="grey.600" v-if="hint">
        {{ hint }}
      </FormHint>
    </Stack>
  </Stack>
</template>

<script setup lang="ts">
import { useCustomModal } from '@/composables'
import { Avatar, Button, FormHint, Stack } from '@/theme'
import { UploadEmptyError, UploadMaxSizeError, formatBytes, upload } from '@/utils'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    isDisabled?: boolean
    hint?: string
    accept?: HTMLInputElement['accept']
    maxSize?: number
    modelValue?: File
  }>(),
  {
    accept: '.png, .jpg, .jpeg',
    maxSize: 5 * 1024 * 1024,
    isDisabled: false
  }
)

const emits = defineEmits(['update:modelValue'])

const { t } = useI18n()
const modal = useCustomModal()
const selectedImage = ref<string>()

async function select() {
  try {
    const res = await upload({
      accept: props.accept,
      maxSize: props.maxSize
    })

    selectedImage.value = res.url
    emits('update:modelValue', res.file)
  } catch (err) {
    if (err instanceof UploadMaxSizeError) {
      modal.error(
        t('File size must be less than {size}', {
          size: formatBytes(props.maxSize)
        })
      )
    } else if (err instanceof UploadEmptyError) {
      modal.error(t('No files selected'))
    } else {
      console.error(err)
    }
  }
}
</script>
