<template>
  <Stack
    :class="[
      'input-group',
      {
        '-prefix': hasPrefix,
        '-suffix': hasSuffix,
        '-prefix-divider': hasPrefixDivider,
        '-suffix-divider': hasSuffixDivider
      }
    ]"
    :spacing="0"
  >
    <slot></slot>
  </Stack>
</template>

<script setup lang="ts">
import Stack from '@/components/design/Stack.vue'
import { provide, ref } from 'vue'
import { InputGroupProvider, type InputGroupContext } from './types'

const hasPrefix = ref(false)
const hasPrefixDivider = ref(false)
const hasSuffix = ref(false)
const hasSuffixDivider = ref(false)

const ctx = provide<InputGroupContext>(InputGroupProvider, {
  hasSuffix,
  hasSuffixDivider,
  hasPrefix,
  hasPrefixDivider
})
</script>

<style scoped lang="scss">
.input-group {
  position: relative;
  --offset: 4px;

  &.-prefix {
    &:deep(input),
    &:deep(textarea),
    &:deep(select) {
      padding-inline-start: var(--artrade-space-14);
    }
  }

  &.-prefix-divider {
    &:deep(input),
    &:deep(textarea),
    &:deep(select) {
      padding-inline-start: calc(var(--artrade-space-16) - var(--offset));
    }
  }

  &.-suffix {
    &:deep(input),
    &:deep(textarea),
    &:deep(select) {
      padding-inline-end: var(--artrade-space-14);
    }
  }

  &.-suffix-divider {
    &:deep(input),
    &:deep(textarea),
    &:deep(select) {
      padding-inline-end: var(--artrade-space-16);
    }
  }
}
</style>
