<template>
  <Teleport to="body">
    <div class="modal-portal">
      <TransitionGroup name="modal">
        <template v-for="modal in store.modals.value" :key="`modal-${modal.index}`">
          <div v-if="modal.isOpen" class="modal-overlay" @click="close(modal)"></div>
          <div
            v-if="modal.isOpen"
            :class="['modal-container', { '-full': modal.modalProps?.variant === 'full' }]"
            tabindex="-1"
          >
            <ModalContext :config="modal">
              <Component :is="modal.component" :id="`modal-${modal.index}`" />
            </ModalContext>
          </div>
        </template>
      </TransitionGroup>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { useModalStore } from '../../composables/useModalStore'
import type { ModalConfig } from '../../types/ModalConfig'
import ModalContext from './ModalContext.vue'

const store = useModalStore()

function close(modal: ModalConfig) {
  if (!modal.modalProps.closeable) return
  modal.close()
}
</script>

<style lang="scss">
@import '../../../../styles/variables.scss';

.modal-portal {
  position: fixed;
  z-index: 9998;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: var(--artrade-overlay);
  transition: opacity 0.2s ease;
  backdrop-filter: blur(14px);
}

.modal-container {
  pointer-events: none;
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  overscroll-behavior-y: none;
  transition: all 0.2s ease;

  @media (max-width: map-get($breakpoints, md)) {
    padding: 0 var(--artrade-space-9);
  }
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-content,
.modal-leave-to .modal-content {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
</style>
