<template>
  <div :class="['artrade--animated-divider', { '-vertical': vertical }]" ref="element"></div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { computed, nextTick, onMounted, ref } from 'vue'
import type { Colors } from '../types'

const props = withDefaults(
  defineProps<{
    vertical?: boolean
    color?: Colors
    delay?: gsap.TweenValue
    duration?: gsap.TweenValue
    trigger?: gsap.DOMTarget
    start?: ScrollTrigger.Vars['start']
    end?: ScrollTrigger.Vars['end']
    ease?: gsap.EaseString
    markers?: ScrollTrigger['vars']['markers']
    scrub?: boolean
  }>(),
  {
    vertical: false,
    color: 'grey.100',
    delay: null,
    duration: 1,
    start: 'top 50%',
    ease: 'expo',
    markers: false,
    scrub: false
  }
)

const backgroundColor = computed(() => `var(--artrade-colors-${props.color.replace('.', '-')})`)
const element = ref<HTMLElement>()

onMounted(async () => {
  await nextTick()

  gsap.set(element.value, {
    scaleX: 0
  })

  gsap.to(element.value, {
    scaleX: 1,
    delay: props.delay,
    duration: props.duration,
    ease: 'expo',
    rotate: 0,

    scrollTrigger: {
      markers: props.markers,
      trigger: props.trigger ? props.trigger : element.value,
      start: props.start,
      end: props.end,
      scrub: props.scrub,
      toggleActions: 'play none none reverse'
    }
  })
})
</script>

<style scoped lang="scss">
.artrade--animated-divider {
  width: 100%;
  height: 1px;
  background-color: v-bind('backgroundColor');
  transform-origin: left;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  will-change: transform;

  &.-vertical {
    width: 1px;
    height: auto;
    flex-shrink: 0;
    align-self: stretch;
  }
}
</style>
