<template>
  <slot></slot>
</template>

<script setup lang="ts">
import { provide } from 'vue'
import { BottomSheetContextKey } from '../constants'
import type { BottomSheetConfig, BottomSheetContextProps } from '../types'

const props = defineProps<{
  config: BottomSheetConfig
}>()

provide<BottomSheetContextProps>(BottomSheetContextKey, {
  config: props.config
})
</script>
