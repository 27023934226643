declare global {
  interface Window {
    dataLayer: {
      push<T>(payload: T): void
    }
  }
}

export function useAnalytic() {
  function send<T extends Record<string, string>>(name: string, payload: T) {
    window.dataLayer.push({
      event: name,
      ...payload
    })
  }

  return {
    send
  }
}
