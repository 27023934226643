<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
      fill="#1A1A1A"
    />
    <path
      d="M19.9997 9L10.2222 12.178V19.2669C10.2222 22.4442 13.0156 28.0664 19.9997 31C26.9838 28.0664 29.778 22.4442 29.778 19.2669V12.178L19.9997 9Z"
      fill="white"
    />
  </svg>
</template>
