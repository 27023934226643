<template>
  <Stack class="token-ads" direction="column" :spacing="8">
    <Stack direction="column" :spacing="3">
      <Text color="white" lineHeight="md" fontWeight="bold">{{
        t('ATR token is now available')
      }}</Text>
      <Text color="grey.400" lineHeight="md">{{
        t('Join a web3 community and help us improve Artrade')
      }}</Text>
    </Stack>
    <Button
      variant="outline"
      theme="dark"
      rightIcon="arrowRight"
      size="sm"
      alignSelf="start"
      @click="openBuyATR"
      >{{ t('Buy now') }}</Button
    >
    <img src="/images/atr-token-ads.webp" />
  </Stack>
</template>
<script setup lang="ts">
import { Button, Stack, Text, useBottomSheet, useModal } from '@/theme'
import { useI18n } from 'vue-i18n'
import { PurchaseATRModal } from './modals'

const { t } = useI18n()

const modal = useModal()
const bottomSheet = useBottomSheet()

function openBuyATR() {
  modal.open(PurchaseATRModal)
  bottomSheet.closeAll()
}
</script>

<style scope lang="scss">
.token-ads {
  background-color: var(--artrade-colors-black);
  border-radius: var(--artrade-radii-lg);
  padding: var(--artrade-space-8);
  position: relative;
  overflow: hidden;
  height: 180px;

  img {
    position: absolute;
    right: -30px;
    bottom: -80px;
    width: 180px;
    height: 180px;
  }
}
</style>
