<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5H28C34.6274 0.5 40 5.87258 40 12.5V28.5C40 35.1274 34.6274 40.5 28 40.5H12C5.37258 40.5 0 35.1274 0 28.5V12.5Z"
      fill="#1A1A1A"
    />
    <mask
      id="mask0_3338_17132"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="13"
      y="11"
      width="14"
      height="19"
    >
      <path d="M26.1875 11.5H13.8125V29.5H26.1875V11.5Z" fill="white" />
    </mask>
    <g mask="url(#mask0_3338_17132)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.1723 12.9153C21.8265 12.9153 22.4402 13.003 23.0084 13.1657C22.4521 11.8692 21.2971 11.5 20.0117 11.5C18.7237 11.5 17.5667 11.8706 17.0117 13.1733C17.5757 13.0049 18.1868 12.9153 18.8388 12.9153H21.1723ZM18.6891 14.2169C15.5825 14.2169 13.8125 16.6609 13.8125 19.6756V22.7725C13.8125 23.074 14.0643 23.3125 14.375 23.3125H25.625C25.9357 23.3125 26.1875 23.074 26.1875 22.7725V19.6756C26.1875 16.6609 24.1292 14.2169 21.0226 14.2169H18.6891ZM19.9956 19.7024C21.0829 19.7024 21.9644 18.821 21.9644 17.7336C21.9644 16.6463 21.0829 15.7649 19.9956 15.7649C18.9083 15.7649 18.0269 16.6463 18.0269 17.7336C18.0269 18.821 18.9083 19.7024 19.9956 19.7024ZM13.8125 25.1328C13.8125 24.8313 14.0643 24.5869 14.375 24.5869H25.625C25.9357 24.5869 26.1875 24.8313 26.1875 25.1328V28.408C26.1875 29.0109 25.6838 29.4997 25.0625 29.4997H14.9375C14.3162 29.4997 13.8125 29.0109 13.8125 28.408V25.1328Z"
        fill="white"
      />
    </g>
  </svg>
</template>
