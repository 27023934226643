<template>
  <Stack
    :class="['bottom-sheet', { '-full': isFull }]"
    :spacing="9"
    direction="column"
    :as="as"
    role="dialog"
    tabindex="-1"
    aria-modal="true"
  >
    <IconButton
      class="bottom-sheet-close-button"
      v-if="isFull"
      @click="config.close"
      variant="outline"
      icon="cancel"
    />
    <slot :props="config.props" :close="config.close"></slot>
  </Stack>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import IconButton from '../../IconButton.vue'
import Stack from '../../Stack.vue'
import { useBottomSheetConfig } from '../composables'
import type { BottomSheetProps } from '../types'

const props = defineProps<BottomSheetProps>()
const config = useBottomSheetConfig()

const variant = computed(() => {
  if (props.variant) {
    return props.variant
  }

  return config.bottomSheetProps.variant
})

const isFull = computed(() => {
  return variant.value === 'full'
})
</script>

<style scoped lang="scss">
.bottom-sheet {
  position: relative;
  width: 100%;
  pointer-events: all;
  z-index: 2;
  background-color: var(--artrade-colors-white);
  border-radius: var(--artrade-radii-2xl) var(--artrade-radii-2xl) 0 0;
  padding: var(--artrade-space-9) var(--artrade-space-5);
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  transform: translate3d(0, 0, 0);
  overflow: auto;
  max-height: 90dvh;

  &.-full {
    border-radius: 0;
    height: 100vh;
    max-height: 100vh;

    :deep(.bottom-sheet-body) {
      flex: 1;
    }
  }

  .bottom-sheet-close-button {
    position: absolute;
    top: var(--artrade-space-9);
    right: var(--artrade-space-9);
    z-index: 1;
  }
}
</style>
