<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5H28C34.6274 0.5 40 5.87258 40 12.5V28.5C40 35.1274 34.6274 40.5 28 40.5H12C5.37258 40.5 0 35.1274 0 28.5V12.5Z"
      fill="#1A1A1A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3007 17.9334L21.7688 8.5C22.2093 8.5 22.5665 8.85714 22.5665 9.29769V17.9334H25.7572C26.8299 17.9334 27.6994 18.803 27.6994 19.8756V23.0664H27.6993L18.2311 32.4999C17.7906 32.4999 17.4334 32.1428 17.4334 31.7022V23.0664H14.2427C13.1701 23.0664 12.3005 22.1968 12.3005 21.1242V17.9334H12.3007Z"
      fill="white"
    />
  </svg>
</template>
