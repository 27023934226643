<template>
  <img
    :class="classnames"
    :src="src"
    :alt="alt"
    :srcset="srcSets"
    :sizes="sizes"
    :loading="loading"
    decoding="async"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    src?: string
    srcSets?: string
    sizes?: string
    width?: number
    height?: number
    alt: string
    loading?: HTMLImageElement['loading']
    objectPosition?: string
    objectFit?: string
  }>(),
  {
    srcSets: null,
    loading: 'lazy',
    objectPosition: 'center',
    objectFit: 'cover'
  }
)

const classnames = computed(
  () => `artrade-image object-${props.objectPosition} object-${props.objectFit}`
)
</script>

<style scoped lang="scss">
.artrade-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &.object-top {
    object-position: top;
  }

  &.object-left {
    object-position: left;
  }

  &.object-right {
    object-position: right;
  }

  &.object-bottom {
    object-position: bottom;
  }

  &.object-center {
    object-position: center;
  }

  &.object-cover {
    object-fit: cover;
  }

  &.object-contain {
    object-fit: contain;
  }

  &.object-fill {
    object-fit: fill;
  }

  &.object-none {
    object-fit: none;
  }
}
</style>
