<template>
  <slot :open="open"></slot>
  <Modal :isOpen="isOpen" @onClose="isOpen = false">
    <ModalContent>
      <ModalHeader>
        <Heading as="h4" size="md">{{ t('Verify your account') }}</Heading>
      </ModalHeader>
      <ModalBody>
        <Text size="md" color="grey.700" lineHeight="md">{{
          t(
            'Oops! To create an artwork you need to verify your account by connecting your X or Instagram account'
          )
        }}</Text>
      </ModalBody>
      <ModalFooter>
        <Button
          as="router-link"
          @click="isOpen = false"
          :to="{ name: RouteName.ProfileSetting }"
          size="lg"
          >{{ t('Get verified') }}</Button
        >
      </ModalFooter>
    </ModalContent>
  </Modal>
</template>

<script setup lang="ts">
import Button from '@/components/design/Button.vue'
import Heading from '@/components/design/Heading.vue'
import Text from '@/components/design/Text.vue'
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@/components/modal'
import { RouteName } from '@/router/types'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const isOpen = ref(false)

function open() {
  isOpen.value = true
}
</script>
